import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { Product } from '@spartacus/core';
import { bossIconConfig } from '../../../../shared/utils/boss-icon-config';

@Component({
  selector: 'boss-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossProductDescriptionComponent implements OnInit {
  product$: Observable<Product>;
  context = this.router.serializeUrl(this.router.createUrlTree([]));
  bossIconConfig = bossIconConfig;

  constructor(private currentProductService: CurrentProductService, private router: Router) {}

  ngOnInit(): void {
    this.product$ = this.currentProductService.getProduct();
  }
}
