import { Pipe, PipeTransform } from '@angular/core';
import { BossComponentType } from './component-type.model';
import { BossPriceType } from './price-type.model';

@Pipe({ name: 'bossPriceTypeClass' })
export class BossPriceTypePipe implements PipeTransform {
  transform(priceType: BossPriceType, componentType: BossComponentType): string {
    return this.getCssClasses(priceType, componentType);
  }

  private getCssClasses(priceType: BossPriceType, componentType: BossComponentType): string {
    const isBannerStyle = componentType === BossComponentType.BANNER,
      priceTypeClass = priceType ? this.convertPriceTypeToCssClass(priceType) : '';
    return isBannerStyle ? 'banner-style' : priceTypeClass;
  }

  private convertPriceTypeToCssClass(priceType: BossPriceType): string {
    return `boss-price-type-${priceType}`;
  }
}
