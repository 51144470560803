import { Injectable } from '@angular/core';
import { defer, Observable, ReplaySubject } from 'rxjs';
import { BossDialogContent } from './boss-dialog-content';
import { WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class BossDialogService {
  getContent$: Observable<BossDialogContent> = defer(() => this.contentState.asObservable());

  isOpen$: Observable<boolean> = defer(() => this.isOpenState.asObservable());

  isOpenState = new ReplaySubject<boolean>(1);

  contentState = new ReplaySubject<BossDialogContent | null>(1);

  constructor(private winRef: WindowRef) {}

  open(payload: BossDialogContent): void {
    this.contentState.next(payload);
    this.isOpenState.next(true);
  }

  close(): void {
    this.contentState.next(null);
    this.isOpenState.next(false);

    if (this.winRef.isBrowser()) {
      document.body.classList.remove('modal-open');
    }
  }
}
