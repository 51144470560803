import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  ConverterService,
  InterceptorUtil,
  Occ,
  OCC_USER_ID_ANONYMOUS,
  OccCheckoutAdapter,
  OccEndpointsService,
  Order,
  ORDER_NORMALIZER,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { AWIN, AwinService } from '../../../shared/services/awin.service';
import { BossCheckoutAdapter } from './boss-checkout.adapter';

@Injectable()
export class BossOccCheckoutAdapter extends OccCheckoutAdapter implements BossCheckoutAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected awinService: AwinService,
  ) {
    super(http, occEndpoints, converter);
  }

  bossPlacePayEverOrder(
    userId: string,
    cartId: string,
    payment_id: string,
    callId: string,
    termsChecked: boolean,
  ): Observable<Order> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    const params = new HttpParams()
      .set('cartId', cartId)
      .set('payeverCallId', callId)
      .set('payeverPaymentId', payment_id)
      .set('termsChecked', termsChecked.toString());

    return this.http
      .post<Occ.Order>(this.occEndpoints.getUrl('placeOrder', { userId }), {}, { headers, params })
      .pipe(this.converter.pipeable(ORDER_NORMALIZER));
  }

  placeOrder(userId: string, cartId: string, termsChecked: boolean): Observable<Order> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    const awinToken = this.awinService.token();
    const params = new HttpParams()
      .set('cartId', cartId)
      .set('termsChecked', termsChecked.toString())
      .set(AWIN.AWIN_TOKEN_KEY, awinToken || null);
    return this.http
      .post<Occ.Order>(this.occEndpoints.getUrl('placeOrder', { userId }), {}, { headers, params })
      .pipe(this.converter.pipeable(ORDER_NORMALIZER));
  }

  clearCheckoutDeliveryMode(userId: string, cartId: string): Observable<string> {
    // to prevent redundant api requests
    return of('');
  }
}
