import { Injectable } from '@angular/core';
import { Address, UserAddressService } from '@spartacus/core';
import { BossDeliveryAddressService } from './boss-delivery-address.service';
import { take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { BossBillingAddressService } from './boss-billing-address.service';
import { country } from '../../../shared/utils/boss-constants';

@Injectable()
export class BossAddressBookService {
  constructor(
    private userAddressService: UserAddressService,
    private deliveryAddressService: BossDeliveryAddressService,
    private billingAddressService: BossBillingAddressService,
  ) {}

  addUserAddress(address: Address): void {
    const address1: Address = {
      ...address,
      country,
      shippingAddress: true,
      billingAddress: true,
    };
    this.userAddressService.addUserAddress(address1);
  }

  removeAddress(addressId: string): Observable<[Address, Address]> {
    this.userAddressService.deleteUserAddress(addressId);

    return this.removeAddressFromCart();
  }

  removeAddressFromCart(): Observable<[Address, Address]> {
    return combineLatest([this.deliveryAddressService.remove(), this.billingAddressService.remove()]).pipe(take(1));
  }

  updateUserAddress(addressId: string, address: Address): Observable<[Address, Address]> {
    this.userAddressService.updateUserAddress(addressId, address);

    return this.removeAddressFromCart();
  }
}
