import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig({
      featureModules: {
        'boss-wishlist-module': {
          module: () =>
            import(
              /* webpackChunkName: "boss-wishlist" */
              './wishlist-details/wishlist-details.module'
            ).then((m) => m.BossWishlistDetailsComponentModule),
          cmsComponents: ['WishlistFlexComponent'],
        },
      },
    } as CmsConfig),
  ],
})
export class BossWishListFeatureModule {}
