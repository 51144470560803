import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { BossProductStarRatingComponentModule } from '../../../product/product-star-rating/product-star-rating.module';
import { BossProductReviewsComponent } from './product-reviews.component';
import { BossIconDirectiveModule } from '../../../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    BossProductStarRatingComponentModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductReviewsComponent: {
          component: BossProductReviewsComponent,
        },
      },
    }),
  ],
  declarations: [BossProductReviewsComponent],
})
export class BossProductReviewsComponentModule {}
