<ng-container *ngIf="items?.length">
  <div class="boss-automatic-carousel">
    <swiper [config]="swiperConfig">
      <ng-template swiperSlide *ngFor="let item of items; index as i; trackBy: trackById">
        <cx-generic-link [url]="item.urlLink">
          <img bossMedia [container]="item.media" [alt]="item.name" />
        </cx-generic-link>
      </ng-template>
    </swiper>
  </div>
</ng-container>
