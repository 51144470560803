import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossShowPasswordComponent } from './boss-show-password.component';
import { BossIconDirectiveModule } from '../../directives/icon/boss-icon.directive.module';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [BossShowPasswordComponent],
  imports: [CommonModule, BossIconDirectiveModule, I18nModule],
  exports: [BossShowPasswordComponent],
})
export class BossShowPasswordModule {}
