import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreAvailabilityComponent } from './store-availability.component';
import { I18nModule } from '@spartacus/core';
import { IconModule, SpinnerModule } from '@spartacus/storefront';
import { BossStatusLabelComponentModule } from '../../../../shared/components/status-label/boss-status-label.module';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreAvailabilityItemComponent } from './store-availability-item/store-availability-item.component';

@NgModule({
  declarations: [StoreAvailabilityComponent, StoreAvailabilityItemComponent],
  imports: [CommonModule, I18nModule, SpinnerModule, BossStatusLabelComponentModule, ReactiveFormsModule, IconModule],
  exports: [StoreAvailabilityComponent],
})
export class StoreAvailabilityModule {}
