import { Injectable } from '@angular/core';
import { ImageGroup, Images } from '@spartacus/core/src/model/image.model';
import { Image } from '@spartacus/core';

@Injectable()
export class BossImageConverter {
  convertToStringArray(images: Images): string[] {
    const imageArray = [];
    if (images) {
      Object.values(images).forEach((imageGroup: ImageGroup | ImageGroup[]) => {
        if (imageGroup instanceof Array) {
          imageGroup.forEach((imageGroupEntry: ImageGroup) => {
            Object.values(imageGroupEntry).forEach((image: Image) => imageArray.push(image.url));
          });
        } else {
          imageArray.push(Object.values(imageGroup)[1].url);
        }
      });
    }
    return [...new Set(imageArray)];
  }
}
