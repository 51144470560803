import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossLinkComponent } from './boss-link.component';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { BossDecodeUriPipeModule } from '../../shared/pipes/encode-uri/boss-decode-uri-pipe.module';

@NgModule({
  declarations: [BossLinkComponent],
  imports: [CommonModule, RouterModule, GenericLinkModule, BossDecodeUriPipeModule],
  exports: [BossLinkComponent],
  providers: [
    provideConfig({
      cmsComponents: {
        CMSLinkComponent: {
          component: BossLinkComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class BossLinkModule {}
