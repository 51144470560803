import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { BossProductFacetNavigationComponent } from './product-facet-navigation.component';
import { FormErrorsModule, IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { BossActiveFacetsModule } from '../../shared/components/facets/active-facets/boss-active-facets.module';
import { BossCollapseModule } from '../../shared/components/boss-collapse/boss-collapse.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BossRangeSliderModule } from '../../shared/components/range-slider/boss-range-slider.module';
import { BossFacetsDialogButtonsComponent } from './dialog-buttons/boss-facets-dialog-buttons.component';
import { BossFilterRangeComponent } from './price-range/boss-filter-range.component';
import { BossFacetCheckboxModule } from '../../shared/components/facets/facet-checkbox/boss-facet-checkbox.module';
import { BossFacetSwitchComponent } from './facet-switch/boss-facet-switch.component';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    BossActiveFacetsModule,
    BossFacetCheckboxModule,
    BossCollapseModule,
    IconModule,
    I18nModule,
    RouterModule,
    KeyboardFocusModule,
    FormErrorsModule,
    BossRangeSliderModule,
    ReactiveFormsModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductRefinementComponent: {
          component: BossProductFacetNavigationComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [
    BossProductFacetNavigationComponent,
    BossFacetsDialogButtonsComponent,
    BossFilterRangeComponent,
    BossFacetSwitchComponent,
  ],
  exports: [
    BossProductFacetNavigationComponent,
    BossFacetsDialogButtonsComponent,
    BossFilterRangeComponent,
    BossFacetSwitchComponent,
  ],
})
export class BossProductFacetsComponentModule {}
