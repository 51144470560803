import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossDropdownCountryComponent } from './boss-dropdown-country.component';
import { I18nModule } from '@spartacus/core';
import { BossDropdownComponentModule } from '../dropdown/boss-dropdown.module';

@NgModule({
  declarations: [BossDropdownCountryComponent],
  imports: [CommonModule, BossDropdownComponentModule, I18nModule],
  exports: [BossDropdownCountryComponent],
})
export class BossDropdownCountryModule {}
