import { Pipe, PipeTransform } from '@angular/core';
import { Breadcrumb } from '@spartacus/core';
import { BossFilterType } from '../boss-filter-type';

@Pipe({ name: 'bossFacetName' })
export class BossFacetNamePipe implements PipeTransform {
  transform(facet: Breadcrumb): string {
    return this.getFacetName(facet);
  }

  private getFacetName(facet: Breadcrumb): string {
    if (facet.facetName === 'Farbe') {
      return this.getColorName(facet.facetValueName);
    } else if (facet.filterType === BossFilterType.RANGE) {
      return this.getPriceRange(facet);
    } else {
      return facet.facetValueName === 'true' ? facet.facetName : facet.facetValueName;
    }
  }

  private getColorName(name?: string): string {
    return name ? name.split('|')[0] : '';
  }

  private getPriceRange(facet: Breadcrumb): string {
    const squareBracketsRegex = /[[\]']+/g,
      name = facet.facetValueName.replace(' TO ', '-').replace(squareBracketsRegex, '');
    return `${name} ${facet.facetName === 'Preis' ? '€' : 'cm'}`;
  }
}
