import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BossStarRatingComponent } from './boss-star-rating.component';

@NgModule({
  declarations: [BossStarRatingComponent],
  imports: [CommonModule],
  exports: [BossStarRatingComponent],
})
export class BossStarRatingComponentModule {}
