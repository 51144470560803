import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { BossFooterNewsletterComponent } from './footer-newsletter.component';
import { MediaModule } from '@spartacus/storefront';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FooterNewsletterComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-footer" */
              './footer-newsletter.component'
            ).then((m) => m.BossFooterNewsletterComponent),
        },
      },
    }),
    MediaModule,
  ],
  declarations: [BossFooterNewsletterComponent],
  exports: [BossFooterNewsletterComponent],
})
export class BossFooterNewsletterComponentModule {}
