import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PaginationBuilder, PaginationComponent, PaginationItem } from '@spartacus/storefront';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'boss-pagination',
  templateUrl: './boss-pagination.component.html',
  styleUrls: ['./boss-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossPaginationComponent extends PaginationComponent implements OnChanges {
  bossIconConfig = bossIconConfig;

  prevPage: PaginationItem;

  currentPage: PaginationItem;

  nextPage: PaginationItem;

  constructor(
    builder: PaginationBuilder,
    private actRoute: ActivatedRoute,
    private winRef: WindowRef,
    private renderer: Renderer2,
  ) {
    super(builder, actRoute);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pagination) {
      this.prevPage = this.pages.find((page: PaginationItem) => page.number === this.pagination.currentPage - 1);
      this.currentPage = this.pages.find((page: PaginationItem) => page.number === this.pagination.currentPage);
      this.nextPage = this.pages.find((page: PaginationItem) => page.number === this.pagination.currentPage + 1);
    }
  }

  isDisabled(item: PaginationItem): boolean {
    return item?.number === this.pagination.currentPage;
  }

  getQueryParams(item: PaginationItem): Params {
    const queryParams = { ...this.actRoute.snapshot.queryParams };

    if (this.queryParam && item?.number < this.pagination.totalPages && !this.isCurrent(item)) {
      queryParams[this.queryParam] = item?.number + 1;
    }

    // omit the page number from the query parameters in case it's the default
    // to clean up the experience and avoid unnecessary polluting of the URL
    if (queryParams[this.queryParam] === this.defaultPage) {
      delete queryParams[this.queryParam];
    }

    return queryParams;
  }

  pageChange(): void {
    // Scroll up
    if (this.winRef.isBrowser()) {
      const productCard: HTMLElement = this.renderer.selectRootElement('boss-product-card', true);
      productCard.scrollIntoView({ block: 'end' });
    }
  }
}
