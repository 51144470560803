import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BossStructuredDataFactory } from './boss-structured-data-factory';

/**
 * Factory to build the structure data
 * without any interaction with the UI.
 */
export function bossGetStructuredDataFactory(injector: Injector): () => void {
  return (): void => {
    const factory = injector.get(BossStructuredDataFactory);
    factory.build();
  };
}

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: bossGetStructuredDataFactory,
      deps: [Injector],
      multi: true,
    },
  ],
})
export class BossStructuredDataModule {}
