import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { ProductCardComponent } from './product-card.component';
import { BossSwatchesModule } from '../../../../shared/components/boss-swatches/boss-swatches.module';
import { BossEnergyLabelsModule } from '../../../../shared/components/boss-energy-labels/boss-energy-labels.module';
import { BossPriceModule } from '../../../../shared/components/boss-price/boss-price.module';
import { BossProductStatusComponentModule } from '../../product-status/boss-product-status.module';
import { BossMediaDirectiveModule } from '../../../../shared/directives/media/boss-media.directive.module';
import { BossIconDirectiveModule } from '../../../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BossSwatchesModule,
    UrlModule,
    BossEnergyLabelsModule,
    BossMediaDirectiveModule,
    BossPriceModule,
    BossProductStatusComponentModule,
    BossIconDirectiveModule,
    I18nModule,
  ],
  declarations: [ProductCardComponent],
  exports: [ProductCardComponent],
})
export class BossProductCardComponentModule {}
