import { MediaConfig } from '@spartacus/storefront';

export const bossMediaConfig: MediaConfig = {
  mediaFormats: {
    xs: { width: 320 },
    mobile: { width: 400 },
    tablet: { width: 770 },
    laptop: { width: 992 },
    desktop: { width: 1140 },
    extraWide: { width: 1920 },
    // product media
    cartIcon: { width: 65 },
    thumbnail: { width: 96 },
    product: { width: 284 },
    productListing: { width: 250 },
    zoom: { width: 515 },
  },
};
