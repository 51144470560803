import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossPriceInfoComponent } from './boss-price-info.component';
import { I18nModule } from '@spartacus/core';
import { BossProductPriceService } from '../../../features/product/product-price/services/product-price.service';

@NgModule({
  declarations: [BossPriceInfoComponent],
  exports: [BossPriceInfoComponent],
  imports: [CommonModule, I18nModule],
  providers: [BossProductPriceService],
})
export class BossPriceInfoModule {}
