import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { BossMiniCartComponent } from './mini-cart.component';
import { HeaderDirectivesModule } from '../../shared/directives/header-directives/header-directives.module';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [BossMiniCartComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    HeaderDirectivesModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MiniCartComponent: {
          component: BossMiniCartComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [BossMiniCartComponent],
})
export class BossMiniCartModule {}
