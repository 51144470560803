import { Injectable } from '@angular/core';
import { DatePickerService } from '@spartacus/storefront';

@Injectable({
  providedIn: 'root',
})
export class BossDatePickerService extends DatePickerService {
  private readonly dateFormatRegEx = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;

  get pattern(): string {
    return this.placeholder
      .replace('yyyy', '^([12]\\d{3}')
      .replace('mm', '(0[1-9]|1[0-2])')
      .replace('dd', '(0[1-9]|[12]\\d|3[01]))$');
  }

  get patternRegex(): RegExp {
    return this.dateFormatRegEx;
  }

  updateDateWithTime(date: string): string {
    // Returns date in ISO8601 format but without milliseconds - Hybris compatible
    return date ? new Date(date).toISOString().split('.')[0] + 'Z' : '';
  }
}
