import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { UserCentricsService } from '../../services/usercentrics.service';
import { debounce, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'boss-consent[consents]',
  templateUrl: './boss-consent.component.html',
  styleUrls: ['./boss-consent.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossConsentComponent implements OnInit, OnDestroy {
  @Input()
  consents!: string[];

  isConsent: boolean;

  loaded = false;

  private onDestroy$ = new Subject<void>();

  constructor(private userCentricsService: UserCentricsService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.loaded && this.consents.length) {
      combineLatest(this.getConsents())
        .pipe(
          map((consents: boolean[]) => !consents.includes(false)),
          debounce(() => timer(1300)),
          takeUntil(this.onDestroy$),
        )
        .subscribe((isConsent: boolean) => {
          this.isConsent = isConsent;
          this.loaded = true;
          this.cdRef.detectChanges();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getConsents(): Observable<boolean>[] {
    return this.consents.map((consent: string) => {
      return this.userCentricsService.getConsent(consent);
    });
  }

  openCookieMenu(): void {
    this.userCentricsService.openCookieMenu();
  }

  acceptRequiredCookies(): void {
    if (this.consents.length) {
      this.consents.forEach((consent: string) => {
        this.userCentricsService.setConsent(consent, true);
      });
    }
  }
}
