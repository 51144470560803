import { Injectable } from '@angular/core';
import { BossFilterCodes } from './boss-filter-codes';
import { BossFilterType } from './boss-filter-type';

@Injectable({ providedIn: 'root' })
export class FacetTypeService {
  getFilterType(code: BossFilterCodes | string): BossFilterType {
    switch (code) {
      case BossFilterCodes.COLOR: {
        return BossFilterType.CHECKBOXES;
      }
      case BossFilterCodes.LENGTH: {
        return BossFilterType.CHECKBOXES;
      }
      case BossFilterCodes.MATERIAL: {
        return BossFilterType.CHECKBOXES;
      }
      case BossFilterCodes.MESH_NAME: {
        return BossFilterType.CHECKBOXES;
      }
      case BossFilterCodes.STYLE: {
        return BossFilterType.CHECKBOXES;
      }
      case BossFilterCodes.SURFACE: {
        return BossFilterType.CHECKBOXES;
      }
      case BossFilterCodes.SALE: {
        return BossFilterType.SWITCH;
      }
      case BossFilterCodes.WIDTH_SLIDER: {
        return BossFilterType.RANGE;
      }
      case BossFilterCodes.PRICE_SLIDER: {
        return BossFilterType.RANGE;
      }
      case BossFilterCodes.DEPTH_SLIDER: {
        return BossFilterType.RANGE;
      }
      case BossFilterCodes.HEIGHT_SLIDER: {
        return BossFilterType.RANGE;
      }
    }
  }
}
