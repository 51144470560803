import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { BossNewCustomerLoginComponent } from './new-customer-login.component';
import { BossShowPasswordModule } from '../../shared/components/boss-show-password/boss-show-password.module';
import { BossDropdownGreetingModule } from '../../shared/components/boss-dropdown-greeting/boss-dropdown-greeting.module';
import { BossDropdownCountryModule } from '../../shared/components/boss-dropdown-country/boss-dropdown-country.module';
import { BossDatePickerModule } from '../../shared/components/boss-date-picker/boss-date-picker.module';
import { BossInfoLineComponentModule } from '../../shared/components/info-line/boss-info-line.module';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        NewCustomerLoginComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-login" */
              './new-customer-login.component'
            ).then((m) => m.BossNewCustomerLoginComponent),
        },
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    FormErrorsModule,
    BossInfoLineComponentModule,
    BossShowPasswordModule,
    BossDropdownGreetingModule,
    BossDropdownCountryModule,
    BossDatePickerModule,
  ],
  declarations: [BossNewCustomerLoginComponent],
  exports: [BossNewCustomerLoginComponent],
})
export class BossNewCustomerLoginComponentModule {}
