import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BossHamburgerMenuService {
  isExpanded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * toggles the expand state of the hamburger menu
   */
  toggle(forceCollapse?: boolean): void {
    if (forceCollapse) {
      this.isExpanded.next(false);
    } else {
      this.isExpanded.next(!this.isExpanded.value);
    }
  }
}
