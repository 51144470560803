import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Product } from '@spartacus/core';

@Component({
  selector: 'boss-product-room-player[product]',
  templateUrl: './boss-product-description-room-player.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossProductDescriptionRoomPlayerComponent implements OnChanges {
  @Input()
  product!: Product;

  augmentedRealityId = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product.currentValue) {
      this.changeARUrl();
    }
  }

  private changeARUrl(): void {
    if (this.product?.augmentedRealityID) {
      this.augmentedRealityId = `https://more.rooom.com/customers/porta/product-viewer/?id=${this.product.augmentedRealityID}&shop=0`;
    } else {
      this.augmentedRealityId = '';
    }
  }
}
