import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
  AuthActions,
  Cart,
  CHECKOUT_DETAILS,
  CheckoutState,
  CheckoutStepsState,
  MULTI_CART_DATA,
  MultiCartState,
  StateUtils,
} from '@spartacus/core';
import { InjectionToken, Provider } from '@angular/core';
import { bossActiveCartReducer, bossCartEntitiesReducer, bossWishListReducer } from './multi-cart.reducer';

import * as fromAddressVerification from './address-verification.reducer';
import * as fromCardTypes from './card-types.reducer';
import * as fromCheckout from './checkout.reducer';
import * as fromOrderTypes from './order-types.reducer';
import * as fromPaymentTypes from './payment-types.reducer';
import * as fromProductList from './product-list.reducer';

//cart
// eslint-disable-next-line
export function bossClearMultiCartState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action: Action): number {
    if (action.type === AuthActions.LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

// eslint-disable-next-line
export const bossMultiCartMetaReducers: MetaReducer<any>[] = [bossClearMultiCartState];

export const bossMultiCartReducerToken: InjectionToken<ActionReducerMap<MultiCartState>> = new InjectionToken<
  ActionReducerMap<MultiCartState>
>('MultiCartReducers');

export function getBossMultiCartReducers(): ActionReducerMap<MultiCartState> {
  return {
    carts: StateUtils.entityProcessesLoaderReducer<Cart>(MULTI_CART_DATA, bossCartEntitiesReducer),
    active: bossActiveCartReducer,
    wishList: bossWishListReducer,
  };
}

export const bossMultiCartReducerProvider: Provider = {
  provide: bossMultiCartReducerToken,
  useFactory: getBossMultiCartReducers,
};

//Checkout
export function getBossCheckoutReducers(): ActionReducerMap<CheckoutState> {
  return {
    steps: StateUtils.loaderReducer<CheckoutStepsState>(CHECKOUT_DETAILS, fromCheckout.reducer),
    cardTypes: fromCardTypes.reducer,
    addressVerification: fromAddressVerification.reducer,
    paymentTypes: fromPaymentTypes.reducer,
    orderType: fromOrderTypes.reducer,
  };
}

export const bossCheckoutReducerToken: InjectionToken<ActionReducerMap<CheckoutState>> = new InjectionToken<
  ActionReducerMap<CheckoutState>
>('CheckoutReducers');

export const bossCheckoutReducerProvider: Provider = {
  provide: bossCheckoutReducerToken,
  useFactory: getBossCheckoutReducers,
};

//Product List page size

export function getProductListPageSize(): ActionReducerMap<number | {}> {
  return {
    pageSize: fromProductList.pageSizeReducer,
  };
}

export const bossPageSizeReducerToken: InjectionToken<ActionReducerMap<CheckoutState>> = new InjectionToken<
  ActionReducerMap<any> // eslint-disable-line
>('PageSizeReducers');

export const bossPageSizeReducerProvider: Provider = {
  provide: bossPageSizeReducerToken,
  useFactory: getProductListPageSize,
};
