import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PointOfServiceWithStock } from '../store-availability.model';
import { Observable } from 'rxjs';
import { TranslationService } from '@spartacus/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { translations } from '../store-availability.constants';
import { BossOpeningSchedule } from '../../../../../shared/models/store.models';
import { BossStatusLabel } from '../../../../../shared/models';
import { StorefinderOpeningHoursService } from '../../../../storefinder/services/storefinder-opening-hours.service';

@Component({
  selector: 'boss-store-availability-item[pointOfService]',
  templateUrl: './store-availability-item.component.html',
  styleUrls: ['./store-availability-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreAvailabilityItemComponent {
  _pointOfService: PointOfServiceWithStock;

  @Input()
  get pointOfService(): PointOfServiceWithStock {
    return this._pointOfService;
  }

  set pointOfService(value: PointOfServiceWithStock) {
    this._pointOfService = {
      ...value,
      formattedHours: this.normalizeOpeningHours(value.openingHours),
      isInDisplayStatus: this.getDisplayStatus(value.isInDisplay),
      stockInfoStatus: this.getStockInfoStatus(value.stockInfo.stockLevelStatus),
    };
  }

  @Input() control: AbstractControl = new FormControl();

  @Output() store = new EventEmitter<string>();

  constructor(
    private translationService: TranslationService,
    private openingHoursService: StorefinderOpeningHoursService,
  ) {}

  selectStore(name: string): void {
    this.store.emit(name);
  }

  getStockInfoTranslation(stockInfo: string): Observable<string> {
    let translation: string;

    switch (stockInfo) {
      case 'inStock':
        translation = translations.AVAILABLE;
        break;
      case 'lowStock':
        translation = translations.FEW_AVAILABLE;
        break;
      default:
        translation = translations.NOT_AVAILABLE;
        break;
    }

    return this.translationService.translate(`productDetails.storeAvailability.${translation}`);
  }

  private normalizeOpeningHours(openingHours: BossOpeningSchedule): string {
    return this.openingHoursService.normalizeOpeningHours(openingHours);
  }

  private getStockInfoStatus(stockInfo: string): string {
    let stockStatusLabel: string;

    switch (stockInfo) {
      case 'inStock':
        stockStatusLabel = BossStatusLabel.SUCCESS;
        break;
      case 'lowStock':
        stockStatusLabel = BossStatusLabel.WARNING;
        break;
      default:
        stockStatusLabel = BossStatusLabel.DANGER;
        break;
    }

    return stockStatusLabel;
  }

  private getDisplayStatus(isInDisplay: boolean): string {
    return isInDisplay ? BossStatusLabel.SUCCESS : BossStatusLabel.DANGER;
  }
}
