import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bossSearchHighlight' })
export class BossHighlightPipe implements PipeTransform {
  transform(text: string, part?: string): string {
    if (!part) return text;

    const regex = this.generateRegexSearch(part);

    if (!regex) return text;

    return text.replace(regex, (match) => `<span class="copy highlight">${match}</span>`);
  }

  private generateRegexSearch(text: string): RegExp {
    const searchWords = text
      .trim()
      .split(' ')
      .map((toReplace) =>
        toReplace
          .replace(/[^0-9A-Zäöüß]+/gi, '')
          .replace(/ae|a|ä/g, '(a|ä)')
          .replace(/oe|o|ö/g, '(o|ö)')
          .replace(/ue|u|ü/g, '(u|ü)')
          .replace(/ss|ß/, '(ss|ß)'),
      )
      .filter((f) => !!f);

    if (!searchWords.length) return;

    try {
      return new RegExp(`(${searchWords.join(')|(')})`, 'gi');
    } catch (e) {
      console.warn(e);
    }
  }
}
