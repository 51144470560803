import { IconConfig } from '@spartacus/storefront';

export const iconConfig: IconConfig = {
  icon: {
    symbols: {
      ADD: 'ri-add-line',
      CART: 'ri-shopping-cart-line',
      ARROW_UP: 'ri-arrow-up-line',
      ARROW_LEFT: 'ri-arrow-left-line',
      ARROW_RIGHT: 'ri-arrow-right-line',
      SEARCH: 'ri-search-line',
      STAR: 'ri-star-fill',
      CARET_UP: 'ri-arrow-up-s-line',
      CARET_DOWN: 'ri-arrow-down-s-line',
      CARET_RIGHT: 'ri-arrow-right-s-line',
      CARET_LEFT: 'ri-arrow-left-s-line',
      SUCCESS: 'ri-checkbox-circle-fill',
      CLOSE: 'ri-close-fill',
      CREDIT_CARD: 'ri-bank-card-fill',
      COLLAPSE: 'ri-subtract-fill',
      EXPAND: 'ri-add-fill',
      RESET: 'ri-close-circle-fill',
      CIRCLE: 'ri-checkbox-blank-circle-fill',
      HEART: 'ri-heart-fill',
      EMPTY_HEART: 'ri-heart-line',
      FILTER: 'ri-equalizer-line',
      PENCIL: 'ri-pencil-fill',
      CLOCK: 'ri-time-line',
      TRASH: 'ri-delete-bin-7-fill',
      ACTIVE: 'ri-check-fill',
      ON: 'ri-toggle-fill',
      OFF: 'ri-toggle-line',
      LINK_OUT: 'ri-external-link-fill',
      SORT_DOWN: 'ri-arrow-down-s-fill',
      SORT: 'ri-sort-desc', // remix does not have sort icon similar to fa
      SUBTRACT: 'ri-subtract-line',
    },
  },
};
