import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { BossHrefPipeModule } from '../../shared/pipes/boss-href.module';
import { BossParagraphComponent } from './paragraph.component';
import { BossSafePipeModule } from '../../shared/pipes/boss-safe-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    BossHrefPipeModule,
    BossSafePipeModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSParagraphComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-shared" */
              './paragraph.component'
            ).then((m) => m.BossParagraphComponent),
        },
        CMSTabParagraphComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-shared" */
              './paragraph.component'
            ).then((m) => m.BossParagraphComponent),
        },
        CMSParagraphNewComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-shared" */
              './paragraph.component'
            ).then((m) => m.BossParagraphComponent),
        },
        CMSParagraphWithoutEditorComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-shared" */
              './paragraph.component'
            ).then((m) => m.BossParagraphComponent),
        },
      },
    }),
  ],
  declarations: [BossParagraphComponent],
  exports: [BossParagraphComponent],
})
export class BossParagraphComponentModule {}
