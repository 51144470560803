<ng-container *ngIf="product$ | async as product">
  <section>
    <h4 class="boss-h4 mb-3 text-transform-none">Produktbeschreibung</h4>
    <div class="row mb-4 mb-md-5">
      <div class="col-12 col-md-6">
        <p class="small-copy" [innerHTML]="product.description"></p>
      </div>
      <div class="col-12 col-md-6">
        <div *ngIf="product.code as code" class="small-copy-bold mb-3 pb-md-2">
          <span class="mr-1">{{ 'productDetails.itemCode' | cxTranslate }}</span>
          <span>{{ code }}</span>
        </div>

        <!-- Specification documents -->
        <ng-container *ngIf="product.specificationDocuments as specDocuments">
          <span *ngIf="specDocuments?.length" class="pdf-icon">
            <i bossIcon [type]="bossIconConfig.PDF_FILE" aria-hidden="true"></i>
            <span>Produktdatenblatt</span>
            <cx-generic-link *ngFor="let document of specDocuments; let i = index" [url]="document.url" target="_blank">
              ({{ i + 1 }})</cx-generic-link
            >
          </span>
        </ng-container>

        <!-- Other documents -->
        <ng-container *ngIf="product.documents as documents">
          <div class="documents" *ngIf="documents?.length">
            <boss-document-item *ngFor="let document of product.documents" [document]="document"></boss-document-item>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <ng-container *ngIf="product.factGroups as factGroups">
    <ng-container *ngFor="let factGroup of factGroups">
      <boss-simple-collapse [header]="factGroup.header">
        <div class="product-information align-items-start">
          <div class="cols" *ngFor="let fact of factGroup.facts">
            <div class="small-copy-bold d-flex align-items-start">
              <i bossIcon class="mr-2" [type]="bossIconConfig.CHECKBOX_CIRCLE_LINE" aria-hidden="true"></i>
              <span [innerHTML]="fact | bossSafe"></span>
            </div>
          </div>
        </div>
      </boss-simple-collapse>
      <hr class="m-0 boss-nude-line product-information-sep" />
    </ng-container>
  </ng-container>

  <!-- 3d -->
  <boss-product-room-player [product]="product"></boss-product-room-player>
</ng-container>
