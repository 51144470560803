import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig({
      featureModules: {
        'boss-kitchen-planner': {
          module: () =>
            import(
              /* webpackChunkName: "boss-kitchen-planner" */
              './boss-kitchen-planner.module'
            ).then((m) => m.BossKitchenPlannerModule),
          cmsComponents: ['kitchenPlanerComponent'],
        },
      },
    } as CmsConfig),
  ],
})
export class BossKitchenPlannerFeatureModule {}
