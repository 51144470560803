import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, interval, Observable, Subject, Subscription } from 'rxjs';
import { WindowRef } from '@spartacus/core';
import { throttle } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BossScrolledDownService implements OnDestroy {
  private windowScrolled: boolean;

  scrolledOutputStream = new Subject<boolean>();

  scrolledToBottomStream = new Subject<boolean>();

  private subscription = new Subscription();

  constructor(private winRef: WindowRef) {
    if (this.winRef.isBrowser()) {
      this.subscription.add(
        fromEvent(window, 'scroll')
          .pipe(throttle(() => interval(100)))
          .subscribe(() => {
            const scrollTriggerPosition = window.outerHeight * 1.25;

            if (window.scrollY > scrollTriggerPosition) {
              this.windowScrolled = true;
              this.scrolledOutputStream.next(this.windowScrolled);
            } else if (
              (this.windowScrolled && window.scrollY) ||
              document.documentElement.scrollTop ||
              document.body.scrollTop < 10
            ) {
              this.windowScrolled = false;
              this.scrolledOutputStream.next(this.windowScrolled);
            }

            let scrolledToBottom = false;

            if (window.scrollY + window.innerHeight >= window.document.body.scrollHeight) {
              scrolledToBottom = true;
            }

            this.scrolledToBottomStream.next(scrolledToBottom);
          }),
      );
    }
  }

  isScrolledDown(): Observable<boolean> {
    return this.scrolledOutputStream;
  }

  isScrolledToBottom(): Observable<boolean> {
    return this.scrolledToBottomStream;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
