import { ChangeDetectionStrategy, Component } from '@angular/core';
import { bossIconConfig } from '../../utils/boss-icon-config';
import { BossDialogService } from '../dialog/boss-dialog.service';

@Component({
  selector: 'boss-modal-close-button',
  templateUrl: './boss-modal-close-button.component.html',
  styleUrls: ['./boss-modal-close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossModalCloseButtonComponent {
  bossIconConfig = bossIconConfig;

  constructor(private dialogService: BossDialogService) {}

  close(): void {
    this.dialogService.close();
  }
}
