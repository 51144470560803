import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

export class TooltipBase {
  public isOpen = false;
  public isDesktop: Observable<boolean>;

  constructor(private breakpointService: BreakpointService) {
    this.isDesktop = breakpointService.isUp(BREAKPOINT.md);
  }

  public toggleTooltip(): void {
    this.isOpen = !this.isOpen;
  }
}
