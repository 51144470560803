import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class BossProductVariantGuard implements CanActivate {
  canActivate(): Observable<boolean | UrlTree> {
    //this guard was blocking some variants to be displayed ticket : BSN-102
    //we don't need thw feature provided by this guard so return a simple true
    return of(true);
  }
}
