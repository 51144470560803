import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { BossFooterPaymentComponent } from './footer-payment.component';
import { MediaModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { BossDecodeUriPipeModule } from '../../../shared/pipes/encode-uri/boss-decode-uri-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        PaymentServiceBarComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-footer" */
              './footer-payment.component'
            ).then((m) => m.BossFooterPaymentComponent),
        },
      },
    }),
    MediaModule,
    BossDecodeUriPipeModule,
  ],
  declarations: [BossFooterPaymentComponent],
  exports: [BossFooterPaymentComponent],
})
export class BossFooterPaymentComponentModule {}
