<div class="boss-facets-dialog-buttons">
  <ng-container *ngIf="facetList?.activeFacets?.length > 0; else closeButton">
    <button (click)="removeAllFilters()" class="boss-link" type="button"
      >{{ 'productList.removeAllFilter' | cxTranslate }}
    </button>

    <button (click)="close()" class="boss-btn boss-btn-primary" type="button">
      {{ totalResults }} {{ 'productList.filterBy.action' | cxTranslate }}
    </button>
  </ng-container>

  <ng-template #closeButton>
    <button (click)="close()" class="boss-btn boss-btn-transparent" type="button">
      {{ 'productList.filterBy.close' | cxTranslate }}
    </button>
  </ng-template>
</div>
