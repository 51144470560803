import { Injectable } from '@angular/core';
import { BossAddressRepository } from './boss-address-repository';
import { Observable } from 'rxjs';
import { Address } from '@spartacus/core';
import { BossOccDeliveryAddressService } from '../occ/boss-occ-delivery-address.service';

@Injectable()
export class BossDeliveryAddressRepository extends BossAddressRepository {
  constructor(private occDeliveryAddressService: BossOccDeliveryAddressService) {
    super();
  }

  getAddressFromApi(): Observable<Address> {
    return this.occDeliveryAddressService.getDeliveryAddress();
  }
}
