import { NgModule } from '@angular/core';
import { BossConsentComponent } from './boss-consent.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [BossConsentComponent],
  imports: [CommonModule, RouterModule, I18nModule],
  exports: [BossConsentComponent],
})
export class BossConsentModule {}
