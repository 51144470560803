import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule, UrlModule } from '@spartacus/core';
import { ModalModule } from '@spartacus/storefront';
import { BossForgotPasswordFormComponentModule } from '../../../features/forgot-password-form/forgot-password-form.module';
import { BossForgotPasswordDialogComponent } from './forgot-password-dialog.component';
import { BossModalCloseButtonModule } from '../boss-modal-close-button/boss-modal-close-button.module';

@NgModule({
  imports: [
    CommonModule,
    UrlModule,
    I18nModule,
    ModalModule,
    BossForgotPasswordFormComponentModule,
    BossModalCloseButtonModule,
  ],
  declarations: [BossForgotPasswordDialogComponent],
  exports: [BossForgotPasswordDialogComponent],
})
export class BossForgotPasswordDialogComponentModule {}
