import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BossProductStatusComponent } from './boss-product-status.component';
import { BossProductStatusService } from './boss-product-status.service';

@NgModule({
  declarations: [BossProductStatusComponent],
  imports: [CommonModule],
  exports: [BossProductStatusComponent],
  providers: [BossProductStatusService],
})
export class BossProductStatusComponentModule {}
