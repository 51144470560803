import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossOrderDetailShippingComponent } from './order-detail-shipping.component';
import { AuthGuard, CmsConfig, provideDefaultConfig } from '@spartacus/core';
import { CardModule } from '@spartacus/storefront';

@NgModule({
  declarations: [BossOrderDetailShippingComponent],
  exports: [BossOrderDetailShippingComponent],
  imports: [CommonModule, CardModule],
  providers: [
    provideDefaultConfig({
      cmsComponents: {
        AccountOrderDetailsShippingComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-my-account" */
              './order-detail-shipping.component'
            ).then((m) => m.BossOrderDetailShippingComponent),
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
  ],
})
export class BossOrderDetailShippingModule {}
