import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActiveCartService, Address, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BossOccBillingAddressService } from '../occ/boss-occ-billing-address.service';
import { BossBillingAddressRepository } from '../repository/boss-billing-address.repository';
import { country } from '../../../shared/utils/boss-constants';

@Injectable()
export class BossBillingAddressService {
  constructor(
    private activeCartService: ActiveCartService,
    private occBillingAddressService: BossOccBillingAddressService,
    private globalMessageService: GlobalMessageService,
    private billingAddressRepository: BossBillingAddressRepository,
  ) {}

  setBillingAddress(address: Address): Observable<Address> {
    const addressWithCountry: Address = {
      ...address,
      country,
    };

    return addressWithCountry?.id
      ? this.updateAddress(addressWithCountry)
      : this.createAndSetAddress(addressWithCountry);
  }

  updateAddress(address: Address): Observable<Address> {
    return this.occBillingAddressService.updateBillingAddress(address.id, address).pipe(
      catchError(() => of(address)),
      map(() => {
        this.billingAddressRepository.next(address);
        return address;
      }),
    );
  }

  createAndSetAddress(address: Address): Observable<Address> {
    return this.occBillingAddressService.createAndSetAddress(address).pipe(
      map((response: Address) => {
        this.billingAddressRepository.next(response);
        if (!this.activeCartService.isGuestCart()) {
          this.globalMessageService.add(
            { key: 'bossDeliveryAddress.saveAddressMessage' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION,
          );
        }
        return response;
      }),
    );
  }

  remove(): Observable<Address> {
    return this.activeCartService.getActiveCartId().pipe(
      switchMap((cartId: string) => {
        return cartId ? this.occBillingAddressService.remove() : of({});
      }),
      map((address: Address) => {
        this.billingAddressRepository.reset();
        return address;
      }),
    );
  }
}
