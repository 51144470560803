import { Injectable } from '@angular/core';
import { CanonicalUrlOptions, PageLinkService } from '@spartacus/core';
import { subdomain } from '../utils/boss-constants';

@Injectable({ providedIn: 'root' })
export class BossPageLinkService extends PageLinkService {
  getCanonicalUrl(options?: CanonicalUrlOptions, url?: string): string {
    const config: CanonicalUrlOptions = {
        ...this.pageMetaConfig?.pageMeta?.canonicalUrl,
        ...options,
      },
      href: string = this.winRef.location.href,
      excludeSubdomain: string = href.includes(subdomain) ? href.replace(`${subdomain}.`, '') : href;

    return this.buildCanonicalUrl(url ?? excludeSubdomain ?? '', config);
  }
}
