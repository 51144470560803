import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FeaturesConfigModule } from '@spartacus/core';
import {
  AnonymousConsentsDialogModule,
  GlobalMessageComponentModule,
  KeyboardFocusModule,
  OutletModule,
  OutletRefModule,
  PageLayoutModule,
  PageSlotModule,
  SkipLinkModule,
} from '@spartacus/storefront';
import { BossStorefrontComponent } from './boss-storefront.component';
import { HideHeaderDirective } from './directives/hide-header/hide-header.directive';
import { BossGlobalMessageModule } from '../boss-global-message/boss-global-message.module';
import { BossBackToTopModule } from '../../shared/components/back-to-top/back-to-top.module';
import { BossSeoModule } from '../seo/boss-seo.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GlobalMessageComponentModule,
    OutletModule,
    OutletRefModule,
    PageLayoutModule,
    PageSlotModule,
    AnonymousConsentsDialogModule,
    FeaturesConfigModule,
    SkipLinkModule,
    KeyboardFocusModule,
    BossGlobalMessageModule,
    BossBackToTopModule,
    BossSeoModule,
  ],
  declarations: [BossStorefrontComponent, HideHeaderDirective],
  exports: [BossStorefrontComponent],
})
export class BossStorefrontComponentModule {}
