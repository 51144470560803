import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { BossHamburgerMenuComponent } from './boss-hamburger-menu.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        HamburgerMenuComponent: {
          component: BossHamburgerMenuComponent,
        },
      },
    }),
  ],
  declarations: [BossHamburgerMenuComponent],
  exports: [BossHamburgerMenuComponent],
})
export class BossHamburgerMenuModule {}
