import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Cart, CartModification } from '@spartacus/core';
import { BossCartEntryAdapter } from './boss-cart-entry.adapter';
import { GiftCardApplyRequest } from '../../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class BossCartEntryConnector {
  constructor(private adapter: BossCartEntryAdapter) {}

  public addWithStore(
    userId: string,
    cartId: string,
    productCode: string,
    pickupStore: string,
    quantity?: number,
  ): Observable<CartModification> {
    return this.adapter.addWithStore(userId, cartId, productCode, pickupStore, quantity);
  }

  public updateWithReturnAmount(
    userId: string,
    cartId: string,
    entryNumber: string,
    qty: number,
    returnAmount?: number,
  ): Observable<CartModification> {
    return this.adapter.updateWithReturnAmount(userId, cartId, entryNumber, qty, returnAmount);
  }

  public addGiftCard(userId: string, cartId: string, giftCardApplyRequest: GiftCardApplyRequest): Observable<Cart> {
    return this.adapter.addGiftCard(userId, cartId, giftCardApplyRequest);
  }

  public removeGiftCard(userId: string, cartId: string, giftcard: string): Observable<Cart> {
    return this.adapter.removeGiftCard(userId, cartId, giftcard);
  }

  public removeAllGiftCards(userId: string, cartId: string): Observable<Cart> {
    return this.adapter.removeAllGiftCards(userId, cartId);
  }
}
