import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FeatureConfigService, Product } from '@spartacus/core';
import { BossPriceType } from './price-type.model';
import { BossComponentType } from './component-type.model';
import { BossProductPriceService } from '../../../features/product/product-price/services/product-price.service';

@Component({
  selector: 'boss-price[product]',
  templateUrl: './boss-price.component.html',
  styleUrls: ['./boss-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossPriceComponent implements OnChanges {
  @Input()
  product: Product;

  @Input()
  componentType: BossComponentType = BossComponentType.DEFAULT;

  priceType: BossPriceType = BossPriceType.REGULAR;

  BossPriceType = BossPriceType;

  constructor(private productPriceService: BossProductPriceService, private featureConfig: FeatureConfigService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const productChanges = changes.product;
    if (productChanges?.currentValue?.code !== productChanges?.previousValue?.code) {
      this.setPriceType();
    }
  }

  private setPriceType(): void {
    if (this.product?.priceDisplayType) {
      const bossPriceType =
        Object.keys(BossPriceType)[
          Object.values(BossPriceType).indexOf(this.product?.priceDisplayType as BossPriceType)
        ];

      this.priceType = BossPriceType[bossPriceType] ? BossPriceType[bossPriceType] : BossPriceType.REGULAR;

      // Double check crossed price
      if (this.productPriceService.isPriceCrossed(this.product)) {
        this.priceType = BossPriceType.CROSSED;
      }

      // Shrunk price promotion
      if (this.featureConfig.isEnabled('shrunkPricePromotion')) {
        if (this.priceType === BossPriceType.BOSS || this.priceType === BossPriceType.STABLE_LOW) {
          this.priceType = BossPriceType.SHRUNK;
        }
      }
    }
  }
}
