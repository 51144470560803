import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OccEndpointsService, SearchConfig, SearchboxService, StateWithProduct } from '@spartacus/core';
import { Observable, Subject, iif, of } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { BossOccSuggestions, BossSuggestionSearchConfig } from './boss-search-box.model';

@Injectable({
  providedIn: 'root',
})
export class BossSearchboxService extends SearchboxService {
  private readonly triggerSearch$: Subject<BossSuggestionSearchConfig> = new Subject();

  private readonly searchCache$: Map<string, BossOccSuggestions> = new Map();

  constructor(store: Store<StateWithProduct>, private occEndpoints: OccEndpointsService, private http: HttpClient) {
    super(store);
  }

  bossGetSuggestionResults(): Observable<BossOccSuggestions> {
    return this.triggerSearch$.pipe(
      switchMap((config: BossSuggestionSearchConfig) =>
        iif(
          () => !!config?.query,
          this.searchCache$.has(config.query)
            ? of(this.searchCache$.get(config.query))
            : this.performSearch(config).pipe(
                tap((suggestions: BossOccSuggestions) => {
                  this.searchCache$.set(config.query, suggestions);
                  this.tryClearSearchCache();
                }),
              ),
          // emulate empty search to reset results
          of({} as BossOccSuggestions),
        ),
      ),
    );
  }

  /**
   * Used to force empty results
   */
  forceEmptyResults(): void {
    this.triggerSearch$.next({} as BossSuggestionSearchConfig);
  }

  searchSuggestions(query: string, config?: SearchConfig): void {
    this.triggerSearch$.next({ query, config });
  }

  private getSuggestionEndpoint(term: string, max: string = '5'): string {
    return this.occEndpoints.buildUrl('productSuggestions', {
      urlParams: { componentUid: 'SearchBox' }, // 'SearchBox' intentionally hardcoded
      queryParams: { term, max },
    });
  }

  private performSearch(config: BossSuggestionSearchConfig): Observable<BossOccSuggestions> {
    return this.http
      .get<BossOccSuggestions>(this.getSuggestionEndpoint(config.query, config.config?.pageSize?.toString()))
      .pipe(shareReplay());
  }

  /**
   * This method allows us to prevent cache becoming extra bloated after multiple searches.
   * It will clear cache after it contains X items inside.
   */
  private tryClearSearchCache(): void {
    if (this.searchCache$.size > 50) {
      this.searchCache$.clear();
    }
  }
}
