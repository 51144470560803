import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { HeadlineIntroComponentCmsData } from '../../shared/models';

@Component({
  selector: 'boss-headline-and-intro',
  templateUrl: './headline-and-intro.component.html',
  styleUrls: ['./headline-and-intro.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossHeadlineAndIntroComponent {
  data$ = this.cmsComponentData.data$;

  constructor(private cmsComponentData: CmsComponentData<HeadlineIntroComponentCmsData>) {}
}
