import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ActiveCartService,
  CheckoutActions,
  CheckoutService,
  StateWithCheckout,
  StateWithProcess,
  UserIdService,
} from '@spartacus/core';
import { BossPlaceOrder } from './boss-checkout.action';

@Injectable()
export class BossCheckoutService extends CheckoutService implements OnDestroy {
  private onDestroy$ = new Subject<void>();

  constructor(
    protected checkoutStore: Store<StateWithCheckout | StateWithProcess<void>>,
    protected activeCartService: ActiveCartService,
    protected userIdService: UserIdService,
  ) {
    super(checkoutStore, activeCartService, userIdService);
  }

  /**
   * Places an order
   */
  bossPlaceOrder(cartId: string, termsChecked: boolean): void {
    let userId;
    this.userIdService
      .getUserId()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((occUserId) => (userId = occUserId));

    //quick workaround of this bug :https://github.com/SAP/spartacus/issues/5596
    setTimeout(() => {
      this.checkoutStore.dispatch(
        new CheckoutActions.PlaceOrder({
          userId,
          cartId,
          termsChecked,
        }),
      );
    }, 400);
  }

  bossPlacePayEverOrder(cartId: string, payment_id: string, callId: string, termsChecked: boolean): void {
    let userId;
    this.userIdService
      .getUserId()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((occUserId) => (userId = occUserId));
    //quick workaround of this bug :https://github.com/SAP/spartacus/issues/5596
    setTimeout(() => {
      this.checkoutStore.dispatch(
        new BossPlaceOrder({
          userId,
          cartId,
          payment_id,
          callId,
          termsChecked,
        }),
      );
    }, 400);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
