import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FacetList } from '@spartacus/storefront';
import { ProductSearchPage } from '@spartacus/core';
import { BossProductListComponentService } from '../../product/product-list/services/product-list-component.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'boss-facets-dialog-buttons[facetList]',
  templateUrl: './boss-facets-dialog-buttons.component.html',
  styleUrls: ['./boss-facets-dialog-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossFacetsDialogButtonsComponent implements OnInit, OnDestroy {
  @Input()
  facetList!: FacetList;

  @Output()
  removeFilters = new EventEmitter();

  @Output()
  closeDialog = new EventEmitter();

  totalResults: number;

  private onDestroy$ = new Subject<void>();

  constructor(
    private bossProductListComponentService: BossProductListComponentService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setTotalResults();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  removeAllFilters(): void {
    this.removeFilters.emit();
  }

  close(): void {
    this.closeDialog.emit();
  }

  private setTotalResults(): void {
    this.bossProductListComponentService.model$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((model: ProductSearchPage) => {
        this.totalResults = model.pagination.totalResults;
        this.cdRef.detectChanges();
      });
  }
}
