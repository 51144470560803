import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { Image, ImageGroup } from '@spartacus/core';
import { Media, MediaContainer, MediaService } from '@spartacus/storefront';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { BossHoverImageGroup } from '../../../features/product/product-list/product-card/hover-image-group.model';

@Directive({
  selector: 'img[bossMedia][container]',
  host: {
    class: 'cx-media is-loading',
  },
})
export class BossMediaDirective implements OnChanges, OnInit, OnDestroy {
  @Input()
  container!: MediaContainer | Image | ImageGroup | ImageGroup[] | BossHoverImageGroup | BossHoverImageGroup[];

  @Input()
  isAboveTheFold = false;

  @Input()
  format = '';

  media?: Media;

  private isInitialized: boolean = false;

  private observer?: IntersectionObserver;

  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: never,
    private mediaService: MediaService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.container) {
      this.handleContainer();
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.initializeIntersectionObserver();
    }

    fromEvent(this.elementRef.nativeElement, 'load')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadHandler();
      });

    fromEvent(this.elementRef.nativeElement, 'error')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.errorHandler();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private handleMissing(): void {
    this.removeClass('is-loading');
    this.initializeImg();
    this.renderer.addClass(this.elementRef.nativeElement, 'is-missing');
  }

  private handleContainer(): void {
    this.generateMedia();

    if (this.isAboveTheFold || this.isInitialized) {
      this.addSrcAttributes();
    }
  }

  private generateMedia(): void {
    const mediaContainer = this.container instanceof Array ? this.container[0] : this.container;
    this.media = this.mediaService.getMedia(mediaContainer, this.format);

    if (this.media?.alt) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'alt', this.media.alt);
    }

    if (!this.media?.src) {
      this.handleMissing();
    }
  }

  private initializeIntersectionObserver(): void {
    if (!this.isAboveTheFold) {
      this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            this.addSrcAttributes();
            this.observer?.disconnect();
          }
        });
      });

      this.observer.observe(this.elementRef.nativeElement);
    }
  }

  private addSrcAttributes(): void {
    if (this.media?.src) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'src', this.media.src);
    }

    if (this.media?.srcset && !this.format) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'srcset', this.media.srcset);
    } else {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'srcset', '');
    }
  }

  private loadHandler(): void {
    this.removeClass('is-loading');
    this.initializeImg();
  }

  private errorHandler(): void {
    this.handleMissing();
  }

  private initializeImg(): void {
    this.addClass('is-initialized');
    this.isInitialized = true;
  }

  private addClass(cssClass: string): void {
    this.renderer.addClass(this.elementRef.nativeElement, cssClass);
  }

  private removeClass(cssClass: string): void {
    this.renderer.removeClass(this.elementRef.nativeElement, cssClass);
  }
}
