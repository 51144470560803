import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossDialogWrapperComponent } from './boss-dialog-wrapper.component';
import { BossDialogModule } from '../boss-dialog.module';

@NgModule({
  declarations: [BossDialogWrapperComponent],
  imports: [CommonModule, BossDialogModule],
  exports: [BossDialogWrapperComponent],
})
export class BossDialogWrapperModule {}
