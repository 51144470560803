import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossEnergyLabelsComponent } from './boss-energy-labels.component';
import { BossModalCloseButtonModule } from '../boss-modal-close-button/boss-modal-close-button.module';
import { IconModule } from '@spartacus/storefront';
import { BossEnergyLabelComponent } from './boss-energy-label/boss-energy-label.component';
import { BossEnergyLabelModalComponent } from './boss-energy-label/boss-energy-label-modal/boss-energy-label-modal.component';

@NgModule({
  declarations: [BossEnergyLabelComponent, BossEnergyLabelsComponent, BossEnergyLabelModalComponent],
  imports: [CommonModule, IconModule, BossModalCloseButtonModule],
  exports: [BossEnergyLabelComponent, BossEnergyLabelsComponent],
})
export class BossEnergyLabelsModule {}
