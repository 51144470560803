import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';

// import Swiper core and required modules
import SwiperCore, { Navigation, Virtual, Pagination, Autoplay, Lazy, Zoom } from 'swiper';

// install Swiper modules
SwiperCore.use([Virtual, Navigation, Pagination, Autoplay, Lazy, Zoom]);

@NgModule({
  imports: [CommonModule, SwiperModule],
  exports: [SwiperModule],
})
export class BossCarouselBaseModule {}
