import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossBreadcrumbComponent } from './breadcrumb.component';
import { ConfigModule, CmsConfig, UrlModule, I18nModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { SpinnerModule } from '@spartacus/storefront';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [BossBreadcrumbComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BreadcrumbComponent: {
          component: BossBreadcrumbComponent,
        },
      },
    } as CmsConfig),
    I18nModule,
    UrlModule,
    RouterModule,
    SpinnerModule,
    BossIconDirectiveModule,
  ],
})
export class BossBreadcrumbModule {}
