import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveCartService, CheckoutService, Order, OrderEntry } from '@spartacus/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { BossWishlist } from '../wishlist/wishlist.model';
import { BossWishlistService } from '../wishlist/wishlist.service';

@Component({
  selector: 'boss-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossMiniCartComponent implements OnInit, OnDestroy {
  order$: Observable<Order>;
  total$: Observable<string>;
  quantity$: Observable<number>;
  entries$: Observable<OrderEntry[]>;
  wishlist$: BehaviorSubject<BossWishlist> = new BehaviorSubject({});
  bossIconConfig = bossIconConfig;

  private optInOrderGuid: string;
  private subscription = new Subscription();

  constructor(
    public activeCartService: ActiveCartService,
    private wishlistService: BossWishlistService,
    private checkoutService: CheckoutService,
  ) {
    this.order$ = this.checkoutService.getOrderDetails();
    this.subscription.add(this.order$.pipe(map((order: Order) => (this.optInOrderGuid = order?.guid))).subscribe());

    /* in order to avoid the "cart not found" error message on order confirmation page,
     *  try to get order information before calling cart api,
     *  if order guid is undefined => means not order confirmation page, then obtain mini cart data as usual,
     *  if order guid is not undefined => means the old cart is already converted to the order, then do not call cart api
     */
    if (this.optInOrderGuid == undefined) {
      this.total$ = this.activeCartService.getActive().pipe(map((cart) => cart.totalPrice?.formattedValue));
      this.quantity$ = this.activeCartService
        .getActive()
        .pipe(map((cart) => cart.deliveryItemsQuantity + cart.pickupItemsQuantity || 0));
      this.entries$ = this.activeCartService.getActive().pipe(map((cart) => cart.entries));
    } else {
      this.quantity$ = of(0);
    }
  }

  public ngOnInit(): void {
    this.retrieveWishlist();
  }

  private retrieveWishlist(): void {
    this.subscription.add(
      this.wishlistService.getWishlist().subscribe((wishlist: BossWishlist) => {
        this.wishlist$.next(wishlist);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
