import { Injectable } from '@angular/core';
import { BreadcrumbMeta, PageRobotsMeta, Product, ProductPageMetaResolver, ProductScope } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BossProductPageMetaResolver extends ProductPageMetaResolver {
  private bossSiteName = 'Möbel Boss';

  product$: Observable<Product> = this.routingService.getRouterState().pipe(
    map((state) => state.state.params['productCode']),
    filter((code) => !!code),
    switchMap((code) => this.productService.get(code, ProductScope.DETAILS)),
    filter((p) => Boolean(p)),
  );

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return this.basePageMetaResolver.resolveRobots();
  }

  resolveCanonicalUrl(): Observable<string> {
    return this.basePageMetaResolver.resolveCanonicalUrl();
  }

  // Override basic resolver to build breadcrimbs over breadCrumbCategories
  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return this.product$.pipe(
      map((product) => {
        const breadcrumbs = [];
        for (const { name, code, url } of product['breadCrumbCategories'] || []) {
          breadcrumbs.push({
            label: name || code,
            link: url,
          } as BreadcrumbMeta);
        }
        breadcrumbs.push({
          label: product.name,
          link: 'PDP',
        } as BreadcrumbMeta);
        return breadcrumbs;
      }),
    );
  }

  resolveTitle(): Observable<string> {
    return this.product$.pipe(
      map((product) => {
        return product.name + ' | ' + this.bossSiteName;
      }),
    );
  }

  resolveDescription(): Observable<string> {
    return this.product$.pipe(map((data) => data.metaDescription));
  }
}
