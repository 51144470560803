import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MultiCartService, StateWithMultiCart, UserIdService } from '@spartacus/core';
import { BossCartActions } from './index';

@Injectable({
  providedIn: 'root',
})
export class BossMultiCartService extends MultiCartService {
  constructor(protected store: Store<StateWithMultiCart>, protected userIdService: UserIdService) {
    super(store, userIdService);
  }

  addEntryWithStore(userId: string, cartId: string, productCode: string, pickupStore: string, quantity: number): void {
    this.store.dispatch(
      new BossCartActions.CartAddEntryWithStore({
        userId,
        cartId,
        productCode,
        pickupStore,
        quantity,
      }),
    );
  }

  updateEntryWithReturnAmount(
    userId: string,
    cartId: string,
    entryNumber: number,
    quantity: number,
    returnAmount: number,
  ): void {
    this.store.dispatch(
      new BossCartActions.CartUpdateEntryWithReturnAmount({
        userId,
        cartId,
        entryNumber: `${entryNumber}`,
        quantity,
        returnAmount,
      }),
    );
  }
}
