<div class="boss-collapse">
  <button class="boss-collapse-main" (click)="toggle()">
    <p class="boss-collapse-headline">{{ headline }}</p>

    <div class="boss-collapse-expand">
      <i bossIcon [type]="isExpanded ? bossIconConfig.CARET_UP : bossIconConfig.CARET_DOWN" aria-hidden="true"></i>
    </div>
  </button>

  <span>
    <ng-content select="sub-headline"></ng-content>
  </span>

  <div *ngIf="isExpanded" class="boss-collapse-body">
    <ng-content></ng-content>
  </div>
</div>
