import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossBuyBoxModule } from './components/buy-box/boss-buy-box.module';
import { BossProductDescriptionComponentModule } from './components/product-description/product-description.module';
import { BossProductImagesComponentModule } from './components/product-images/product-images.module';
import { BossProductReviewsComponentModule } from './components/product-reviews/product-reviews.module';

@NgModule({
  imports: [
    CommonModule,
    BossBuyBoxModule,
    BossProductDescriptionComponentModule,
    BossProductImagesComponentModule,
    BossProductReviewsComponentModule,
  ],
})
export class BossProductDetailsPageModule {}
