import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossDeliveryAddressService } from './service/boss-delivery-address.service';
import { BossDeliveryAddressRepository } from './repository/boss-delivery-address.repository';
import { BossBillingAddressRepository } from './repository/boss-billing-address.repository';
import { BossOccDeliveryAddressService } from './occ/boss-occ-delivery-address.service';
import { BossOccBillingAddressService } from './occ/boss-occ-billing-address.service';
import { BossBillingAddressService } from './service/boss-billing-address.service';
import { BossAddressBookService } from './service/boss-address-book.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    BossOccDeliveryAddressService,
    BossOccBillingAddressService,
    BossDeliveryAddressService,
    BossDeliveryAddressRepository,
    BossBillingAddressRepository,
    BossBillingAddressService,
    BossAddressBookService,
  ],
})
export class BossAddressFeatureModule {}
