import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlobalMessageComponent } from '@spartacus/storefront';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';

@Component({
  selector: 'boss-global-message',
  templateUrl: './boss-global-message.component.html',
  styleUrls: ['./boss-global-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossGlobalMessageComponent extends GlobalMessageComponent {
  bossIconConfig = bossIconConfig;
}
