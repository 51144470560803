import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';
import { BossFooterMiddleComponent } from './footer-middle.component';
import { FooterMiddleService } from './footer-middle.service';
import { BossIconDirectiveModule } from '../../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    UrlModule,
    MediaModule,
    GenericLinkModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FooterMiddleComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-footer" */
              './footer-middle.component'
            ).then((m) => m.BossFooterMiddleComponent),
        },
      },
    }),
  ],
  declarations: [BossFooterMiddleComponent],
  exports: [BossFooterMiddleComponent],
  providers: [FooterMiddleService],
})
export class BossFooterMiddleComponentModule {}
