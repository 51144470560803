import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { EnergyEfficiency } from '../../../models';
import { BossEnergyLabelModalComponent } from './boss-energy-label-modal/boss-energy-label-modal.component';
import { Product } from '@spartacus/core';
import { ENERGY_LABEL_TYPE } from '../../../../icons/boss-energy-label-type';
import { BossDialogService } from '../../dialog/boss-dialog.service';
import { BossDialogContent } from '../../dialog/boss-dialog-content';
import { BossDialogSize, BossDialogType } from '../../dialog/boss-dialog.model';

@Component({
  selector: 'boss-energy-label[energyEfficiency]',
  templateUrl: './boss-energy-label.component.html',
  styleUrls: ['./boss-energy-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossEnergyLabelComponent implements OnChanges {
  private static readonly OLD_ENERGY_CODES = ['FLD-9-11', 'FLD-9-9'];

  @Input()
  energyEfficiency!: EnergyEfficiency;

  iconType?: ENERGY_LABEL_TYPE;

  @Input() product: Product;

  constructor(private dialogService: BossDialogService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.energyEfficiency) {
      this.setIconType();
    }
  }

  setIconType(): void {
    if (this.energyEfficiency.energyClass) {
      if (this.isNewEnergyLabel()) {
        this.iconType = this.getNewEnergyLabelIcon();
      } else {
        this.iconType = this.getOldEnergyLabelIcon();
      }
    }
  }

  openLabelModal(): void {
    if (this.energyEfficiency.documentUrl) {
      this.dialogService.open(
        new BossDialogContent(BossEnergyLabelModalComponent, {
          type: BossDialogType.MODAL,
          size: BossDialogSize.MD,
          data: { product: this.product, energyEfficiency: this.energyEfficiency },
        }),
      );
    }
  }

  getNewEnergyLabelIcon(): ENERGY_LABEL_TYPE {
    switch (this.energyEfficiency.energyClass) {
      case 'A': {
        return ENERGY_LABEL_TYPE.NEW_ENERGY_LABEL_A;
      }
      case 'B': {
        return ENERGY_LABEL_TYPE.NEW_ENERGY_LABEL_B;
      }
      case 'C': {
        return ENERGY_LABEL_TYPE.NEW_ENERGY_LABEL_C;
      }
      case 'D': {
        return ENERGY_LABEL_TYPE.NEW_ENERGY_LABEL_D;
      }
      case 'E': {
        return ENERGY_LABEL_TYPE.NEW_ENERGY_LABEL_E;
      }
      case 'F': {
        return ENERGY_LABEL_TYPE.NEW_ENERGY_LABEL_F;
      }
      case 'G': {
        return ENERGY_LABEL_TYPE.NEW_ENERGY_LABEL_G;
      }
    }
  }

  getOldEnergyLabelIcon(): ENERGY_LABEL_TYPE {
    switch (this.energyEfficiency.energyClass) {
      case 'A++': {
        return ENERGY_LABEL_TYPE.OLD_ENERGY_LABEL_A_2;
      }
      case 'A+': {
        return ENERGY_LABEL_TYPE.OLD_ENERGY_LABEL_A_1;
      }
      case 'A': {
        return ENERGY_LABEL_TYPE.OLD_ENERGY_LABEL_A;
      }
      case 'B': {
        return ENERGY_LABEL_TYPE.OLD_ENERGY_LABEL_B;
      }
      case 'C': {
        return ENERGY_LABEL_TYPE.OLD_ENERGY_LABEL_C;
      }
      case 'D': {
        return ENERGY_LABEL_TYPE.OLD_ENERGY_LABEL_D;
      }
    }
  }

  private isNewEnergyLabel(): boolean {
    return !BossEnergyLabelComponent.OLD_ENERGY_CODES.includes(this.energyEfficiency.classificationCode);
  }
}
