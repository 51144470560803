import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
  Cart,
  CartActions,
  CartModification,
  normalizeHttpError,
  SiteContextActions,
  withdrawOn,
} from '@spartacus/core';
import { BossCartEntryConnector } from './cart-entry.connector';
import { BossCartActions } from './index';

@Injectable()
export class BossCartEntryEffects {
  private contextChange$ = this.actions$.pipe(
    ofType(SiteContextActions.CURRENCY_CHANGE, SiteContextActions.LANGUAGE_CHANGE),
  );

  @Effect()
  addEntry$: Observable<CartActions.CartAddEntrySuccess | CartActions.CartAddEntryFail | CartActions.LoadCart> =
    this.actions$.pipe(
      ofType(BossCartActions.CART_ADD_ENTRY_WITH_STORE),
      map((action: BossCartActions.CartAddEntryWithStore) => action.payload),
      concatMap((payload) => {
        return this.bossCartEntryConnector
          .addWithStore(payload.userId, payload.cartId, payload.productCode, payload.pickupStore, payload.quantity)
          .pipe(
            map(
              (cartModification: CartModification) =>
                new CartActions.CartAddEntrySuccess({
                  ...payload,
                  ...(cartModification as Required<CartModification>),
                }),
            ),
            catchError((error) =>
              from([
                new CartActions.CartAddEntryFail({
                  ...payload,
                  error: normalizeHttpError(error),
                }),
                new CartActions.LoadCart({
                  cartId: payload.cartId,
                  userId: payload.userId,
                }),
              ]),
            ),
          );
      }),
      withdrawOn(this.contextChange$),
    );

  @Effect()
  updateEntryWithReturnAmount$: Observable<
    CartActions.CartUpdateEntrySuccess | CartActions.CartUpdateEntryFail | CartActions.LoadCart
  > = this.actions$.pipe(
    ofType(BossCartActions.CART_UPDATE_ENTRY_WITH_RETURN_AMOUNT),
    map((action: BossCartActions.CartUpdateEntryWithReturnAmount) => action.payload),
    concatMap((payload) =>
      this.bossCartEntryConnector
        .updateWithReturnAmount(
          payload.userId,
          payload.cartId,
          payload.entryNumber,
          payload.quantity,
          payload.returnAmount,
        )
        .pipe(
          map(
            () =>
              new CartActions.CartUpdateEntrySuccess({
                ...payload,
              }),
          ),
          catchError((error) =>
            from([
              new CartActions.CartUpdateEntryFail({
                ...payload,
                error: normalizeHttpError(error),
              }),
              new CartActions.LoadCart({
                cartId: payload.cartId,
                userId: payload.userId,
              }),
            ]),
          ),
        ),
    ),
    withdrawOn(this.contextChange$),
  );

  @Effect()
  addGiftCard$: Observable<BossCartActions.GiftCardAddSuccess | BossCartActions.GiftCardAddFail> = this.actions$.pipe(
    ofType(BossCartActions.GIFT_CARD_ADD),
    map((action: BossCartActions.GiftCardAdd) => action.payload),
    concatMap((payload) => {
      return this.bossCartEntryConnector.addGiftCard(payload.userId, payload.cartId, payload.giftCardApplyRequest).pipe(
        map(
          (cart: Cart) =>
            new BossCartActions.GiftCardAddSuccess({
              cart: cart,
              cartId: payload.cartId,
            }),
        ),
        catchError((error) =>
          of(
            new BossCartActions.GiftCardAddFail({
              ...payload,
              error: normalizeHttpError(error),
            }),
          ),
        ),
      );
    }),
    withdrawOn(this.contextChange$),
  );

  @Effect()
  removeGiftCard$: Observable<BossCartActions.GiftCardRemoveSuccess> = this.actions$.pipe(
    ofType(BossCartActions.GIFT_CARD_REMOVE),
    map((action: BossCartActions.GiftCardRemove) => action.payload),
    concatMap((payload) => {
      return this.bossCartEntryConnector.removeGiftCard(payload.userId, payload.cartId, payload.giftcard).pipe(
        map(
          (cart: Cart) =>
            new BossCartActions.GiftCardRemoveSuccess({
              cart: cart,
              cartId: payload.cartId,
            }),
        ),
      );
    }),
    withdrawOn(this.contextChange$),
  );

  @Effect()
  removeAllGiftCard$: Observable<BossCartActions.GiftCardRemoveAllSuccess> = this.actions$.pipe(
    ofType(BossCartActions.GIFT_CARD_REMOVE_ALL),
    map((action: BossCartActions.GiftCardRemoveAll) => action.payload),
    concatMap((payload) => {
      return this.bossCartEntryConnector.removeAllGiftCards(payload.userId, payload.cartId).pipe(
        map(
          (cart: Cart) =>
            new BossCartActions.GiftCardRemoveAllSuccess({
              cart: cart,
              cartId: payload.cartId,
            }),
        ),
      );
    }),
    withdrawOn(this.contextChange$),
  );

  constructor(private actions$: Actions, private bossCartEntryConnector: BossCartEntryConnector) {}
}
