export const product = {
  productDetails: {
    customerInfo:
      'Dieses Produkt können Sie in ausgewählten Möbelhäusern kaufen und konfigurieren. Unsere Mitarbeiter beraten Sie gerne.',
    additionalInfo: 'Weitere Informationen',
    availability: 'Verfügbarkeit online',
    hybridDelivery: 'In deiner Nähe verfügbar?',
    hybridDeliverySet: 'Verfügbarkeit für Postleitzahl ',
    hybridDeliveryButton: 'Prüfe plz.',
    selectStore: 'Möbelhaus auswählen',
    consultantAppointment: 'Beratungstermin vereinbaren',
    recommend: 'Weiterempfehlen',
    safePurchase: 'Sicherer Online-Kauf',
    bestFinancing: 'Beste Finanzierungskonditionen',
    itemCode: 'Artikelnummer:',
    chooseVariantButton: 'Farbe & Grösse wählen',
    saveUpTo: 'Spare bis zu',
    upTo: 'Bis zu',
    save: 'sparen',
    pickUpInStore: 'Im Markt abholen',
    checkDeliveryTime: 'Lieferzeit prüfen',
    discovered: 'Das habe ich gerade auf moebel-boss.de entdeckt ',
    quantity: 'Menge',
    productDetails: 'Produktdetails',
    specification: 'Ausstattung',
    reviews: 'Bewertungen',
    shipping: 'Versand',
    share: 'Teilen',
    showReviews: 'Zeige Bewertungen',
    noReviews: 'Noch keine Bewertungen',
    productZoom: 'Produktbild vergrößern',
    clickAndCollect: 'Click & Collect',
    subtract: 'reduzieren',
    increase: 'erhöhen',
    financingCalculator: {
      label: 'Finanzierungsrechner',
      labelValue: 'Finanzierungswert:',
      term: 'Laufzeit:',
      termPlaceholder: 'Laufzeit',
      month: 'Monat',
      errorMessage: 'Bitte geben Sie einen gültigen Finanzierungsbetrag ein!',
      detail: 'Finanzierungsdetails',
      netto: 'Nettodarlehensbetrag:',
      zins: 'Zinsen*:',
      rate: 'Raten',
      total: 'Gesamtbetrag:',
      sollzins: 'Gebundener Sollzinssatz (p.a.):',
      annualInterest: 'Effektiver Jahreszins:',
      disclaimer:
        '*Die vorstehenden Angaben stellen zugleich das\n' +
        '        repräsentative Beispiel gemäß § 6a Abs. 3 PAngV\n' +
        '        dar. Bonität vorausgesetzt. Dies ist ein Berechnungsbeispiel\n' +
        '        nach Ihren Angaben. Andere Finanzierungswünsche sind\n' +
        '        möglich. Ein konkretes Angebot erhalten Sie bei Stellung des\n' +
        '        Finanzierungsantrages.',
    },
    storeAvailability: {
      subheading:
        'Prüfe die Verfügbarkeit im Einrichtungshaus, bestelle online per Click & Collect und sieh, ob der Artikel im Einrichtungshaus ausgestellt ist.',
      showOnlyAvailable: 'Nur Märkte mit verfügbarem Produkt anzeigen',
      displayed: 'Ausgestellt',
      notDisplayed: 'Nicht ausgestellt',
      available: 'verfügbar',
      fewAvailable: 'Wenige verfügbar',
      notAvailable: 'Nicht verfügbar',
      noStoresFound: 'Leider ist dieses Produkt derzeit in keinen der Märkte verfügbar',
    },
    mail: {
      subject: 'Empfehlung von moebel-boss',
      body: 'Hallo, diesen Artikel möchte ich dir gerne zeigen: ',
    },
    priceInfo: {
      infoText: '* inkl. MwSt. zzgl. ',
      delivery: 'Versand',
      toolTipHeader: 'Versandkosten für diesen Artikel: ',
      toolTipBody: 'Bitte beachten Sie, dass die Versandkosten abhängig von der Gesamtbestellsumme sind.',
    },
    returnDevices: {
      infoBold: 'Hinweis:',
      infoText: 'Alle wichtigen Informationen zu der Entsorgung Ihres Altgerätes finden Sie in unseren',
      infoLink: 'Entsorgungshinweisen',
      label: 'Altgeräte-Mitnahme/Abgabe',
    },
    facets: {
      priceFrom: 'Filter {{filterName}} from',
      priceTo: 'Filter {{filterName}} to',
    },
    wishlist: {
      addThisProduct: 'Add this product to wishlist',
    },
    thumbnails: {
      label: 'Product image {{index}}',
    },
    zipCodeModal: {
      title: 'PLZ eingeben',
      subtitle:
        'Bitte gib hier deine Postleitzahl an, damit wir dir eine genaue Einschätzung der Lieferdauer geben können.',
    },
  },
  productList: {
    filterBy: {
      label: 'Filter auswählen',
      action: 'Artikel anzeigen',
      close: 'Filter schließen',
    },
    appliedFilter: 'Ausgewählte Filter:',
    showLess: 'Zeige weniger...',
    showMore: 'Zeige mehr...',
    sortByRelevance: 'Nach Relevanz sortieren',
    backToTopBtn: 'ZURÜCK ZUM ANFANG',
    showMoreBtn: 'Zeige mehr',
    currentScrollStatus: '{{current}} von {{total}} Produkten angezeigt',
    removeAllFilter: 'Alle Filter löschen',
    products: 'Produkte',
  },
  productFacetNavigation: {
    filterBy: {
      label: 'Filter übernehmen',
      action: 'Filter übernehmen',
    },
    appliedFilter: 'Angewandte Filter:',
    showLess: 'zeige weniger',
    showMore: 'zeige mehr',
    sortByRelevance: 'Nach Relevanz sortieren',
  },
  addToCart: {
    quantity: 'Menge',
    addToCart: 'Online bestellen',
    addToCartWishlist: 'In den Warenkorb',
    itemsAddedToYourCart: 'Produkt(e) zum Warenkorb hinzugefügt',
    itemsIncrementedInYourCart: 'Dieses Produkt befindet sich bereits in Ihrem Warenkorb. Die Menge wurde angepasst.',
    items: 'Produkte',
    updatingCart: 'Aktualisiere Warenkorb...',
    viewCart: 'Warenkorb ansehen',
    proceedToCheckout: 'Zur Kasse',
    outOfStock: 'Nicht verfügbar',
    inStock: 'Verfügbar',
    selectStyleAndSize: 'Wählen die Stil und Größe, um die Verfügbarkeit zu überprüfen.',
    success: 'Eine gute Wahl! Wir haben das Produkt in deinen Warenkorb gelegt',
    error: 'Das Produkt konnte dem Warenkorb nicht hinzugefügt werden.',
  },
  bossProductCarousel: {
    labelButtonPrev: 'Product carousel previous slide',
    labelButtonNext: 'Product carousel next slide',
  },
  bossProductSwatches: {
    labelShowMore: 'Show more product colors',
  },
  bossProductCard: {
    wishlist: {
      labelAdd: 'Add this product to wishlist',
      labelRemove: 'Remove this product from wishlist',
    },
    image: {
      labelPrimary: 'Primary product image',
      labelSecondary: 'Secondary product image',
    },
  },
};
