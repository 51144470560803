import { Injectable } from '@angular/core';
import { Converter, Occ, Product } from '@spartacus/core';

@Injectable({ providedIn: 'root' })
export class BossProductNormalizer implements Converter<Occ.Product, Product> {
  convert(source: Occ.Product, target?: Product): Product {
    const { name, code } = source;

    if (target === undefined) {
      target = { ...(source as unknown as Partial<Product>) };
    }

    if (name && code) {
      target.productNameAndCode = this.createNameAndCodeString(name, code);
    }

    return target;
  }

  private createNameAndCodeString(name: string, code: string): string {
    return `${name
      .toLowerCase()
      .replace(/ä/gi, 'ae')
      .replace(/ö/gi, 'oe')
      .replace(/ü/gi, 'ue')
      .replace(/ß/gi, 'ss')
      .replace(/[^a-zA-Z0-9]+/gi, '-')
      .replace(/-+/gi, '-')
      .replace(/%2F/gi, '/')
      .replace(/[^%A-Za-z0-9\\-]+/gi, '-')
      .replace(/\s+/gi, '-')}-${code}`;
  }
}
