import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { bossIconConfig } from '../../utils/boss-icon-config';

@Component({
  selector: 'boss-dropdown',
  templateUrl: './boss-dropdown.component.html',
  styleUrls: ['./boss-dropdown.component.scss'],
})
export class BossDropdownComponent {
  @Input()
  placeholder: string;

  @Input()
  isDisabled = false;

  @Input()
  label: string;

  @Input()
  labelClasses = '';

  @Input()
  list = {};

  @Input()
  listValue = '';

  @Input()
  listLabel = '';

  @Output() selected = new EventEmitter<string>();

  @Input()
  selectedValue;

  @Input() showEmpty: boolean = false;
  @Input() emptyValue = '';
  @Input() orderByKeys = false;

  @Input() useRadio: boolean = false;

  showContent = false;

  bossIconConfig = bossIconConfig;

  @ViewChild('dropdownContent') dropdownContent: ElementRef<HTMLDivElement>;

  constructor(private el: ElementRef) {}

  keyOrder = (): number => {
    return 0;
  };

  @HostListener('document:click', ['$event'])
  clickOutside(event): void {
    if (!this.el.nativeElement.contains(event.target) && this.dropdownContent) {
      this.showContent = false;
    }
  }

  toggleList(): void {
    if (this.isDisabled) {
      return;
    }
    this.showContent = !this.showContent;
  }

  onClick(value: string, ev?: MouseEvent | TouchEvent): void {
    ev?.preventDefault();
    this.selected.emit(value);
    this.toggleList();
  }

  get displayList(): {} {
    if (this.listLabel && this.listValue) {
      return Object.values(this.list).reduce((acc, val) => {
        acc[val[this.listValue]] = val[this.listLabel];
        return acc;
      }, {});
    }
    return this.list;
  }

  get selection(): string {
    return (this.selectedValue !== '' && this.displayList[this.selectedValue]) || '';
  }

  get nothingSpecial(): boolean {
    return !this.useRadio;
  }
}
