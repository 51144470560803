import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { storeFinderTranslationChunksConfig, storeFinderTranslations } from '@spartacus/storefinder/assets';
import { bossStoreFinderConfig } from '../../configuration/boss-store-finder-config';
import { BossTeaserStoreFinderModule } from './components/teaser-store-finder/teaser-store-finder.module';

@NgModule({
  imports: [BossTeaserStoreFinderModule],
  providers: [
    provideConfig({
      featureModules: {
        'boss-storefinder': {
          module: () =>
            import(
              /* webpackChunkName: "boss-storefinder" */
              '../storefinder/boss-storefinder.module'
            ).then((m) => m.BossStoreFinderModule),
          cmsComponents: [
            'StoreFinderListFlexComponent',
            'MarketProspectComponent',
            'NearPointOfServiceTeaserComponent',
            'StoreDetailsFlexComponent',
            'LocalCounselorTeaserComponent',
          ],
        },
      },
    } as CmsConfig),
    provideConfig(bossStoreFinderConfig),
    provideConfig({
      i18n: {
        resources: storeFinderTranslations,
        chunks: storeFinderTranslationChunksConfig,
      },
    } as I18nConfig),
  ],
})
export class BossStoreFinderFeatureModule {}
