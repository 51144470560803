import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { BossFooterTrustedComponent } from './footer-trusted.component';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { BossDecodeUriPipeModule } from '../../../shared/pipes/encode-uri/boss-decode-uri-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        PaymentSimpleBannerComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-footer" */
              './footer-trusted.component'
            ).then((m) => m.BossFooterTrustedComponent),
        },
      },
    }),
    MediaModule,
    GenericLinkModule,
    BossDecodeUriPipeModule,
  ],
  declarations: [BossFooterTrustedComponent],
  exports: [BossFooterTrustedComponent],
})
export class BossFooterTrustedComponentModule {}
