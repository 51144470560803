import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICON_TYPE } from '@spartacus/storefront';

@Component({
  selector: 'boss-financing-calculator',
  templateUrl: './boss-financing-calculator.component.html',
  styleUrls: ['./boss-financing-calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossFinancingCalculatorComponent implements OnInit {
  iconTypes = ICON_TYPE;

  @ViewChild('term') viewSelect: ElementRef;
  @ViewChild('volume') volume: ElementRef;
  @ViewChild('netAmount') netAmount: ElementRef;
  @ViewChild('interestAmount') interestAmount: ElementRef;
  @ViewChild('rateLevel') rateLevel: ElementRef;
  @ViewChild('grossAmount') grossAmount: ElementRef;
  @ViewChild('debitInterest') debitInterest: ElementRef;
  @ViewChild('effInterest') effInterest: ElementRef;
  @ViewChild('error') error: ElementRef;
  @ViewChild('rateAmount') rateAmount: ElementRef;

  calculator: FormGroup;

  faktor = [
    0.166666, 0.111111, 0.1, 0.083333, 0.059822, 0.054253, 0.045912, 0.037572, 0.032032, 0.028073, 0.025126, 0.022833,
    0.020992, 0.019521, 0.018271,
  ];

  terms = [
    { code: 0, name: '6 Monate' },
    { code: 1, name: '9 Monate' },
    { code: 2, name: '10 Monate' },
    { code: 3, name: '12 Monate' },
    { code: 4, name: '18 Monate' },
    { code: 5, name: '20 Monate' },
    { code: 6, name: '24 Monate' },
    { code: 7, name: '30 Monate' },
    { code: 8, name: '36 Monate' },
    { code: 9, name: '42 Monate' },
    { code: 10, name: '48 Monate' },
    { code: 11, name: '54 Monate' },
    { code: 12, name: '60 Monate' },
    { code: 13, name: '66 Monate' },
    { code: 14, name: '72 Monate' },
  ];

  effzinsArr = [0.0, 9.9, 9.9, 9.9, 9.9, 9.9, 9.9, 9.9, 9.9, 9.9, 9.9, 9.9, 9.9, 9.9, 9.9];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.calculator = this.fb.group({
      volume: [''],
      term: [0],
    });
  }

  // Funktionen
  round2Decimal(x, places): number {
    const roundingFactor = Math.pow(10, places);
    const result = Math.round(x * roundingFactor) / roundingFactor;
    return result;
  }

  calcSollzins(effzins): number {
    let result = (Math.pow(1 + effzins / 100, 1 / 12) - 1) * 100;
    result = result * 12;
    return this.round2Decimal(result, 3);
  }

  calc(): void {
    // Hole Laufzeit Index + Laufzeit aus Dropdown Menu
    const termIndex = this.getTermValue();
    const term = parseInt(this.terms[termIndex].name.replace('Monate', ''));
    const netLoanAmount = parseFloat(this.volume.nativeElement.value.replace(',', '.').replace(',', '.'));
    if (isNaN(netLoanAmount)) {
      // Ausgabe Fehlermeldung
      this.error.nativeElement.style.display = 'block';

      //ggf. vorherige Berechnungsergebnisse zuruecksetzen
      this.netAmount.nativeElement.value = '';
      this.interestAmount.nativeElement.value = '';
      this.rateLevel.nativeElement.value = '';
      this.rateAmount.nativeElement.innerHTML = '0';
      this.grossAmount.nativeElement.value = '';
      this.debitInterest.nativeElement.value = '';
      this.effInterest.nativeElement.value = '';
    } else {
      //eine vorher ggf. eingeblendete Fehlermeldung wieder ausblenden
      this.error.nativeElement.style.display = 'none';

      // Berechnungen
      const rate = this.round2Decimal(netLoanAmount * this.faktor[termIndex], 2);

      let total: number;
      if (this.effzinsArr[termIndex] == 0.0) total = netLoanAmount;
      else total = this.round2Decimal(term * rate, 2);

      let totalZins: number;
      if (this.effzinsArr[termIndex] == 0.0) totalZins = 0;
      else totalZins = this.round2Decimal(total - netLoanAmount, 2);

      //Ausgabe Berechnungsergebnisse
      this.netAmount.nativeElement.value = netLoanAmount.toFixed(2).toString().replace('.', ',');
      this.interestAmount.nativeElement.value = totalZins.toFixed(2).toString().replace('.', ',');
      this.rateLevel.nativeElement.value = rate.toFixed(2).toString().replace('.', ',');
      this.rateAmount.nativeElement.innerHTML = term;
      this.grossAmount.nativeElement.value = total.toFixed(2).toString().replace('.', ',');
      this.debitInterest.nativeElement.value = this.calcSollzins(this.effzinsArr[termIndex])
        .toFixed(2)
        .toString()
        .replace('.', ',');
      this.effInterest.nativeElement.value = this.effzinsArr[termIndex].toFixed(2).toString().replace('.', ',');
    }
  }

  getTermValue(): string {
    return this.calculator?.get('term').value;
  }

  setTermValue(value: unknown): void {
    this.calculator?.patchValue({ ['term']: value });
    this.calculator?.get('term').markAsDirty();
    this.calc();
  }
}
