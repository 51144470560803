import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { BossResetPasswordComponentComponent } from './reset-password-component.component';
import { FormErrorsModule, LoginFormModule } from '@spartacus/storefront';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BossShowPasswordModule } from '../../shared/components/boss-show-password/boss-show-password.module';

@NgModule({
  declarations: [BossResetPasswordComponentComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ResetPasswordComponent: {
          component: () =>
            import('./reset-password-component.component').then((m) => m.BossResetPasswordComponentComponent),
        },
      },
    } as CmsConfig),
    LoginFormModule,
    I18nModule,
    FormErrorsModule,
    FormsModule,
    ReactiveFormsModule,
    BossShowPasswordModule,
  ],
})
export class BossResetPasswordComponentComponentModule {}
