import { RoutingConfig } from '@spartacus/core';
import { bossCategoryUrlMatcher } from '../shared/matchers/category.matcher';
import { bossProductUrlMatcher } from '../shared/matchers/product.matcher';

const category = {
    paramsMapping: { categoryCode: 'code' },
    matchers: [bossCategoryUrlMatcher],
  },
  orderConfirmation = { paths: ['orderConfirmation'] },
  product = {
    matchers: [bossProductUrlMatcher],
    paths: ['p/:productNameAndCode', 'product/:productCode/:name'],
  },
  registrationInfo = { paths: ['registration-info'] },
  newsletter = { paths: ['newsletter'] };

export const bossRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      category,
      orderConfirmation,
      product,
      registrationInfo,
      newsletter,
    },
  },
};
