import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category, ConverterService, InterceptorUtil, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { CATEGORY_TREE_NORMALIZER } from './converters';
import { BossCategory, BossCategoryTree } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class OccCategoryAdapter {
  constructor(
    private http: HttpClient,
    private occEndpoints: OccEndpointsService,
    private converter: ConverterService,
  ) {}

  load(categoryCode: string, scope?: string): Observable<BossCategoryTree> {
    const headers = this.getHeaders();
    return this.http
      .get(this.getEndpoint(categoryCode, scope), { headers })
      .pipe(this.converter.pipeable(CATEGORY_TREE_NORMALIZER));
  }

  loadCategory(categoryCode: string): Observable<Category> {
    const headers = this.getHeaders();
    return this.http.get(this.getCategoryEndpoint(categoryCode), { headers });
  }

  loadBossCategory(categoryCode: string): Observable<BossCategory> {
    const headers = this.getHeaders();
    return this.http.get<BossCategory>(this.getCategoryEndpoint(categoryCode), {
      headers,
    });
  }

  protected getEndpoint(code: string, scope?: string): string {
    return this.occEndpoints.getUrl(
      'product',
      {
        categoryCode: code,
      },
      undefined,
      scope,
    );
  }

  protected getCategoryEndpoint(code: string): string {
    return this.occEndpoints.buildUrl(`categories/${code}?fields=FULL`);
  }

  protected getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);

    return headers;
  }
}
