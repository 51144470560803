import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossDialogComponent } from './boss-dialog.component';
import { BossDialogDirective } from './boss-dialog.directive';
import { BossModalCloseButtonModule } from '../boss-modal-close-button/boss-modal-close-button.module';

@NgModule({
  declarations: [BossDialogComponent, BossDialogDirective],
  imports: [CommonModule, BossModalCloseButtonModule],
  exports: [BossDialogComponent, BossDialogDirective],
})
export class BossDialogModule {}
