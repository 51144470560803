export const userProfile = {
  updateEmailForm: {
    newEmailAddress: {
      label: 'Neue E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse',
    },
    confirmNewEmailAddress: {
      label: 'Neue E-Mail-Adresse bestätigen',
      placeholder: 'E-Mail-Adresse',
    },
    enterValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
    bothEmailMustMatch: 'Beide E-Mail-Adressen müssen übereinstimmen',
    password: {
      label: 'Passwort',
      placeholder: 'Passwort',
    },
    pleaseInputPassword: 'Bitte Passwort eingeben',
  },
  register: {
    confirmPassword: {
      action: 'Passwort bestätigen',
      label: 'Passwort bestätigen',
      placeholder: 'Passwort bestätigen',
    },
    managementInMyAccount: 'Verwaltung in Mein Konto.',
    termsAndConditions: 'AGB',
    signIn: 'Ich habe bereits ein Benutzerkonto. Einloggen',
    register: 'Registrieren',
    confirmNewPassword: 'Neues Passwort bestätigen',
    resetPassword: 'Passwort zurücksetzen',
    createAccount: 'Benutzerkonto erstellen',
    title: 'Anrede',
    firstName: {
      label: 'Vorname',
      placeholder: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
      placeholder: 'Nachname',
    },
    emailAddress: {
      label: 'E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Passwort',
    },
    newPassword: 'Neues Passwort',
    emailMarketing:
      'Verwende meien persönlichen Daten, um Newsletter von Marketing-Kampagnen zu erhalten. Um Ihre Einstellungen zu ändern, gehen Sie zu Consent Management in Mein Konto.',
    confirmThatRead: 'Hiermit bestätige ich, dass ich das folgende gelesen und zugestimmt habe',
    selectTitle: 'Anrede auswählen',
    passwordMinRequirements:
      'Passwort muss aus mindestens 6 Zeichen bestehen, mit einem Großbuchstaben, einer Zahl und einem Sonderzeichen',
    bothPasswordMustMatch: 'Beide Passwörter müssen übereinstimmen',
    titleRequired: 'Anrede wird benötigt.',
    postRegisterMessage: 'Bitte loggen Sie sich mit den Anmeldedaten an.',
  },
  forgottenPassword: {
    resetPassword: 'Passwort zurücksetzen',
    enterEmailAddressAssociatedWithYourAccount: 'Geben Sie die E-Mail-Adresse an, die mit Ihrem Account verknüpft ist',
    emailAddress: {
      label: 'E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse eingeben',
    },
    enterValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    passwordResetEmailSent:
      'Eine E-Mail wurde Ihnen zugeschickt, mit Informationen, wie Sie Ihr Passwort zurücksetzen können.',
    passwordResetSuccess: 'Erfolg! Sie können sich jetzt mit Ihrem neuen Passwort anmelden.',
  },
};
