import { CxEvent } from '@spartacus/core';

export class BossProductDetailsPageEvent extends CxEvent {
  static readonly type = 'BossProductDetailsPageEvent';

  ecommerce: {
    value: number;
    currency: string;
    items: {
      affiliation: string;
      currency: string;
      discount: number;
      item_brand: string;
      item_category: string;
      item_category2?: string;
      item_category3?: string;
      item_category4?: string;
      item_category5?: string;
      item_id: string;
      item_name: string;
      item_variant: string;
      price: number;
      quantity: number;

      coupon?: string;
      index?: number;
      item_list_id?: string;
      item_list_name?: string;
      location_id?: string;
    }[];
  };
}
