import { environment } from '../../../environments/environment';

export const bossOccConfig = {
  backend: {
    media: {
      baseUrl: environment.mediaBaseUrl,
    },
    occ: {
      // baseUrl: 'https://spartacus-demo.eastus.cloudapp.azure.com:8443',
      baseUrl: environment.occBaseUrl,
      // baseUrl: 'https://localhost:9002/',
      prefix: '/occ/v2',
      endpoints: {
        product: {
          default: 'products/${productCode}?fields=FULL',
          details: 'products/${productCode}?fields=FULL',
          categoryTree: 'categories/${categoryCode}/tree?fields=FULL',
          zipCodeAndQty: 'products/${productCode}?fields=DEFAULT,reviews(FULL)&quantity=${quantity}&zipCode=${zipCode}',
          list: 'products/${productCode}?fields=priceDisplayType,code,name,title,summary,price(FULL),images(DEFAULT,galleryIndex),baseProduct,crossedPrice(FULL),onlineStockColor,stock(FULL),allVariants(FULL),averageRating,reviews(FULL),availableForPickup,showRoomProduct,energyEfficiencies,onlyOnlineProduct,newProduct,saleProduct,augmentedRealityID',
          zipCodeChange: 'users/${userId}/carts/${cartId}/setHybridDeliveryZipCode',
        },
        saferPayInitialize: 'users/${userId}/carts/${cartId}/initialize/saferpay?awinToken=${awinToken}',
        payEverInitialize: 'users/${userId}/carts/${cartId}/authorize/payeverSantander?awinToken=${awinToken}',
        prepaidInitialize: 'users/${userId}/carts/${cartId}/authorize/prepaid?awinToken=${awinToken}',
        klarnaInitialize: 'users/${userId}/carts/${cartId}/initialize/klarna?awinToken=${awinToken}',
        giftCardApply: 'users/${userId}/carts/${cartId}/giftcards',
        hybridDeliveryZipCode: 'users/${userId}/carts/${cartId}/setHybridDeliveryZipCode',
        giftCardRemove: 'users/${userId}/carts/${cartId}/giftcards/${giftcard}',
        giftCardInitialize: 'users/${userId}/carts/${cartId}/authorize/giftcard?awinToken=${awinToken}',
        removeAllGiftCards: 'users/${userId}/carts/${cartId}/giftcards',
        productSearch:
          'products/search?fields=products(priceDisplayType,code,name,title,saleProduct,availableForPickup,summary,configurable,configuratorType,price(FULL),images(FULL),hoverImage(FULL),stock(FULL),averageRating,reviews(FULL),numberOfReviews,variantOptions,crossedPrice(FULL),onlineStockColor,allVariants(FULL),showRoomProduct,energyEfficiency,energyEfficiencies,onlyOnlineProduct,newProduct,augmentedRealityID),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
        productSuggestions: 'products/suggestions/${componentUid}',
        carts:
          'users/${userId}/carts?fields=carts(DEFAULT,additionalDeliveryCost,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(allVariants(FULL),images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description)',
        cart: 'users/${userId}/carts/${cartId}?fields=DEFAULT,additionalDeliveryCost,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(allVariants(FULL),images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description',
        createCart:
          'users/${userId}/carts?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(allVariants(FULL),images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user',
        createUpdateBillingAddress: 'users/${occUserId}/carts/${activeCartId}/addresses/billing',
        /**
         * TODO: in version 4.0 `baseSitesForConfig` will be removed, so on migration replace it here with `baseSites`
         */
        baseSitesForConfig: 'basesites?fields=FULL',
        baseStores: '/basestores/boss?fields=store(FULL)',
      },
    },
  },
};
