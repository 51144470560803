import { Injectable } from '@angular/core';
import {
  BasePageMetaResolver,
  BreadcrumbMeta,
  CanonicalPageResolver,
  CategoryPageMetaResolver,
  CmsService,
  PageBreadcrumbResolver,
  PageContext,
  PageDescriptionResolver,
  PageHeadingResolver,
  PageRobotsMeta,
  PageRobotsResolver,
  PageTitleResolver,
  PageType,
  ProductSearchService,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BossCategory } from '../../models';
import { OccCategoryAdapter } from '../../occ/category/occ-category-adapter';

@Injectable({
  providedIn: 'root',
})
export class BossCategoryPageMetaResolver
  extends CategoryPageMetaResolver
  implements
    PageTitleResolver,
    PageBreadcrumbResolver,
    PageRobotsResolver,
    PageHeadingResolver,
    PageDescriptionResolver,
    CanonicalPageResolver
{
  constructor(
    productSearchService: ProductSearchService,
    cms: CmsService,
    translation: TranslationService,
    basePageMetaResolver: BasePageMetaResolver,
    private routingService: RoutingService,
    private occCategoryAdapter: OccCategoryAdapter,
  ) {
    super(productSearchService, cms, translation, basePageMetaResolver);
    this.pageType = PageType.CATEGORY_PAGE;
  }

  resolveDescription(): Observable<string> {
    return this.onCategory().pipe(map((data: BossCategory) => data.metaDescription));
  }

  resolveHeading(): Observable<string> {
    return this.onCategory().pipe(map((data: BossCategory) => data.title));
  }

  resolveTitle(): Observable<string> {
    return this.onCategory().pipe(map((category: BossCategory) => `${category.pageTitle} | Möbel Boss`));
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return this.onCategory().pipe(
      map((category) => {
        const breadcrumbs = [];
        for (const { name, code, url } of category['breadcrumbs'] || []) {
          breadcrumbs.push({
            label: name || code,
            link: url,
          } as BreadcrumbMeta);
        }
        return breadcrumbs;
      }),
    );
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return this.basePageMetaResolver.resolveRobots();
  }

  resolveCanonicalUrl(): Observable<string> {
    return this.onCategory().pipe(
      withLatestFrom(this.basePageMetaResolver.resolveCanonicalUrl()),
      map(([category, basePageCanonical]) => {
        return category?.canonicalUrlCategory?.url
          ? `https://moebel-boss.de${category.canonicalUrlCategory.url}`
          : basePageCanonical;
      }),
    );
  }

  private onCategory(): Observable<BossCategory> {
    return this.routingService.getPageContext().pipe(
      map((context: PageContext) => context.id),
      distinctUntilChanged(),
      switchMap((id: string) => this.occCategoryAdapter.loadBossCategory(id)),
    );
  }
}
