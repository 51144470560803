import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { BossStorageKeys } from '../../utils/boss-constants';

@Injectable({
  providedIn: 'root',
})
export class BossZipCodeService {
  private sessionStorage: Storage;

  constructor(private winRef: WindowRef) {
    this.sessionStorage = winRef.sessionStorage;
  }

  /**
   * Save zip code to session storage.
   *
   * @param {string} zipCode
   * @memberof BossZipCodeService
   */
  set(zipCode: string): void {
    if (this.winRef.isBrowser()) {
      this.sessionStorage.setItem(BossStorageKeys.ZIP_CODE, zipCode);
    }
  }

  /**
   * Get zip code from session storage.
   *
   * @return {*}  {(string | null)}
   * @memberof BossZipCodeService
   */
  get(): string | null {
    return this.winRef.isBrowser() ? this.sessionStorage.getItem(BossStorageKeys.ZIP_CODE) : null;
  }

  /**
   * Delete zip code from session storage.
   *
   * @memberof BossZipCodeService
   */
  delete(): void {
    if (this.winRef.isBrowser()) {
      this.sessionStorage.removeItem(BossStorageKeys.ZIP_CODE);
    }
  }
}
