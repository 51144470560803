import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { ICON_TYPE, StarRatingComponent } from '@spartacus/storefront';
import { bossIconConfig } from '../../../shared/utils/boss-icon-config';

@Component({
  selector: 'boss-product-star-rating',
  templateUrl: './product-star-rating.component.html',
  styleUrls: ['./product-star-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossProductStarRatingComponent extends StarRatingComponent {
  protected initialRate = 0;

  icon = ICON_TYPE.STAR;

  bossIconConfig = bossIconConfig;

  @Input()
  @HostBinding('attr.disabled')
  disabled = true;

  @Input()
  @HostBinding('style.--star-fill')
  rating: number = this.initialRate;

  @Input()
  reviewsCount: number;

  @Input()
  anchorLink: string;

  @Input()
  showCount = true;
}
