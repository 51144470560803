import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { bossIconConfig } from '../../utils/boss-icon-config';

@Component({
  selector: 'boss-star-rating',
  templateUrl: './boss-star-rating.component.html',
  styleUrls: ['./boss-star-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossStarRatingComponent {
  @Input() rating = 0;

  @Input() count = 0;

  @Input() link = '';

  @Input() max = 5;

  @Input() icon = bossIconConfig.STAR_LINE;

  @Input() halfIcon = bossIconConfig.STAR_HALF_LINE;

  @Input() fillIcon = bossIconConfig.STAR;

  get stars(): string[] {
    const stars: string[] = [];

    for (let i = 0; i < this.max; i++) {
      stars.push(this.getIcon(i + 1));
    }

    return stars;
  }

  private getIcon(index: number): string {
    if (index <= this.rating) {
      return this.fillIcon;
    }

    if (index > this.rating && index - 1 < this.rating) {
      return this.halfIcon;
    }

    return this.icon;
  }
}
