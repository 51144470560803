import { NgModule } from '@angular/core';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { BossBuyBoxComponent } from './buy-box.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BuyBoxCurrentZipService } from './buy-box-current-zip.service';
import { BossProductPostCodeModalComponentModule } from '../post-code-dialog/product-post-code-modal.module';
import { StoreAvailabilityModule } from '../store-availability/store-availability.module';
import { BossItemCounterModule } from '../../../../shared/components/item-counter/boss-item-counter.module';
import { BossProductAvailabilityComponentModule } from '../../../product/product-availability/product-availability.module';
import { BossAddToCartModule } from '../../../boss-add-to-cart/boss-add-to-cart.module';
import { BossSwatchesModule } from '../../../../shared/components/boss-swatches/boss-swatches.module';
import { BossEnergyLabelsModule } from '../../../../shared/components/boss-energy-labels/boss-energy-labels.module';
import { BossPriceModule } from '../../../../shared/components/boss-price/boss-price.module';
import { BossPriceInfoModule } from '../../../../shared/components/boss-price-info/boss-price-info.module';
import { BossStatusLabelComponentModule } from '../../../../shared/components/status-label/boss-status-label.module';
import { BossStarRatingComponentModule } from '../../../../shared/components/star-rating/boss-star-rating.module';
import { BossStoreDropdownComponentModule } from '../../../product/store-dropdown/boss-store-dropdown.module';
import { BossFinancingCalculatorComponentModule } from '../../../../shared/components/financing-calculator/boss-financing-calculator.module';
import { BossProductStatusComponentModule } from '../../../product/product-status/boss-product-status.module';
import { BossVariantSelectorComponentModule } from '../../../product/variant-selector/boss-variant-selector.module';
import { BossDialogWrapperModule } from '../../../../shared/components/dialog/dialog-wrapper/boss-dialog-wrapper.module';
import { BossIconDirectiveModule } from '../../../../shared/directives/icon/boss-icon.directive.module';
import { BossTooltipComponentModule } from '../../../../shared/components/tooltip/boss-tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    BossItemCounterModule,
    BossProductAvailabilityComponentModule,
    BossAddToCartModule,
    BossSwatchesModule,
    BossEnergyLabelsModule,
    BossPriceModule,
    BossPriceInfoModule,
    BossStatusLabelComponentModule,
    BossTooltipComponentModule,
    BossStarRatingComponentModule,
    BossStoreDropdownComponentModule,
    BossFinancingCalculatorComponentModule,
    BossProductPostCodeModalComponentModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BossBuyBoxComponent: {
          component: BossBuyBoxComponent,
        },
      },
    }),
    BossTooltipComponentModule,
    BossProductStatusComponentModule,
    BossVariantSelectorComponentModule,
    BossStatusLabelComponentModule,
    BossStoreDropdownComponentModule,
    BossFinancingCalculatorComponentModule,
    StoreAvailabilityModule,
    BossDialogWrapperModule,
  ],
  declarations: [BossBuyBoxComponent],
  providers: [BuyBoxCurrentZipService],
})
export class BossBuyBoxModule {}
