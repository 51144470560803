import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'boss-item-counter',
  templateUrl: './boss-item-counter.component.html',
  styleUrls: ['./boss-item-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossItemCounterComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;

  @Input() min = 1;

  @Input() max: number;

  @Input() step = 1;

  @Input() allowZero = false;

  @Output()
  decreased: EventEmitter<void> = new EventEmitter();

  @Output()
  increased: EventEmitter<void> = new EventEmitter();

  @HostBinding('class.readonly') @Input() readonly = false;

  private onDestroy$ = new Subject<void>();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.control?.valueChanges.pipe(startWith(this.control?.value), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.control?.setValue(this.getValidCount(value), { emitEvent: false });

      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onDecrement(): void {
    this.updateControl(-this.step);

    this.decreased.emit();
  }

  onIncrement(): void {
    this.updateControl(this.step);

    this.increased.emit();
  }

  private getValidCount(value: number): number {
    if (value < this.min && !(value === 0 && this.allowZero)) {
      value = this.min;
    }
    if (this.max && value > this.max) {
      value = this.max;
    }
    return value;
  }

  private updateControl(valueChange: number): void {
    if (this.control) {
      this.control.setValue(this.control.value + valueChange);
      this.control.markAsDirty();

      this.cdRef.detectChanges();
    }
  }
}
