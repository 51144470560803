import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { provideCmsStructure } from '@spartacus/storefront';
import { BossMyMarketComponent } from './my-market.component';
import { RouterModule } from '@angular/router';
import { HeaderDirectivesModule } from '../../shared/directives/header-directives/header-directives.module';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BossMyMarket: {
          component: BossMyMarketComponent,
        },
      },
    } as CmsConfig),
    I18nModule,
    RouterModule,
    HeaderDirectivesModule,
    BossIconDirectiveModule,
  ],
  declarations: [BossMyMarketComponent],
  providers: [
    provideCmsStructure({
      componentId: 'BossMyMarket',
      pageSlotPosition: 'BossMyMarketSlot',
    }),
  ],
})
export class BossMyMarketModule {}
