import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossFinancingCalculatorComponent } from './boss-financing-calculator.component';
import { I18nModule } from '@spartacus/core';
import { BossModalCloseButtonModule } from '../boss-modal-close-button/boss-modal-close-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BossDropdownComponentModule } from '../dropdown/boss-dropdown.module';

@NgModule({
  declarations: [BossFinancingCalculatorComponent],
  imports: [
    CommonModule,
    I18nModule,
    BossModalCloseButtonModule,
    BossDropdownComponentModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class BossFinancingCalculatorComponentModule {}
