import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveCartService, Address, Cart, OccEndpointsService, UserIdService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

const DELIVERY_PARAMS = 'deliveryAddress(FULL)';

@Injectable()
export class BossOccDeliveryAddressService {
  constructor(
    private http: HttpClient,
    private activeCartService: ActiveCartService,
    private userIdService: UserIdService,
    private occEndpoints: OccEndpointsService,
  ) {}

  getDeliveryAddress(): Observable<Address> {
    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()]).pipe(
      switchMap(([occUserId, activeCartId]: [string, string]) => {
        const url = this.occEndpoints.buildUrl(`users/${occUserId}/carts/${activeCartId}/`);
        const params = new HttpParams({
          fromString: `fields=${DELIVERY_PARAMS}`,
        });

        return this.http.get(url, { params });
      }),
      map((cartDetails: Cart) => {
        const address: Address = cartDetails['deliveryAddress'] ?? {};
        return address;
      }),
    );
  }

  updateDeliveryAddress(address: Address): Observable<Address> {
    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()]).pipe(
      switchMap(([occUserId, activeCartId]) => {
        const url: string = this.occEndpoints.buildUrl(`users/${occUserId}/carts/${activeCartId}/addresses/delivery`);
        return this.http.put(url, address || {}, { params: { addressId: address?.id } });
      }),
    );
  }

  createAndSetAddress(address: Address): Observable<Address> {
    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()]).pipe(
      switchMap(([occUserId, activeCartId]) => {
        const url = this.occEndpoints.buildUrl(`users/${occUserId}/carts/${activeCartId}/addresses/delivery`);
        return this.http.post(url, address);
      }),
    );
  }

  remove(): Observable<Address> {
    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()]).pipe(
      switchMap(([occUserId, activeCartId]: [string, string]) => {
        const orderEndpoint = 'users/' + occUserId + '/carts/';
        const url = `${this.occEndpoints.getEndpoint(orderEndpoint)}${activeCartId}/addresses/delivery`;
        return this.http.delete<Address>(url);
      }),
    );
  }
}
