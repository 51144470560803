<ng-template cxOutletRef="header">
  <cx-page-slot position="PreHeader"></cx-page-slot>
  <cx-page-slot position="SiteLogo"></cx-page-slot>
  <cx-page-slot position="SearchBox"></cx-page-slot>
  <div class="icon-wrapper-flex">
    <cx-page-slot position="BossMyMarketSlot"></cx-page-slot>
    <cx-page-slot position="BossUserAccountSlot"></cx-page-slot>
    <cx-page-slot position="WishListSlot"></cx-page-slot>
    <cx-page-slot position="MiniCart"></cx-page-slot>
  </div>
</ng-template>
