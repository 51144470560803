import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, UrlModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { BossSiteLogoComponent } from './site-logo.component';
import { BossMediaDirectiveModule } from '../../shared/directives/media/boss-media.directive.module';

@NgModule({
  imports: [
    CommonModule,
    UrlModule,
    RouterModule,
    MediaModule,
    BossMediaDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        HeaderLogoComponent: {
          component: BossSiteLogoComponent,
        },
      },
    }),
  ],
  declarations: [BossSiteLogoComponent],
  exports: [BossSiteLogoComponent],
})
export class BossSiteLogoComponentModule {}
