import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BossDialogService } from '../boss-dialog.service';
import { BossDialogComponent } from '../boss-dialog.component';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { BossDialogContent } from '../boss-dialog-content';
import { WindowRef } from '@spartacus/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'boss-dialog-wrapper',
  templateUrl: './boss-dialog-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossDialogWrapperComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  @ViewChild('bossDialogWrapper', { static: true, read: ViewContainerRef }) vcr: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private dialogService: BossDialogService,
    private winRef: WindowRef,
  ) {}

  ngOnInit(): void {
    this.initializeContentWhenOpen();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initializeContentWhenOpen(): void {
    if (this.winRef.isBrowser()) {
      this.dialogService.isOpen$.pipe(takeUntil(this.onDestroy$)).subscribe((isOpen: boolean) => {
        if (isOpen) {
          this.loadComponent();
          document.body.classList.add('modal-open');
        } else {
          this.vcr.clear();
        }
      });
    }
  }

  loadComponent(): void {
    const viewContainerRef = this.vcr;
    viewContainerRef?.clear();

    this.dialogService.getContent$
      .pipe(
        filter((content: BossDialogContent) => !!content),
        distinctUntilChanged(),
        take(1),
        takeUntil(this.onDestroy$),
      )
      .subscribe((content: BossDialogContent) => {
        if (content) {
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BossDialogComponent),
            componentRef = viewContainerRef.createComponent(componentFactory);

          componentRef.instance.content = content;
          componentRef?.changeDetectorRef.detectChanges();
        }
      });
  }
}
