import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, provideDefaultConfig } from '@spartacus/core';
import { BossProductLastViewedComponent } from './product-last-viewed.component';
import { BossProductCardComponentModule } from '../product/product-list/product-card/product-card.module';
import { BossCarouselBaseModule } from '../../shared/components/boss-carousel-base/boss-carousel-base.module';
import { BossProductCarouselTemplateModule } from '../../shared/components/boss-product-carousel-template/boss-product-carousel-template.module';

@NgModule({
  declarations: [BossProductLastViewedComponent],
  imports: [CommonModule, BossProductCardComponentModule, BossCarouselBaseModule, BossProductCarouselTemplateModule],
  providers: [
    provideDefaultConfig({
      cmsComponents: {
        LastViewedProductsComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-product" */
              './product-last-viewed.component'
            ).then((m) => m.BossProductLastViewedComponent),
        },
      },
    } as CmsConfig),
  ],
})
export class BossProductLastViewedComponentModule {}
