import { Injectable } from '@angular/core';
import {
  EventService,
  ProductService,
  ProductSearchService,
  FeatureConfigService,
  createFrom,
  Product,
  Cart,
  ActiveCartService,
} from '@spartacus/core';
import { ProductPageEventBuilder, NavigationEvent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { BossProductVariants } from '../models';
import { BossProductDetailsPageEvent } from './boss-product-details.event';

@Injectable({
  providedIn: 'root',
})
export class BossProductPageEventBuilder extends ProductPageEventBuilder {
  constructor(
    eventService: EventService,
    productService: ProductService,
    productSearchService: ProductSearchService,
    private activeCartService: ActiveCartService,
    featureConfigService?: FeatureConfigService,
  ) {
    super(eventService, productService, productSearchService, featureConfigService);
    this.eventService.register(BossProductDetailsPageEvent, this.bossBuildProductDetailsPageEvent());
  }

  private bossBuildProductDetailsPageEvent(): Observable<BossProductDetailsPageEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      filter((navigationEvent) => navigationEvent.semanticRoute === 'product'),
      switchMap((navigationEvent) =>
        this.productService.get(navigationEvent.context.id).pipe(
          filter((product: Product) => Boolean(product)),
          take(1),
          withLatestFrom(this.activeCartService.getActive()),
          map(([product, cart]: [Product, Cart]) => {
            return createFrom(BossProductDetailsPageEvent, {
              ecommerce: {
                value: product?.price?.value,
                currency: 'EUR',
                items: [
                  {
                    affiliation: 'Google Merchandise Store',
                    currency: 'EUR',
                    discount: this.getDiscount(product),
                    item_brand: product?.manufacturer,
                    item_category: product?.categories[0]?.name,
                    item_category2: product?.categories[1]?.name,
                    item_category3: product?.categories[2]?.name,
                    item_category4: product?.categories[3]?.name,
                    item_category5: product?.categories[4]?.name,
                    item_id: product?.code,
                    item_name: product?.name,
                    item_variant: this.getVariantsAsString(product?.allVariants),
                    price: product?.price?.value,
                    quantity: 1,
                    coupon: cart?.appliedVouchers?.[0]?.code ?? '',

                    index: 0,
                    item_list_id: '',
                    item_list_name: '',
                    location_id: '',
                  },
                ],
              },
            });
          }),
        ),
      ),
    );
  }

  private getVariantsAsString(variants: BossProductVariants): string {
    return variants
      ? Object.keys(variants)
          ?.map((key) => variants[key])
          ?.find((variant) => variant?.length)
          ?.join(',')
      : '';
  }

  private getDiscount(product: Product): number {
    const price = product.price?.value || 0;
    const crossedPrice = product.crossedPrice?.value || 0;

    let discount = 0;

    if (crossedPrice) {
      discount = price - crossedPrice;
    }

    return discount > 0 ? discount : 0;
  }
}
