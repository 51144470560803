import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable()
export class FooterTopService {
  constructor(private http: HttpClient, private occEndpoints: OccEndpointsService) {}

  // TODO: Add interface for returning observable
  // eslint-disable-next-line
  public getBannerByUuid(bannerId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(`cms/components/${bannerId}`);
    return this.http.get(url);
  }

  public createBannersArray(bannerStr: string): Observable<string>[] {
    const observableArr = [];
    bannerStr.split(' ').forEach((elm: string) => {
      observableArr.push(this.getBannerByUuid(elm));
    });
    return observableArr;
  }
}
