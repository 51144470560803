import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OccCartAdapter, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable()
export class BossOccCartAdapter extends OccCartAdapter {
  addEmail(userId: string, cartId: string, email: string): Observable<{}> {
    const url = this.occEndpointsService.getUrl(
      'addEmail',
      {
        userId,
        cartId,
      },
      {
        email,
      },
    );

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);

    return this.http.put(url, {}, { headers });
  }
}
