import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Product } from '@spartacus/core';
import { BossProductPriceService } from '../../../features/product/product-price/services/product-price.service';

@Component({
  selector: 'boss-price-info[product]',
  templateUrl: './boss-price-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossPriceInfoComponent {
  @Input()
  product: Product;

  constructor(private productPriceService: BossProductPriceService) {}

  get isPriceCrossed(): boolean {
    return this.productPriceService.isPriceCrossed(this.product);
  }
}
