import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthGuard, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { FormErrorsModule, MediaModule } from '@spartacus/storefront';
import { BossAccountMarketContactComponent } from './account-market-contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BossContactFormService } from '../contact-form/contact-form.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    FormsModule,
    ReactiveFormsModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountMarketContactComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-my-account" */
              './account-market-contact.component'
            ).then((m) => m.BossAccountMarketContactComponent),
          guards: [AuthGuard],
        },
      },
    }),
  ],
  declarations: [BossAccountMarketContactComponent],
  exports: [BossAccountMarketContactComponent],
  providers: [BossContactFormService],
})
export class BossAccountMarketContactModule {}
