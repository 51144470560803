import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossAddToCartComponent } from './boss-add-to-cart.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import {
  CartSharedModule,
  IconModule,
  ItemCounterModule,
  KeyboardFocusModule,
  ModalModule,
  PromotionsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { BossCartEntryEffects } from '../product/product-add-to-cart/cart-entry.effect';

@NgModule({
  declarations: [BossAddToCartComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CartSharedModule,
    RouterModule,
    SpinnerModule,
    PromotionsModule,
    FeaturesConfigModule,
    UrlModule,
    IconModule,
    I18nModule,
    ItemCounterModule,
    KeyboardFocusModule,
    ModalModule,
    EffectsModule.forFeature([BossCartEntryEffects]),
  ],
  exports: [BossAddToCartComponent],
  providers: [
    provideConfig({
      cmsComponents: {
        ProductAddToCartComponent: {
          component: BossAddToCartComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class BossAddToCartModule {}
