import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Cart,
  CART_MODIFICATION_NORMALIZER,
  CART_NORMALIZER,
  CartModification,
  ConverterService,
  OccEndpointsService,
} from '@spartacus/core';
import { BossCartEntryAdapter } from './boss-cart-entry.adapter';
import { GiftCardApplyRequest } from '../../../shared/models';

@Injectable()
export class BossOccCartEntryAdapter implements BossCartEntryAdapter {
  constructor(
    private http: HttpClient,
    private occEndpointsService: OccEndpointsService,
    private converterService: ConverterService,
  ) {}

  public addWithStore(
    userId: string,
    cartId: string,
    productCode: string,
    pickupStore: string,
    quantity = 1,
  ): Observable<CartModification> {
    let headers: HttpHeaders;

    const url = this.occEndpointsService.getUrl('addEntries', {
      userId,
      cartId,
      quantity,
    });
    // Handle b2b case where the x-www-form-urlencoded is still used
    if (url.includes(`quantity=${quantity}`)) {
      headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      return this.http
        .post<CartModification>(url, {}, { headers, params: { code: productCode } })
        .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
    }

    const toAdd = {
      quantity,
      product: { code: productCode },
      deliveryPointOfService: {
        name: pickupStore,
      },
    };

    headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .post<CartModification>(url, toAdd, { headers })
      .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
  }

  public updateWithReturnAmount(
    userId: string,
    cartId: string,
    entryNumber: string,
    qty: number,
    returnAmount?: number,
  ): Observable<CartModification> {
    const params = {};

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpointsService.getUrl('updateEntries', {
      userId,
      cartId,
      entryNumber,
    });

    return this.http
      .patch<CartModification>(url, { quantity: qty, ...params, returnAmount: returnAmount }, { headers })
      .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
  }

  public addGiftCard(userId: string, cartId: string, giftCardApplyRequest: GiftCardApplyRequest): Observable<Cart> {
    const url = this.occEndpointsService.getUrl('giftCardApply', {
      userId,
      cartId,
    });
    return this.http.put<Cart>(url, giftCardApplyRequest).pipe(this.converterService.pipeable(CART_NORMALIZER));
  }

  public removeGiftCard(userId: string, cartId: string, giftcard: string): Observable<Cart> {
    const url = this.occEndpointsService.getUrl('giftCardRemove', {
      userId,
      cartId,
      giftcard,
    });
    return this.http.delete<Cart>(url).pipe(this.converterService.pipeable(CART_NORMALIZER));
  }

  removeAllGiftCards(userId: string, cartId: string): Observable<Cart> {
    const url = this.occEndpointsService.getUrl('removeAllGiftCards', {
      userId,
      cartId,
    });

    return this.http.delete<Cart>(url).pipe(this.converterService.pipeable(CART_NORMALIZER));
  }
}
