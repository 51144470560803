import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, UrlModule } from '@spartacus/core';
import { PageComponentModule } from '@spartacus/storefront';
import { BossThreeColumnComponent } from './three-column.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    PageComponentModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ThreeColumnComponentContainer: {
          component: () =>
            import(
              /* webpackChunkName: "boss-shared" */
              './three-column.component'
            ).then((m) => m.BossThreeColumnComponent),
        },
      },
    }),
  ],
  declarations: [BossThreeColumnComponent],
  exports: [BossThreeColumnComponent],
})
export class BossThreeColumnsModule {}
