import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { BossAppComponent } from './app.component';
import { B2cStorefrontModule, CmsPageGuard, PageLayoutComponent, PageSlotModule } from '@spartacus/storefront';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { CHECKOUT_FEATURE, MULTI_CART_FEATURE } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import {
  bossCheckoutReducerToken,
  bossMultiCartMetaReducers,
  bossMultiCartReducerToken,
  bossPageSizeReducerToken,
} from './spartacus/shared/reducers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpartacusModule } from './spartacus/spartacus.module';
import { BossJsonLdBuilderModule } from './spartacus/features/seo/builders/boss-json-ld-builder.module';
import { BossOutletComponentModule } from './spartacus/features/outlet/boss-outlet.module';

const devImports = [];
if (!environment.production) {
  devImports.push(StoreDevtoolsModule.instrument());
}

@NgModule({
  declarations: [BossAppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    B2cStorefrontModule,
    BrowserTransferStateModule,
    devImports,
    BossOutletComponentModule,
    StoreModule.forFeature(CHECKOUT_FEATURE, bossCheckoutReducerToken),
    StoreModule.forFeature(MULTI_CART_FEATURE, bossMultiCartReducerToken, {
      metaReducers: bossMultiCartMetaReducers,
    }),
    StoreModule.forFeature('pageSize', bossPageSizeReducerToken),
    SpartacusModule,
    RouterModule.forChild([
      {
        path: 'moebelhaeuser/:storeName',
        data: { pageLabel: '/moebelhaeuser' },
        component: PageLayoutComponent,
        canActivate: [CmsPageGuard],
      },
    ]),
    PageSlotModule,
    BossJsonLdBuilderModule,
  ],
  bootstrap: [BossAppComponent],
})
export class BossAppModule {}
