import { CmsComponent, Product } from '@spartacus/core';
import { Observable } from 'rxjs';
import { BannerLink, BossHomepageBasicStageBanner } from '../../shared/models';
import { MediaContainer } from '@spartacus/storefront';
import { BossHeadlineType } from '../../shared/components/headline/boss-headline-type';

export enum BossMainStageType {
  BLACK = 'black',
  GREEN = 'green',
  PASTEL_VIOLET = 'pastelviolet',
  PASTEL_BLUE = 'pastelblue',
  SILK_GREY = 'silkgrey',
  MAGENTA = 'magenta',
}

export enum BossStageTextLimitType {
  COPY_TEXT = 120,
  HEADLINE = 60,
  CTA = 32,
}

export enum BossStageStyleClass {
  DEFAULT = 'main-stage-default',
  BLACK = 'main-stage-black',
  GREEN = 'main-stage-green',
  PASTEL_BLUE = 'main-stage-pastel-blue',
  PASTEL_VIOLET = 'main-stage-pastel-violet',
  SILK_GREY = 'main-stage-silk-grey',
  MAGENTA = 'main-stage-magenta',
}

export enum BossButtonClass {
  DEFAULT = 'boss-btn-primary',
  BLACK = 'boss-btn-black',
  GREEN = 'boss-btn-white-green',
  MAGENTA = 'boss-btn-white-magenta',
}

export interface BossMainStageModel extends CmsComponent {
  uid?: string;
  uuid?: string;
  typeCode?: string;
  modifiedtime?: string;
  name?: string;
  container?: boolean;
  sliderTime?: number;
  bannerList?: string;
  styleClass?: string;
  styleClasses?: string;
  banners$?: Observable<BossHomepageBasicStageBanner>[];
  colorTheme?: string;
}

export interface BossMainStageBannerModel extends CmsComponent {
  text?: string;
  uid?: string;
  uuid?: string;
  typeCode?: string;
  modifiedtime?: string;
  name?: string;
  container?: boolean;
  bannerPriceProduct?: string;
  bannerLink?: BannerLink;
  bannerButtonText?: string;
  media?: MediaContainer;
  bannerStyleClass?: string;
  bannerHeadline?: string;
  mobileMedia?: MediaContainer;
  product$?: Observable<Product>;
  headlineType?: BossHeadlineType;
}
