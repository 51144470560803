<div *ngIf="document" class="boss-document-item">
  <i bossIcon class="document-icon" [type]="bossIconConfig.FILE_DOWNLOAD" aria-hidden="true"></i>

  <span class="boss-document-item-content">
    <a class="boss-document-item-name small-copy-bold pl-0" tabindex="0" [href]="document.url" download>{{
      document.altText
    }}</a>
    <span *ngIf="documentType" class="small-copy pl-0">({{ documentType }})</span>
  </span>
</div>
