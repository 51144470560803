import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BossStatusLabelComponent } from './boss-status-label.component';

@NgModule({
  declarations: [BossStatusLabelComponent],
  imports: [CommonModule],
  exports: [BossStatusLabelComponent],
})
export class BossStatusLabelComponentModule {}
