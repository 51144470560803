import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ProductListRouteParams } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { distinctUntilKeyChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BossCategoryHierarchy, BossCategoryTree } from '../../shared/models';
import { OccCategoryAdapter } from '../../shared/occ/category/occ-category-adapter';

@Component({
  selector: 'boss-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossCategoryTreeComponent implements OnInit, OnDestroy {
  categoryTree: BossCategoryTree;

  currentCategory: string;

  private onDestroy$ = new Subject<void>();

  constructor(
    private occCategoryAdapter: OccCategoryAdapter,
    private routingService: RoutingService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.routingService
      .getRouterState()
      .pipe(
        map((state) => state?.state?.params as ProductListRouteParams),
        distinctUntilKeyChanged('categoryCode'),
        tap((params: ProductListRouteParams) => {
          this.currentCategory = params?.categoryCode;
        }),
        switchMap(() => this.occCategoryAdapter.load(this.currentCategory, 'categoryTree')),
        map((categoryTree: BossCategoryTree) => ({
          categories: this.recurseCategoryHierarchy(categoryTree.categories),
        })),
        takeUntil(this.onDestroy$),
      )
      .subscribe((categoryTree: BossCategoryTree) => {
        this.categoryTree = categoryTree;

        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private recurseCategoryHierarchy(categoryHierarchy: BossCategoryHierarchy[]): BossCategoryHierarchy[] {
    return categoryHierarchy.map((category: BossCategoryHierarchy) => {
      if (category.subCategories) {
        const filteredSubCategories: BossCategoryHierarchy[] = category.subCategories.filter(
          (subCategory: BossCategoryHierarchy) => subCategory.countProduct,
        );

        return { ...category, subCategories: this.recurseCategoryHierarchy(filteredSubCategories) };
      } else {
        return category;
      }
    });
  }
}
