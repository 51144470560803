import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { bossIconConfig } from '../../../../shared/utils/boss-icon-config';
import { Review } from '@spartacus/core/src/model/product.model';

@Component({
  selector: 'boss-product-reviews',
  templateUrl: './product-reviews.component.html',
  styleUrls: ['./product-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossProductReviewsComponent {
  bossIconConfig = bossIconConfig;
  product$: Observable<Product> = this.currentProductService.getProduct();

  constructor(private currentProductService: CurrentProductService) {}

  getAverageRating(product: Product): string {
    let rating = this.round2Decimal(product?.averageRating, 1) + '';

    if (!rating.includes('.')) {
      rating += '.0';
    }

    return rating;
  }

  round2Decimal(x, places): number {
    const roundingFactor = Math.pow(10, places);
    const result = Math.round(x * roundingFactor) / roundingFactor;
    return result;
  }

  calculateReviewWidth(reviews: Review[], rating: number): string {
    const total = reviews?.length;
    const ratingCount = reviews?.filter((review) => review?.rating === rating)?.length || 0;
    const width = (ratingCount / total) * 100;

    return `${width}%`;
  }

  getAuthor(): string {
    return 'Möbel Boss Kunde';
  }
}
