import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossHeadlineComponent } from './boss-headline.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BossHeadlineComponent],
  exports: [BossHeadlineComponent],
})
export class BossHeadlineModule {}
