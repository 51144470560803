import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig({
      featureModules: {
        'boss-my-account': {
          module: () =>
            import(
              /* webpackChunkName: "boss-my-account" */
              './boss-user-profile.module'
            ).then((m) => m.BossUserProfileModule),
          cmsComponents: [
            'AccountOrderHistoryCMSFlexComponent',
            'UpdateEmailComponent',
            'UpdatePasswordComponent',
            'AccountNavigationComponent',
            'UpdateProfileComponent',
            'AccountAddressBookComponent',
            'AccountOrderTrackingFlexComponent',
          ],
        },
      },
    } as CmsConfig),
  ],
})
export class BossUserProfileFeatureModule {}
