import { Address } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export abstract class BossAddressRepository {
  private requested = false;

  private address: Address;

  private address$ = new BehaviorSubject<Address>({});

  abstract getAddressFromApi(): Observable<Address>;

  on(): Observable<Address> {
    const requestFromApi = !this.address?.id && !this.requested;

    this.requested = true;

    if (requestFromApi) {
      this.getAddressFromApi()
        .pipe(take(1))
        .subscribe((address: Address) => {
          if (address?.id) {
            this.next(address);
          }
        });
    }

    return this.address$.asObservable();
  }

  next(address: Address): void {
    this.address = address;
    this.address$.next(address);
  }

  reset(): void {
    this.address$.next({});
  }
}
