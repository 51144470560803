import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig({
      featureModules: {
        'boss-cart': {
          module: () =>
            import(
              /* webpackChunkName: "boss-cart" */
              './boss-cart.module'
            ).then((m) => m.BossCartModule),
          cmsComponents: ['CartComponent'],
        },
      },
    } as CmsConfig),
  ],
})
export class BossCartFeatureModule {}
