import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Facet, FacetValue } from '@spartacus/core';
import { FacetService } from '@spartacus/storefront';

@Component({
  selector: 'boss-facet-switch[facet]',
  templateUrl: './boss-facet-switch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossFacetSwitchComponent implements OnChanges {
  @Input() facet: Facet;

  saleValue: FacetValue;

  constructor(private facetService: FacetService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.facet) {
      this.saleValue = this.facet?.values?.find((value) => value.name === 'true');
    }
  }

  getLinkParams(): { [key: string]: string } {
    if (this.saleValue) {
      return this.facetService.getLinkParams(this.saleValue.query?.query?.value);
    }
  }
}
