import { Injectable } from '@angular/core';
import {
  BasePageMetaResolver,
  BreadcrumbMeta,
  CmsService,
  Occ,
  Page,
  PageBreadcrumbResolver,
  PageRobotsMeta,
  ProductSearchPage,
  ProductSearchService,
  RoutingService,
  SearchPageMetaResolver,
  TranslationService,
} from '@spartacus/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import PageType = Occ.PageType;

@Injectable({
  providedIn: 'root',
})
export class BossSearchPageMetaResolver extends SearchPageMetaResolver implements PageBreadcrumbResolver {
  constructor(
    routingService: RoutingService,
    productSearchService: ProductSearchService,
    translation: TranslationService,
    basePageMetaResolver: BasePageMetaResolver,
    private cms: CmsService,
  ) {
    super(routingService, productSearchService, translation, basePageMetaResolver);
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'SearchResultsGridPageTemplate';
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    const breadcrumbs: BreadcrumbMeta[] = [];
    return combineLatest([this.productSearchService.getResults(), this.cms.getCurrentPage()]).pipe(
      map(([productSearchPage, page]: [ProductSearchPage, Page]) => {
        if (page.title && page.title !== '') {
          breadcrumbs.push({
            label: page.title,
            link: '',
          } as BreadcrumbMeta);
        }

        if (productSearchPage.freeTextSearch && productSearchPage.freeTextSearch !== '') {
          breadcrumbs.pop();

          breadcrumbs.push({
            label: productSearchPage.freeTextSearch,
            link: '',
          } as BreadcrumbMeta);
        }
        return breadcrumbs;
      }),
    );
  }

  resolveTitle(): Observable<string> {
    let title = super.resolveTitle();
    return this.cms.getCurrentPage().pipe(
      switchMap((page: Page) => {
        if (page?.title) {
          title = of(page.title);
        }
        return title;
      }),
    );
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return of([PageRobotsMeta.NOINDEX, PageRobotsMeta.FOLLOW]);
  }
}
