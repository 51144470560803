import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BossRangeSliderValues } from './boss-range-slider';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'boss-range-slider',
  templateUrl: './boss-range-slider.component.html',
  styleUrls: ['./boss-range-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossRangeSliderComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input()
  range: BossRangeSliderValues = { min: 0, max: 100 };

  @Input()
  decimal = 0;

  @Input()
  min: number;

  @Input()
  max: number;

  @Output()
  changes = new EventEmitter();

  formGroup: FormGroup;

  left = '0%';

  right = '0%';

  isDisabled = false;

  private onDestroy$ = new Subject<void>();

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef) {
    this.formGroup = this.fb.group({
      min: [0],
      max: [100],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.min) {
      if (this.min !== undefined && this.min <= this.max) {
        this.formGroup.controls['min'].setValue(this.min);
      }
    }

    if (changes.max) {
      if (this.max !== undefined && this.max >= this.min) {
        this.formGroup.controls['max'].setValue(this.max);
      }
    }

    if (changes.range) {
      this.checkRange();
    }
  }

  ngOnInit(): void {
    this.setDefault();

    this.formGroup.controls['min'].valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.manageRangeValue(true);
      });

    this.formGroup.controls['max'].valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.manageRangeValue(false);
      });
  }

  ngAfterViewInit(): void {
    this.setDefaultProgress();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private updateValue(min: number, max: number, isMin?: boolean): void {
    const gap = 0.1;

    if (max - min < gap) {
      if (isMin) {
        this.formGroup.controls['min'].setValue(max - gap);
      } else {
        this.formGroup.controls['max'].setValue(min - gap);
      }
    }

    this.updateProgress(min, max);
  }

  private manageRangeValue(isMin: boolean): void {
    if (!this.isDisabled) {
      const max = this.getDecimalValue(this.formGroup.get('max').value),
        min = this.getDecimalValue(this.formGroup.get('min').value);

      this.updateValue(min, max, isMin);

      if (max >= min) {
        this.changes.emit({ max, min });
      }

      this.cdRef.detectChanges();
    }
  }

  private updateProgress(min: number, max: number): void {
    const range = this.range.max - this.range.min,
      calcMin = (100 * (min - this.range.min)) / range,
      calcMax = (100 * (max - this.range.min)) / range;

    this.left = this.getProgressStyle(min, calcMin, true);
    this.right = this.getProgressStyle(max, 100 - calcMax);
  }

  private getProgressStyle(formValue: number, calculatedValue: number, isMin = false): string {
    if (this.range.max >= formValue && this.range.min <= formValue) {
      return calculatedValue + '%';
    } else {
      if (this.range.max >= formValue && !isMin) {
        return '100%';
      }
      if (this.range.min <= formValue && isMin) {
        return '0%';
      }
    }
  }

  private setDefault(): void {
    if (this.range.min && this.range.max) {
      if (!this.min) {
        this.formGroup.controls['min'].setValue(this.range.min);
      }

      if (!this.max) {
        this.formGroup.controls['max'].setValue(this.range.max);
      }
    }
  }

  private getDecimalValue(value: number): number {
    return +value.toFixed(this.decimal);
  }

  private checkRange(): void {
    if (this.range?.min === this.range?.max) {
      this.range.max = this.range.max + 1;
      this.formGroup.controls['max'].setValue(this.range.max);
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

  private setDefaultProgress(): void {
    if (!this.isDisabled) {
      this.updateProgress(this.min, this.max);
      this.cdRef.detectChanges();
    }
  }
}
