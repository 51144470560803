<a *ngIf="facet" class="boss-filter-toggle__container" [queryParams]="getLinkParams()" [routerLink]="">
  <div class="boss-filter-toggle__label">
    <p class="small-copy-bold my-0 mr-2">
      {{ facet.name }}
    </p>
    <p class="small-copy m-0">({{ saleValue?.count }})</p>
  </div>

  <label class="boss-filter-toggle__switch">
    <input type="checkbox" [checked]="saleValue?.selected" (change)="toggleValueChanged($event.target.checked)" />
    <span class="boss-filter-toggle__slider"></span>
  </label>
</a>
