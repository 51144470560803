import { NgModule } from '@angular/core';
import { BossOutletComponent } from './boss-outlet.component';
import { OutletModule, OutletRefModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { ContentPage1TemplateComponent } from './content-page1-template/content-page1-template.component';
import { BossIconWrapperComponent } from './icon-wrapper/boss-icon-wrapper.component';

@NgModule({
  imports: [OutletModule, OutletRefModule, PageLayoutModule, PageSlotModule],
  declarations: [BossOutletComponent, ContentPage1TemplateComponent, BossIconWrapperComponent],
  exports: [BossOutletComponent],
})
export class BossOutletComponentModule {}
