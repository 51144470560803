import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BossProductVariantsSmallComponent } from './product-variants-small/product-variants-small.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BossProductVariantsSmallComponent],
  exports: [BossProductVariantsSmallComponent],
})
export class BossProductVariantsComponentModule {}
