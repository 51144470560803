import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { Product } from '@spartacus/core';
import { ProductStatusLabel, StatusItems } from '../../../shared/models';
import { BossProductPriceService } from '../product-price/services/product-price.service';

@Component({
  selector: 'boss-product-status',
  templateUrl: './boss-product-status.component.html',
  styleUrls: ['./boss-product-status.component.scss'],
})
export class BossProductStatusComponent implements OnInit, OnDestroy {
  @Input() product!: Product;

  @Input() max = 0;

  private subscriptions = new Subscription();

  private isMobile = false;

  constructor(private breakpointService: BreakpointService, private productPriceService: BossProductPriceService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.breakpointService.isDown(BREAKPOINT.md).subscribe((isMobile: boolean) => (this.isMobile = isMobile)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get labels(): ProductStatusLabel[] {
    let labels: ProductStatusLabel[] = [];

    const isNew = this.product?.newProduct;
    const onSale = this.productPriceService.isPricePromotional(this.product);
    const onlyOnline = this.product?.onlyOnlineProduct;
    const isAR = this.product?.augmentedRealityID;

    const labelNew: ProductStatusLabel = { classNames: 'boss-label-solid', label: StatusItems.NEW };
    const labelSale: ProductStatusLabel = { classNames: 'boss-label-sale', label: StatusItems.SALE };
    const labelOnlyOnline: ProductStatusLabel = { classNames: 'boss-label-outline', label: StatusItems.ONLY_ONLINE };
    const labelAR: ProductStatusLabel = { classNames: 'boss-label-ar', label: StatusItems.AR_3D };

    if (isNew) {
      labels.push(labelNew);
    }
    if (onSale) {
      labels.push(labelSale);
    }
    if (onlyOnline) {
      labels.push(labelOnlyOnline);
    }
    if (isAR) {
      labels.push(labelAR);
    }

    // Handle mobile only online
    // On mobile the only label shown is `onlyOnline` when present
    if (this.isMobile && onlyOnline) {
      labels = [labelOnlyOnline];
    }

    if (!labels.length) {
      return [];
    }

    // Limit how many labels to show
    if (this.max) {
      return labels.slice(0, this.getMaxLabelsCount());
    }

    return labels;
  }

  /**
   * Get max number of labels shown. We can have one label on mobile and any number of labels on desktop
   *
   * @return {*}  {number}
   */
  private getMaxLabelsCount(): number {
    return this.isMobile ? 1 : this.max;
  }
}
