import { PaginationConfig } from '@spartacus/storefront';

export const bossPaginationConfig: PaginationConfig = {
  pagination: {
    addDots: true,
    addFirst: true,
    addLast: true,
    addStart: false,
    addEnd: false,
  },
};
