import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { BossSearchBoxComponent } from './boss-search-box.component';
import { BossSafePipeModule } from '../../shared/pipes/boss-safe-pipe.module';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';
import { BossHighlightPipe } from './boss-search-highlight.pipe';

@NgModule({
  declarations: [BossSearchBoxComponent, BossHighlightPipe],
  imports: [
    CommonModule,
    MediaModule,
    UrlModule,
    RouterModule,
    I18nModule,
    BossIconDirectiveModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SearchBoxComponent: {
          component: BossSearchBoxComponent,
        },
      },
    } as CmsConfig),
    BossSafePipeModule,
  ],
  exports: [BossSearchBoxComponent],
})
export class BossSearchBoxModule {}
