import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Product } from '@spartacus/core';

@Component({
  selector: 'boss-energy-labels[product]',
  templateUrl: './boss-energy-labels.component.html',
  styleUrls: ['./boss-energy-labels.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossEnergyLabelsComponent {
  @Input()
  product: Product;

  @Input()
  maxVisibleLabels: number | null = null;

  @Input()
  expandedDesign = false;
}
