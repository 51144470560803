import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { BossFooterTopComponent } from './footer-top.component';
import { FooterTopService } from './footer-top.service';
import { GenericLinkModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { BossMediaDirectiveModule } from '../../../shared/directives/media/boss-media.directive.module';
import { BossIconDirectiveModule } from '../../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    RouterModule,
    BossMediaDirectiveModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FooterTopComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-footer" */
              './footer-top.component'
            ).then((m) => m.BossFooterTopComponent),
        },
      },
    }),
    GenericLinkModule,
  ],
  declarations: [BossFooterTopComponent],
  exports: [BossFooterTopComponent],
  providers: [FooterTopService],
})
export class BossFooterTopComponentModule {}
