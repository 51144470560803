<ng-container *ngIf="data$ | async as data">
  <div class="headline-intro-container" [ngStyle]="{ 'text-align': data.textAlign ? data.textAlign : 'center' }">
    <div class="headline-container">
      <h2 [class]="'boss-' + data.headlineType | lowercase">{{ data.headline }}</h2>
    </div>
    <p class="copy">
      {{ data.text }}
    </p>
  </div>
</ng-container>
