import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { bossIconConfig } from '../../utils/boss-icon-config';
import { contentTeamIconConfig } from '../../utils/content-team-icon-config';

@Directive({
  selector: '[bossIcon]',
})
export class BossIconDirective implements OnChanges {
  @Input() type: bossIconConfig;
  @Input() iconSize = 20;

  iconConfig = { ...bossIconConfig, ...contentTeamIconConfig };

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      if (changes.type.previousValue) {
        this.removePreviousClass(changes.type.previousValue);
      }
      this.setIcon();
    }
  }

  private setIcon(): void {
    this.renderer.addClass(this.elementRef.nativeElement, this.type);
    this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', `${this.iconSize}px`);
  }

  private removePreviousClass(previousClass: string): void {
    this.renderer.removeClass(this.elementRef.nativeElement, previousClass);
  }
}
