import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { BREAKPOINT, BreakpointService, CmsComponentData } from '@spartacus/storefront';
import { EMPTY, Observable, Subscription, combineLatest, interval, of, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { BOSS_SALES_BANNER_DATA, BossTransferStateService } from '../../shared/services/boss-transfer-state';
import { BossSalesBannerComponentCmsData } from '../../shared/models';

@Component({
  selector: 'boss-sales-banner',
  templateUrl: './boss-sales-banner.component.html',
  styleUrls: ['./boss-sales-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossSalesBannerComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  timeLeft = {
    sec: '0',
    min: '0',
    h: '0',
    day: '0',
  };

  isMobile: boolean;

  componentData: BossSalesBannerComponentCmsData;

  codeCopied: boolean;

  bossIconConfig = bossIconConfig;

  constructor(
    private cmsComponentData: CmsComponentData<BossSalesBannerComponentCmsData>,
    private breakpointService: BreakpointService,
    private cdRef: ChangeDetectorRef,
    private winRef: WindowRef,
    private bossTransferState: BossTransferStateService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.getCmsData()
        .pipe(
          switchMap((data: BossSalesBannerComponentCmsData) => {
            this.componentData = data;

            return this.winRef.isBrowser() ? combineLatest([of(data), interval(1000)]) : EMPTY;
          }),
        )
        .subscribe(([data]) => {
          if (data?.countDown) {
            const dDay = new Date(data.countDown);
            this.calculateTime(dDay);
          }
        }),
    );

    this.subscription.add(
      this.breakpointService.isDown(BREAKPOINT.md).subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  copyToClipboard(salesCode: string, event): void {
    if (this.winRef.isBrowser()) {
      event.preventDefault();
      navigator.clipboard.writeText(salesCode).catch(() => {});

      this.codeCopied = true;

      this.cdRef.detectChanges();

      this.subscription.add(
        timer(5000).subscribe(() => {
          this.codeCopied = false;

          this.cdRef.detectChanges();
        }),
      );
    }
  }

  private calculateTime(targetDate: Date): void {
    const ms = 1000;
    const sec = 60;
    const min = 60;
    const h = 24;

    const timeDifference = targetDate.getTime() - new Date().getTime();

    this.timeLeft.sec = this.prefix(Math.floor((timeDifference / ms) % sec));
    this.timeLeft.min = this.prefix(Math.floor((timeDifference / (ms * min)) % sec));
    this.timeLeft.h = this.prefix(Math.floor((timeDifference / (ms * min * sec)) % h));
    this.timeLeft.day = this.prefix(Math.floor(timeDifference / (ms * min * sec * h)));

    this.cdRef.detectChanges();
  }

  /**
   * Prefix string with zeroes
   *
   * @param {number} num
   * @return {*}  {string}  Prefixed string
   */
  private prefix(num: number): string {
    let s = num + '';
    while (s.length < 2) s = '0' + s;
    return s;
  }

  private getCmsData(): Observable<BossSalesBannerComponentCmsData> {
    const cmsData$ = this.bossTransferState.get<BossSalesBannerComponentCmsData>(BOSS_SALES_BANNER_DATA);

    return (
      cmsData$ ||
      this.cmsComponentData.data$.pipe(
        tap((data: BossSalesBannerComponentCmsData) => {
          this.bossTransferState.set<BossSalesBannerComponentCmsData>(BOSS_SALES_BANNER_DATA, data);
        }),
      )
    );
  }
}
