import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { SCHEMA_BUILDER, SchemaBuilder } from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { BossJsonLdScriptFactory } from './boss-json-ld-script-factory';

/**
 * Factory service that is used to build the structured data for
 * all configured schema builders.
 */
@Injectable({
  providedIn: 'root',
})
export class BossStructuredDataFactory implements OnDestroy {
  constructor(
    private scriptBuilder: BossJsonLdScriptFactory,
    @Optional()
    @Inject(SCHEMA_BUILDER)
    private builders: SchemaBuilder[],
  ) {}

  private subscription: Subscription = new Subscription();

  /**
   * Initiates the build of structured data by collecting all schema
   * builders.
   */
  build(): void {
    if (this.builders) {
      this.subscription.add(
        this.collectSchemas().subscribe((schema: {}[]) => {
          this.scriptBuilder.build(schema);
        }),
      );
    }
  }

  /**
   * Collects all schema builders and observe their structured data.
   */
  protected collectSchemas(): Observable<{}[]> {
    return combineLatest(
      this.builders.map((builder) => {
        return builder.build();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
