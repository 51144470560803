import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import {
  ActiveCartService,
  Address,
  AuthRedirectService,
  AuthService,
  SemanticPathService,
  User,
} from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckoutConfigService } from '@spartacus/storefront';
import { BossCheckoutClickAndCollectService } from '../services/boss-checkout-click-and-collect.service';

@Injectable()
export class BossCheckoutAuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private authRedirectService: AuthRedirectService,
    private checkoutConfigService: CheckoutConfigService,
    private activeCartService: ActiveCartService,
    private semanticPathService: SemanticPathService,
    private router: Router,
    private checkoutClickAndCollectService: BossCheckoutClickAndCollectService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.checkoutClickAndCollectService.onPurgeDeliveryAddress(),
      this.authService.isUserLoggedIn(),
      this.activeCartService.getAssignedUser(),
    ]).pipe(
      map(([, isLoggedIn, cartUser]: [Address, boolean, User]) => {
        return isLoggedIn ? true : this.handleAnonymousUser(cartUser);
      }),
    );
  }

  handleAnonymousUser(cartUser: User): boolean | UrlTree {
    if (this.activeCartService.isGuestCart()) {
      return Boolean(cartUser);
    }

    this.authRedirectService.reportAuthGuard();

    if (this.checkoutConfigService.isGuestCheckout()) {
      return this.router.createUrlTree([this.semanticPathService.get('checkoutLogin')]);
    } else {
      return this.router.parseUrl(this.semanticPathService.get('login'));
    }
  }
}
