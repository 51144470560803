import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BossRangeSliderValues } from '../../../shared/components/range-slider/boss-range-slider';
import { BossValidatorService } from '../../../shared/services/validator.service';
import { Breadcrumb, Facet } from '@spartacus/core';
import { BossFilterCodes } from '../../../shared/components/facets/boss-filter-codes';
import { FacetService } from '@spartacus/storefront';
import { Router } from '@angular/router';

@Component({
  selector: 'boss-filter-range[facet]',
  templateUrl: './boss-filter-range.component.html',
  styleUrls: ['./boss-filter-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossFilterRangeComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  facet: Facet;

  @Input()
  activeFacets: Breadcrumb[];

  form: FormGroup;

  range: BossRangeSliderValues;

  isDisabled = true;

  min: number;

  max: number;

  isCurrency = true;

  private onDestroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private validationService: BossValidatorService,
    private router: Router,
    private facetService: FacetService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.form = this.fb.group({
      min: [],
      max: [],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.facet) {
      if (this.facet) {
        this.manageFacetData();
      }
    }
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged((previous: BossRangeSliderValues, current: BossRangeSliderValues) => {
          return previous.min === current.min && previous.max === current.max;
        }),
        debounceTime(100),
        takeUntil(this.onDestroy$),
      )
      .subscribe((value: BossRangeSliderValues) => {
        if (this.getFormItemControl('min').valid) {
          this.min = value.min;
        }
        if (this.getFormItemControl('max').valid) {
          this.max = value.max;
        }

        if (this.form.dirty) {
          this.isDisabled = !this.form.valid;
        }

        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getFormItemControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  reset(): void {
    const activeQuery = this.activeFacets.find((breadCrumb: Breadcrumb) => breadCrumb.facetCode === this.facet.code);
    if (activeQuery) {
      const queryParams = this.facetService.getLinkParams(activeQuery.removeQuery.query.value);
      this.router.navigate([], { queryParams });
      return;
    }

    this.updateForm(this.range);
  }

  updateForm(value: BossRangeSliderValues): void {
    this.form.setValue(value);
    this.form.markAsDirty();
  }

  getFilterTypeSymbol(): string {
    return this.isCurrency ? '€' : 'cm';
  }

  getLinkParams(): { [key: string]: string } {
    const query = this.getQueryParam();
    return this.facetService.getLinkParams(query);
  }

  areButtonsVisible(): boolean {
    return this.facet.max !== this.facet.queryMax || this.facet.min !== this.facet.queryMin || this.form.dirty;
  }

  isButtonDisabled(): boolean {
    return this.isDisabled || this.range.max === this.range.min;
  }

  private getQueryParam(): string {
    let currentQuery = this.facet.values[0].query.query.value.split(':');

    currentQuery = currentQuery.slice(0, -2);

    const filterIndex = currentQuery.findIndex((query: string) => query === this.facet.code);

    if (filterIndex !== -1) {
      currentQuery = currentQuery.map((query, index) => {
        if (filterIndex === index) {
          return `${this.facet.code}`;
        }

        if (filterIndex + 1 === index) {
          return `[${this.min} TO ${this.max}]`;
        }

        return query;
      });
      return currentQuery.join(':');
    } else {
      return currentQuery.join(':') + `:${this.facet.code}:[${this.min} TO ${this.max}]`;
    }
  }

  private manageFacetData(): void {
    this.range = this.getRange();
    this.setQueryMinMax();
    this.isCurrency = this.facet.code === BossFilterCodes.PRICE_SLIDER;
    this.setDefaultValues();
    this.setValidators();
  }

  private setDefaultValues(): void {
    const min = this.min || 0,
      max = this.max || 0,
      roundedMin = min < 0 ? 0 : Math.floor(min),
      roundedMax = max < 0 ? 0 : Math.ceil(max);

    this.getFormItemControl('min').setValue(roundedMin);
    this.getFormItemControl('max').setValue(roundedMax);
  }

  private setValidators(): void {
    if (this.form) {
      const min = this.getFormItemControl('min'),
        max = this.getFormItemControl('max'),
        range = this.range;

      min.setValidators([
        this.validationService.compareSize('max', {
          smallerThan: true,
          range,
        }),
      ]);
      max.setValidators([this.validationService.compareSize('min', { range })]);

      min.updateValueAndValidity();
      max.updateValueAndValidity();
    }
  }

  private getRange(): BossRangeSliderValues {
    const min = this.facet?.min && this.facet.min > 0 ? Math.floor(this.facet.min) : 0,
      max = this.facet?.max && this.facet.max > 0 ? Math.ceil(this.facet.max) : 0;
    return { min, max };
  }

  private setQueryMinMax(): void {
    let currentQuery = this.facet.values[0].query.query.value.split(':');
    currentQuery = currentQuery.slice(0, -2);
    const filterIndex = currentQuery.findIndex((query: string) => query === this.facet.code);
    if (filterIndex !== -1 && this.facet) {
      const squareBracketsRegex = /[[\]']+/g,
        activeQueries = decodeURI(currentQuery[filterIndex + 1])
          .replace(squareBracketsRegex, '')
          .split('+TO+'),
        activeMin = +activeQueries[0],
        activeMax = +activeQueries[1];
      this.min = this.range.min > activeMin ? this.range.min : activeMin;
      this.max = activeMax > this.range.max ? this.range.max : activeMax;
    } else {
      this.min = this.range.min ?? 0;
      this.max = this.range.max ?? 0;
    }
  }
}
