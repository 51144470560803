import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { BossForgotPasswordFormComponent } from './forgot-password-form.component';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ForgotPasswordComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-login" */
              './forgot-password-form.component'
            ).then((m) => m.BossForgotPasswordFormComponent),
        },
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
  ],
  declarations: [BossForgotPasswordFormComponent],
  exports: [BossForgotPasswordFormComponent],
})
export class BossForgotPasswordFormComponentModule {}
