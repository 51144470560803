import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { provideCmsStructure, WishListModule } from '@spartacus/storefront';
import { BossWishlistIconComponent } from './wishlist-icon.component';
import { RouterModule } from '@angular/router';
import { BossProductPriceComponentModule } from '../product/product-price/product-price.module';
import { HeaderDirectivesModule } from '../../shared/directives/header-directives/header-directives.module';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [BossWishlistIconComponent],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    WishListModule,
    BossProductPriceComponentModule,
    HeaderDirectivesModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BossWishlistIcon: {
          component: BossWishlistIconComponent,
        },
      },
    } as CmsConfig),
  ],
  providers: [
    provideCmsStructure({
      componentId: 'BossWishlistIcon',
      pageSlotPosition: 'WishListSlot',
    }),
  ],
})
export class BossWishlistIconComponentModule {}
