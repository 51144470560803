<div *ngIf="data" class="boss-content-stage container px-0">
  <div class="boss-content-stage-content">
    <boss-headline [text]="data.title | bossTextLimit: 50" [type]="data.titleSize"></boss-headline>

    <p *ngIf="data.text" [innerHtml]="data.text | bossTextLimit: 120 | bossSafe" class="subtitle"></p>

    <a *ngIf="data.link" [href]="data.link.url | bossDecodeUri" class="boss-btn boss-btn-primary">{{
      data.link.linkName | bossTextLimit: 32
    }}</a>
  </div>

  <img bossMedia [isAboveTheFold]="true" [container]="media" class="boss-content-stage-image" />
</div>
