import { NgModule } from '@angular/core';
import { CheckoutAdapter, CmsConfig, provideConfig } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { BossOccCheckoutAdapter } from './adapters/boss-occ-checkout.adapter';
import { BossCheckoutAuthGuard } from './guards/checkout-auth.guard';
import { BossCheckoutClickAndCollectService } from './services/boss-checkout-click-and-collect.service';
import { BossCheckoutService } from './services/boss-checkout.service';
import { bossCheckoutConfig } from '../../configuration/boss-checkout-config';

@NgModule({
  imports: [CommonModule],
  providers: [
    BossCheckoutService,
    BossCheckoutAuthGuard,
    BossCheckoutClickAndCollectService,
    { provide: CheckoutAdapter, useClass: BossOccCheckoutAdapter },
    provideConfig(bossCheckoutConfig),
    provideConfig({
      featureModules: {
        'boss-checkout-module': {
          module: () =>
            import(
              /* webpackChunkName: "boss-checkout" */
              './boss-checkout.module'
            ).then((m) => m.BossCheckoutModule),
          cmsComponents: [
            'CheckoutHeader',
            'CheckoutPaymentDetails',
            'CheckoutReviewOrder',
            'CheckoutShippingAddress',
            'CheckoutFooter',
            'CheckoutProgress',
            'GuestAndRegisterCheckoutComponent',
          ],
        },
      },
    } as CmsConfig),
  ],
})
export class BossCheckoutFeatureModule {}
