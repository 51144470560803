import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { BossHamburgerMenuService } from './boss-hamburger-menu.service';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: './boss-hamburger-menu',
  templateUrl: './boss-hamburger-menu.component.html',
  styleUrls: ['./boss-hamburger-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossHamburgerMenuComponent implements OnInit, OnDestroy {
  isMobile = false;
  isExpanded = false;
  private subscription = new Subscription();

  constructor(
    private hamburgerMenuService: BossHamburgerMenuService,
    private breakPointService: BreakpointService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
        this.hamburgerMenuService.toggle(true);
      }),
    );

    this.subscription.add(
      this.hamburgerMenuService.isExpanded.subscribe((isExpandedFlag) => {
        this.isExpanded = isExpandedFlag;
        this.cdRef.detectChanges();
      }),
    );

    this.subscription.add(
      this.breakPointService.isDown(BREAKPOINT.md).subscribe((isMobileFlag) => {
        this.isMobile = isMobileFlag;
        this.cdRef.detectChanges();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggle(): void {
    this.hamburgerMenuService.toggle();
  }
}
