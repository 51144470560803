export enum contentTeamIconConfig {
  GIFT = 'ri-gift-line',
  STORE_2 = 'ri-store-2-line',
  GOOGLE = 'ri-google-fill',
  MONEY_EURO_CIRCLE = 'ri-money-euro-circle-line',
  MONEY_EURO_CIRCLE_FILL = 'ri-money-euro-circle-fill',
  SHOPPING_CART_2 = 'ri-shopping-cart-2-fill',
  COUPON = 'ri-coupon-fill',
  PERCENT = 'ri-percent-line',
  TRUCK__FILL = 'ri-truck-fill',
  TRUCK = 'ri-truck-line',
  CUSTOMER_SERVICE_2_FILL = 'ri-customer-service-2-fill',
  CUSTOMER_SEERVICE_2 = 'ri-customer-service-2-line',
  HAND_HEART = 'ri-hand-heart-fill',
  RULER = 'ri-ruler-2-fill',
  PENCIL_RULER = 'ri-pencil-ruler-2-line',
  PLUG = 'ri-plug-fill',
  RECYCLE = 'ri-recycle-fill',
  INSTAGRAM = 'ri-instagram-line',
  FACEBOOK = 'ri-facebook-circle-fill',
  SWAP_BOX = 'ri-swap-box-line',
  SHIELD_CHECK = 'ri-shield-check-line',
  FRIDGE = 'ri-fridge-line',
  EARTH = 'ri-earth-line',
  PARKING = 'ri-parking-fill',
}
