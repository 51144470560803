import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { ModalModule, SpinnerModule } from '@spartacus/storefront';
import { BossProductPostCodeModalComponent } from './product-post-code-modal.component';
import { BossModalCloseButtonModule } from '../../../../shared/components/boss-modal-close-button/boss-modal-close-button.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    BossModalCloseButtonModule,
  ],
  declarations: [BossProductPostCodeModalComponent],
  exports: [BossProductPostCodeModalComponent],
})
export class BossProductPostCodeModalComponentModule {}
