import { translations } from '@spartacus/assets';
import { deepMerge } from '@spartacus/core';
import { common } from './common';
import { user } from './user';
import { forms } from './forms';
import { storeFinder } from './store-finder';
import { cart } from './cart';
import { checkout } from './checkout';
import { product } from './product';
import { myAccount } from './my-account';
import { address } from './address';
import { userProfile } from './user-profile';
import { consent } from './consent';
import { price } from './price';

const deCustom = {
  address,
  cart,
  checkout,
  common,
  forms,
  myAccount,
  product,
  price,
  storeFinder,
  user,
  userProfile,
  consent,
};

export const de = deepMerge({}, translations.de, deCustom);
