import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';
import { BossWishlist } from '../wishlist/wishlist.model';
import { BossWishlistService } from '../wishlist/wishlist.service';

@Component({
  selector: 'boss-wishlist-icon',
  templateUrl: './wishlist-icon.component.html',
  styleUrls: ['./wishlist-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossWishlistIconComponent implements OnInit {
  wishlist$: Observable<BossWishlist>;
  bossIconConfig = bossIconConfig;

  constructor(private wishlistService: BossWishlistService) {}

  ngOnInit(): void {
    this.wishlist$ = this.wishlistService.getWishlist();
  }
}
