import { Injectable } from '@angular/core';
import { Product } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { JsonLdBuilder } from '@spartacus/storefront';
import { environment } from '../../../../../environments/environment';
import { BossProductOfferSchema } from '../models/boss-product-offer.model';

/**
 * Builds the structured data for the product offer, see https://schema.org/offers.
 * The data includes the price, currency, availability level, url.
 */
@Injectable({
  providedIn: 'root',
})
export class BossJsonLdProductOfferBuilder implements JsonLdBuilder<Product> {
  build(product: Product): Observable<{ [offers: string]: BossProductOfferSchema }> {
    const schema: BossProductOfferSchema = {
      '@type': 'Offer',
    };

    if (product.price?.value) {
      schema.price = product.price.value;

      if (product.price.currencyIso) {
        schema.priceCurrency = product.price.currencyIso;
      }
    }

    if (product.stock?.stockLevelStatus) {
      schema.availability = this.getAvailabilityName(product.stock.stockLevelStatus);
    }

    if (product.url) {
      schema.url = environment.mediaBaseUrl + product.url;
    }

    return of({ offers: schema });
  }

  private getAvailabilityName(stockLevelStatus: string): string {
    switch (stockLevelStatus.toLowerCase()) {
      case 'instock':
        stockLevelStatus = 'InStock';
        break;
      case 'lowstock':
        stockLevelStatus = 'LimitedAvailability';
        break;
      case 'outofstock':
        stockLevelStatus = 'OutOfStock';
        break;
    }

    return stockLevelStatus;
  }
}
