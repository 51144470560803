import { Injectable, Renderer2, RendererFactory2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class BossJsonLdScriptFactory {
  constructor(private winRef: WindowRef, private rendererFactory: RendererFactory2, private sanitizer: DomSanitizer) {}

  build(schema: {}[]): void {
    if (schema) {
      this.getJsonLdScriptElement().innerHTML = this.sanitize(schema);
    }
  }

  /**
   * Creates a json-ld script element. The element is created one, and appended
   * to the html body element.
   *
   * ```html
   * <script id="json-ld" type="application/ld+json">
   * </script>
   * ```
   */
  protected getJsonLdScriptElement(): HTMLScriptElement {
    const id = 'json-ld';
    let scriptElement: HTMLScriptElement = this.winRef.document.getElementById(id) as HTMLScriptElement;

    if (!scriptElement) {
      const renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
      const script: HTMLScriptElement = renderer.createElement('script');
      script.id = id;
      script.type = 'application/ld+json';
      renderer.appendChild(this.winRef.document.body, script);
      scriptElement = script;
    }

    return scriptElement;
  }

  /**
   * Sanitizes the given json-ld schema by leveraging the angular HTML sanitizer.
   *
   * The given schema is not trusted, as malicious code could be injected (XSS)
   * into the json-ld script.
   */
  sanitize(schema: {}): string {
    return JSON.stringify(schema, (_key, value) =>
      typeof value === 'string' ? this.sanitizer.sanitize(SecurityContext.HTML, value) : value,
    );
  }
}
