import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeaserStoreFinderComponent } from './teaser-store-finder.component';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule } from '@spartacus/storefront';
import { BossIconDirectiveModule } from '../../../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormErrorsModule,
    I18nModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        TeaserStoreFinderComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-storefinder" */
              './teaser-store-finder.component'
            ).then((m) => m.TeaserStoreFinderComponent),
        },
      },
    }),
  ],
  declarations: [TeaserStoreFinderComponent],
  exports: [TeaserStoreFinderComponent],
})
export class BossTeaserStoreFinderModule {}
