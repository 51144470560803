import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BossHeadlineType } from './boss-headline-type';

@Component({
  selector: 'boss-headline[text]',
  templateUrl: './boss-headline.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossHeadlineComponent {
  @Input()
  type: BossHeadlineType = BossHeadlineType.H2;

  @Input()
  text = '';

  BossHeadlineType = BossHeadlineType;
}
