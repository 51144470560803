import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetActiveStateDirective } from './set-active-state.directive';

@NgModule({
  declarations: [SetActiveStateDirective],
  exports: [SetActiveStateDirective],
  imports: [CommonModule],
})
export class HeaderDirectivesModule {}
