import { Injectable } from '@angular/core';
import { Product } from '@spartacus/core';
import { BossPriceType } from '../../../../shared/components/boss-price/price-type.model';

@Injectable()
export class BossProductPriceService {
  isPricePromotional(product: Product): boolean {
    return this.isPriceCrossed(product) && product?.saleProduct;
  }

  isPriceCrossed(product: Product): boolean {
    return product?.priceDisplayType === BossPriceType.CROSSED && !!product?.crossedPrice;
  }
}
