import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule, ProductListModule, SpinnerModule, StarRatingModule } from '@spartacus/storefront';
import { BossProductAvailabilityComponentModule } from '../product-availability/product-availability.module';
import { BossProductPriceComponentModule } from '../product-price/product-price.module';
import { BossProductStarRatingComponentModule } from '../product-star-rating/product-star-rating.module';
import { BossProductVariantsComponentModule } from '../product-variants/product-variants.module';
import { BossProductListComponent } from './container/product-list.component';
import { BossProductGridItemComponent } from './product-grid-item/product-grid-item.component';
import { ProgressBarModule } from './progress-bar/progress-bar.module';
import { BossProductCardComponentModule } from './product-card/product-card.module';
import { BossPaginationComponentModule } from '../../pagination/boss-pagination.module';
import { BossDialogWrapperModule } from '../../../shared/components/dialog/dialog-wrapper/boss-dialog-wrapper.module';
import { BossIconDirectiveModule } from '../../../shared/directives/icon/boss-icon.directive.module';
import { BossDropdownComponentModule } from '../../../shared/components/dropdown/boss-dropdown.module';
import { BossActiveFacetsModule } from '../../../shared/components/facets/active-facets/boss-active-facets.module';
import { pageSizeReducer } from '../../../shared/reducers/product-list.reducer';

@NgModule({
  imports: [
    ProgressBarModule,
    CommonModule,
    RouterModule,
    MediaModule,
    I18nModule,
    StarRatingModule,
    SpinnerModule,
    ProductListModule,
    BossProductPriceComponentModule,
    BossProductAvailabilityComponentModule,
    BossProductStarRatingComponentModule,
    BossProductVariantsComponentModule,
    StoreModule.forRoot(pageSizeReducer),
    BossDropdownComponentModule,
    BossProductCardComponentModule,
    BossActiveFacetsModule,
    BossPaginationComponentModule,
    BossIconDirectiveModule,
    BossDialogWrapperModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSProductListComponent: {
          component: BossProductListComponent,
        },
        ProductGridComponent: {
          component: BossProductListComponent,
        },
        SearchResultsListComponent: {
          component: BossProductListComponent,
        },
        SearchResultsGridComponent: {
          component: BossProductListComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [BossProductListComponent, BossProductGridItemComponent],
  exports: [BossProductListComponent, BossProductGridItemComponent],
})
export class BossProductListComponentModule {}
