import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Image, OccEndpointsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteLogoComponentCmsData } from '../../shared/models';

@Component({
  selector: 'boss-site-logo',
  styleUrls: ['./site-logo.component.scss'],
  templateUrl: './site-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossSiteLogoComponent {
  readonly baseUrl = this.occEndpointsService.getBaseUrl({
    baseUrl: true,
    prefix: false,
    baseSite: false,
  });

  image$: Observable<Image> = this.component.data$.pipe(map((data: SiteLogoComponentCmsData) => data?.image));

  constructor(
    private component: CmsComponentData<SiteLogoComponentCmsData>,
    private occEndpointsService: OccEndpointsService,
  ) {}
}
