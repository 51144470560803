<ng-template cxOutletRef="ContentPage1Template">
  <cx-page-slot position="Section1"></cx-page-slot>
  <div class="container">
    <div class="d-flex flex-row row">
      <cx-page-slot position="Section2A" class="col-lg-3 col-md-12"></cx-page-slot>
      <cx-page-slot position="Section2B" class="col-lg-9 col-md-12"></cx-page-slot>
    </div>
  </div>
  <cx-page-slot position="Section3"></cx-page-slot>
</ng-template>
