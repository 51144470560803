// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  occBaseUrl: 'https://occ-dev.moebel-boss.de',
  mediaBaseUrl: 'https://occ-dev.moebel-boss.de',
  occClientUser: 'web_client',
  occClientSecret: 'LmGh3sNG81TWGQsUm5HLBDuS0lrg2',
  googleMapsApiKey: 'AIzaSyCjGkjaeEfi-CpAK66P26TaYR9U41YeDIs',
  smarteditOrigin: 'occ-dev.moebel-boss.de:443',
  gtmId: 'GTM-KRCWD42',
  kitchenPlannerLicense: '22df8f1a-2bcc-092b-ddc0-ab91b0bf434f',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
