import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  BREAKPOINT,
  BreakpointService,
  FacetCollapseState,
  FacetGroupCollapsedState,
  FacetList,
  FacetService,
} from '@spartacus/storefront';
import { Breadcrumb, Facet, RoutingService, WindowRef } from '@spartacus/core';
import { BossFilterType } from '../../shared/components/facets/boss-filter-type';
import { FacetTypeService } from '../../shared/components/facets/facet-type.service';
import { ScreenOrientationType } from '../../shared/models/screen-orientation.model';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';

@Component({
  selector: 'boss-product-facet-navigation',
  templateUrl: './product-facet-navigation.component.html',
  styleUrls: ['./product-facet-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossProductFacetNavigationComponent implements OnInit, OnDestroy {
  @Input() hiddenFacets?: string[];

  BossFilterType = BossFilterType;

  bossIconConfig = bossIconConfig;

  isDialog: boolean = false;

  readonly isModalOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private isLandscapeMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Facets are shown for desktop always, but on mobile only after clicking a button
   */
  showFacets$: Observable<boolean> = combineLatest([
    this.breakpointService.isDown(BREAKPOINT.md),
    this.isModalOpen$,
    this.isLandscapeMode$,
  ]).pipe(
    tap(([isDown]) => {
      this.isDialog = isDown;
    }),
    switchMap(([isDown, isModalOpen]) => (isDown ? of(isModalOpen) : of(true))),
  );

  facetList?: FacetList;

  private subscription = new Subscription();

  constructor(
    private breakpointService: BreakpointService,
    private facetService: FacetService,
    private renderer: Renderer2,
    private routingService: RoutingService,
    private winRef: WindowRef,
    private facetTypeService: FacetTypeService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.facetService.facetList$.subscribe((facetList: FacetList) => {
        this.facetList = this.extendWithFilterType(facetList);

        if (this.hiddenFacets) {
          this.facetList = this.hideFacets(this.facetList);
        }

        this.cdRef.detectChanges();
      }),
    );

    if (this.winRef.isBrowser()) {
      this.observeScreenOrientationChange();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openModal(): void {
    if (this.winRef.isBrowser()) {
      this.renderer.addClass(document.body, 'modal-open');
      this.isModalOpen$.next(true);
    }
  }

  closeModal(): void {
    if (this.winRef.isBrowser()) {
      this.renderer.removeStyle(document.body, 'position');
      this.renderer.removeClass(document.body, 'modal-open');
      this.isModalOpen$.next(false);
    }
  }

  /**
   * Since facets are route-based, we pass an empty object to remove all of them
   */
  removeAllFilters(): void {
    this.routingService.go([], {});
  }

  isCollapsed(facet: Facet): Observable<boolean> {
    return this.facetService
      .getState(facet)
      .pipe(
        map((value: FacetCollapseState) =>
          this.isDialog ? value.toggled === FacetGroupCollapsedState.COLLAPSED : true,
        ),
      );
  }

  updateFacetExtensibility(facet: Facet, isExpanded: boolean): void {
    this.facetService.toggle(facet, isExpanded);
  }

  private observeScreenOrientationChange(): void {
    this.subscription.add(
      fromEvent(this.winRef.nativeWindow.screen.orientation, 'change').subscribe((e) => {
        switch ((e.currentTarget as ScreenOrientation)?.type) {
          case ScreenOrientationType.LANDSCAPE_PRIMARY:
          case ScreenOrientationType.LANDSCAPE_SECONDARY:
            this.isLandscapeMode$.next(true);
            break;
          case ScreenOrientationType.PORTRAIT_PRIMARY:
          case ScreenOrientationType.PORTRAIT_SECONDARY:
            this.isLandscapeMode$.next(false);
            break;
          default:
            break;
        }
      }),
    );
  }

  private extendWithFilterType(facetList: FacetList): FacetList {
    return {
      ...facetList,
      facets: [
        ...facetList.facets.map((facet: Facet) => ({
          ...facet,
          filterType: this.facetTypeService.getFilterType(facet.code),
        })),
      ],
    };
  }

  private hideFacets(facetList: FacetList): FacetList {
    let activeFacets: Breadcrumb[] = facetList.activeFacets;
    let facets: Facet[] = facetList.facets;

    this.hiddenFacets.forEach((hiddenFacetName) => {
      activeFacets = activeFacets.filter((facet) => facet?.facetCode !== hiddenFacetName);
      facets = facets.filter((facet) => facet?.code !== hiddenFacetName);
    });

    return {
      activeFacets,
      facets,
    };
  }
}
