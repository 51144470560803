export const forms = {
  generalForm: {
    newsletter:
      'Ja, ich möchte per E-Mail-Newsletter über Trends, Aktionen, Gutscheine und mehr informiert werden. Ich bestätige, älter als 16 Jahre zu sein und willige ferner ein, dass das Öffnungs- und Klickverhalten der E-Mail-Newsletter verarbeitet werden darf. Eine Abmeldung ist jederzeit möglich. Mehr zum Datenschutz unter <a href="https://moebel-boss.de/datenschutz" target="_blank">Datenschutzhinweise</a>.',
    mandatoryFields: '*Pflichtfelder',
    submit: 'Jetzt absenden',
    title: {
      label: 'Anrede',
      labelMandatory: 'Anrede *',
      mr: 'Herr',
      mrs: 'Frau',
      divers: 'Divers',
      company: 'Firma',
    },
    firstname: {
      label: 'Vorname',
      labelMandatory: 'Vorname *',
      placeholder: 'Vorname',
      placeholderMandatory: 'Vorname *',
    },
    lastname: {
      label: 'Nachname',
      labelMandatory: 'Nachname *',
      placeholder: 'Nachname',
      placeholderMandatory: 'Nachname *',
    },
    email: {
      label: 'E-Mail-Adresse',
      labelMandatory: 'E-Mail-Adresse *',
      placeholder: 'E-Mail-Adresse',
      placeholderMandatory: 'E-Mail-Adresse *',
    },
    pos: {
      label: 'Einrichtungshaus',
      labelMandatory: 'Einrichtungshaus *',
      placeholder: 'Einrichtungshaus',
      placeholderMandatory: 'Einrichtungshaus *',
    },
    street: {
      label: 'Straße',
      labelMandatory: 'Straße *',
      placeholder: 'Straße',
      placeholderMandatory: 'Straße *',
      nr: 'Nr.',
    },
    houseNumber: {
      label: 'Hausnummer',
      labelMandatory: 'Hausnummer *',
      placeholder: 'Hausnummer',
      placeholderMandatory: 'Hausnummer *',
    },
    postalCode: {
      label: 'Postleitzahl',
      labelMandatory: 'Postleitzahl *',
      placeholder: 'Postleitzahl',
      placeholderMandatory: 'Postleitzahl *',
    },
    city: {
      label: 'Stadt',
      labelMandatory: 'Stadt *',
      placeholder: 'Stadt',
      placeholderMandatory: 'Stadt *',
    },
    country: {
      label: 'Land',
      labelMandatory: 'Land *',
      placeholder: 'Deutschland',
    },
    phone: {
      label: 'Telefonnummer',
      labelMandatory: 'Telefonnummer *',
      placeholder: 'Telefonnummer',
      placeholderMandatory: 'Telefonnummer *',
    },
  },
  bossContactForm: {
    title: {
      storeDetailsPage: 'Kontaktiere deinen Markt',
      contactPage: 'Kontaktformular',
    },
    headline: 'Kontakt',
    topic: {
      label: 'Thema',
      placeholder: 'Thema *',
      general: 'Allgemein',
      delivery: 'Lieferauskunft',
      claim: 'Reklamation',
      productinformation: 'Produktinformation',
      payment: 'Zahlungsauskunft',
      largeorder: 'Großbestellung',
      cancellation: 'Widerruf',
      consultingAppointment: 'Beratungstermin im BOSS Markt',
      kitchenconsulting: 'Küchenberatung',
    },
    subheadline:
      'Unser Kundenservice-Team beantwortet gerne Ihre Fragen.<br/><br/>Sie erreichen uns bei Fragen zu Ihrer Onlinebestellung auch über unsere Telefonhotline <a href="tel:+495731609860">05731 609-860</a> (Mo.-Fr. 8:00 bis 17:00 Uhr).<br/><br/>Bei Fragen zu unseren Märkten oder Ihrem bestehenden Kaufvertrag, wenden Sie sich bitte direkt an den jeweiligen Markt. <a href="/moebelhaeuser">Hier</a> finden Sie die entsprechenden Kontaktdaten.',
    contactPage: {
      title: 'Unser Kundenservice-Team beantwortet gerne deine Frage.',
      helpCenterInfo:
        'Du erreichst uns bei Fragen zu deiner Onlinebestellung auch über unsere Telefonhotline <a href="tel:05731 609-860">05731 609-860</a> (Mo.-Fr. 8:00 bis 17:00 Uhr).',
      storeInfo: {
        storeFinderLink: 'Hier',
        partOne:
          'Bei Fragen zu unseren Märkten oder deinem bestehenden Kaufvertrag, wende dich bitte direkt an den jeweiligen Markt.',
        partTwo: 'findest du die entsprechenden Kontaktdaten.',
      },
    },
    orderNumber: {
      label: 'Auftragsnummer*',
      placeholder: 'Auftragsnummer',
      info: {
        text: 'Information',
        tooltip: 'Die Auftragsnummer finden Sie auf Ihrer Auftragsbestätigung und im Bereich meine Bestellungen.',
      },
    },
    message: {
      label: 'Deine Nachricht',
      placeholder: 'Bitte beschreibe kurz dein Anliegen oder Frage',
    },
    customerNumber: {
      placeholder: 'Kundennummer',
    },
    contactTypeRadio: {
      label: 'Wie dürfen wir dich kontaktieren?',
      email: 'E-Mail',
      phone: 'Telefon',
    },
    orderDay: {
      placeholder: 'Bestellt am*',
      label: 'Bestelldatum',
    },
    notifyMe: 'Ich möchte eine Kopie meiner Anfrage an die angegebene E-Mail-Adresse erhalten.',
    privacy:
      '*Ja, ich habe die <a href="https://moebel-boss.de/datenschutz" target="_blank">Datenschutzhinweise</a> zur Kenntnis genommen, und bin damit einverstanden, dass die von mir angegebenen Daten elektronisch erhoben und gespeichert werden. Meine Daten werden dabei nur streng zweckgebunden zur Bearbeitung und Beantwortung meiner Anfrage benutzt. Mit dem Absenden des Kontaktformulars erkläre ich mich mit der Verarbeitung einverstanden.',
    submit: {
      success: 'Deine Anfrage wurde erfolgreich an uns übermittelt',
      error: 'Fehler bei der Übermittlung der Anfrage',
    },
    submitButton: 'Anfrage absenden',
    postalCode: {
      label: 'PLZ',
    },
    pos: {
      label: 'Markt auswählen',
    },
    concern: {
      label: 'Betrifft',
      store: 'Möbelhaus',
      onlineStore: 'Onlineshop',
      other: 'Sonstiges',
    },
  },
  bossMyAccountServiceForm: {
    subHeadline: 'Wir helfen dir gerne weiter!',
    formHeader: 'Wie dürfen wir dich kontaktieren?',
    formHeaderSubline: 'Bitte treffen Sie nachfolgende Angaben.',
    emailPlaceholder: 'E-Mail-Adresse',
    phonePlaceholder: 'Telefonnummer',
    messageLabel: 'Deine Nachricht',
    messagePlaceholder: 'Bitte beschreibe kurz dein Anliegen oder Frage',
    submitButton: 'Anfrage absenden',
    radio: {
      phoneOnly: 'nur Anruf',
      emailOnly: 'nur E-Mail',
      both: 'beides möglich (Anruf oder E-Mail)',
    },
    copy: 'Wir vom Möbel Boss Service Team freuen uns, dir behilflich zu sein.<br /><br />Bei Fragen zu unseren Produkten, Serviceleistungen oder Beratungen stehen wir dir gerne telefonisch, per E-Mail oder direkt im Markt zur Verfügung.<br /><br />Freundliche Grüße,<br />dein SB Möbel Boss Service Team',
  },
};
