<ng-container *ngIf="loaded">
  <ng-container *ngIf="isConsent === false; else consentTemplate">
    <div class="boss-consent">
      <h2 class="boss-h4">{{ 'consent.headline' | cxTranslate }}</h2>
      <p class="copy">{{ 'consent.subHeadline' | cxTranslate }}</p>
      <button type="button" class="boss-btn boss-btn-primary" (click)="acceptRequiredCookies()">{{
        'consent.cta' | cxTranslate
      }}</button>

      <p class="copy">
        {{ 'consent.subLine.first' | cxTranslate
        }}<a [routerLink]="'/datenschutz'" target="_blank">{{ 'consent.cookiesPage' | cxTranslate }}</a
        >{{ 'consent.subLine.second' | cxTranslate }} <ng-content select="'cookiesName'"></ng-content>.
      </p>
      <p class="copy">
        {{ 'consent.subLine.third' | cxTranslate }}
        <button (click)="openCookieMenu()" class="boss-link" type="button">
          {{ 'consent.cookiesManager' | cxTranslate }}</button
        >
        {{ 'consent.subLine.last' | cxTranslate }}
      </p>
    </div>
  </ng-container>

  <ng-template #consentTemplate>
    <ng-content></ng-content>
  </ng-template>
</ng-container>
