import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AddToCartComponent, CurrentProductService, ModalService } from '@spartacus/storefront';
import { ActiveCartService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { BossDYEventType } from '../dynamic-yield/model';
import { BossDynamicYieldService } from '../dynamic-yield/boss-dy.service';

@Component({
  selector: 'boss-add-to-cart',
  templateUrl: './boss-add-to-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossAddToCartComponent extends AddToCartComponent implements OnChanges {
  @Input()
  additionalModifierClasses?: string;
  @Input()
  customQuantity?: number;

  constructor(
    modalService: ModalService,
    currentProductService: CurrentProductService,
    cd: ChangeDetectorRef,
    activeCartService: ActiveCartService,
    private globalMessageService: GlobalMessageService,
    private dynamicYieldService: BossDynamicYieldService,
  ) {
    super(modalService, currentProductService, cd, activeCartService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const productChanges = changes.product;
    const stockLevel = productChanges?.currentValue?.stock?.stockLevel;

    if (stockLevel !== undefined) {
      this.updateHasStock(stockLevel);
    }
  }

  get isPurchasable(): boolean {
    return this.product?.purchasable;
  }

  addToCart(): void {
    let quantity: number;

    if (this.customQuantity) {
      quantity = this.customQuantity;
    } else {
      quantity = this.addToCartForm.get('quantity').value;
    }

    if (!this.productCode || quantity <= 0) {
      return;
    }

    this.activeCartService.addEntry(this.productCode, quantity);
    this.globalMessageService.add({ key: 'addToCart.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);

    this.dynamicYieldService.triggerEvent({
      name: 'Add to cart',
      properties: {
        dyType: BossDYEventType.ADD_TO_CART,
        productId: this.productCode,
        quantity,
        value: this.product.price.value * quantity,
      },
    });
  }

  updateHasStock(stockLevel: number): void {
    this.hasStock = stockLevel > 0;
  }
}
