export const consent = {
  consent: {
    headline: 'Für dich empfohlener Inhalt',
    subHeadline: 'Um diesen Inhalt anzeigen zu können, benötigen wir deine Zustimmung.',
    cta: 'Zustimmen',
    cookiesManager: 'Cookie-Einstellungen',
    cookiesPage: 'Datenschutzhinweisen',
    subLine: {
      first:
        'Hiermit stimme ich zu, dass meine Daten gespeichert und weitergegeben werden im Zusammenhang mit den Inhalten und unseren ',
      second: '. Folgende Cookies werden akzeptiert: ',
      third: 'In den ',
      last: 'findest du genauere Informationen zu deinen gesetzten Cookies.',
    },
  },
};
