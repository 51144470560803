import { Injectable, OnDestroy } from '@angular/core';
import {
  ActiveCartService,
  AuthService,
  Cart,
  getCartIdByUserId,
  OCC_CART_ID_CURRENT,
  OCC_USER_ID_ANONYMOUS,
  StateWithMultiCart,
  UserIdService,
} from '@spartacus/core';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BossMultiCartService } from './multi-cart.service';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { ProcessesLoaderState } from '@spartacus/core/src/state/utils/processes-loader';

@Injectable({
  providedIn: 'root',
})
export class BossActiveCartService extends ActiveCartService implements OnDestroy {
  private isQuantityUpdate = true;

  private quantityUpdateObserver!: Subscription;

  private mergeInProgress = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    protected store: Store<StateWithMultiCart>,
    protected userIdService: UserIdService,
    protected bossMultiCartService: BossMultiCartService,
    private authService: AuthService,
  ) {
    super(store, bossMultiCartService, userIdService);
    this.initActiveCart();
    // TODO BSN-3801 function should be removed after Spartacus 5.0 update
    this.authService
      .isUserLoggedIn()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn && this.mergeInProgress) {
          this.mergeInProgress = isLoggedIn;
        }
      });
  }

  addEntryWithStore(productCode: string, quantity: number, pickupStore: string): void {
    this.requireLoadedCart()
      .pipe(withLatestFrom(this.userIdService.getUserId()), takeUntil(this.onDestroy$))
      .subscribe(([cartState, userId]) => {
        this.bossMultiCartService.addEntryWithStore(
          userId,
          getCartIdByUserId(cartState.value, userId),
          productCode,
          pickupStore,
          quantity,
        );
      });
  }

  setQuantityUpdate(state: boolean, needTimeout = false): void {
    if (this.quantityUpdateObserver) {
      this.quantityUpdateObserver.unsubscribe();
    }

    if (needTimeout) {
      this.quantityUpdateObserver = timer(1000).subscribe({
        complete: () => {
          this.isQuantityUpdate = state;
          this.quantityUpdateObserver.unsubscribe();
        },
      });
    } else {
      this.isQuantityUpdate = state;
    }
  }

  getQuantityUpdate(): boolean {
    return this.isQuantityUpdate;
  }

  updateEntryWithReturnAmount(entryNumber: number, quantity: number, returnAmount: number): void {
    this.activeCartId$.pipe(withLatestFrom(this.userIdService.getUserId()), take(1)).subscribe(([cartId, userId]) => {
      this.bossMultiCartService.updateEntryWithReturnAmount(userId, cartId, entryNumber, quantity, returnAmount);
    });
  }

  requireLoadedCart(
    customCartSelector$?: Observable<ProcessesLoaderState<Cart>>,
  ): Observable<ProcessesLoaderState<Cart>> {
    return super.requireLoadedCart(customCartSelector$);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // TODO BSN-3801 function should be removed after Spartacus 5.0 update
  protected loadOrMerge(cartId: string, userId: string, previousUserId: string): void {
    if (cartId === OCC_CART_ID_CURRENT) {
      this.bossMultiCartService.loadCart({
        userId,
        cartId: OCC_CART_ID_CURRENT,
        extraData: {
          active: true,
        },
      });
    } else if (this.isGuestCart()) {
      this.guestCartMerge(cartId);
    } else if (
      userId !== previousUserId &&
      userId !== OCC_USER_ID_ANONYMOUS &&
      previousUserId !== OCC_USER_ID_ANONYMOUS
    ) {
      // This case covers the case when you are logged in and then asm user logs in and you don't want to merge, but only load emulated user cart
      // Similarly when you are logged in as asm user and you logout and want to resume previous user session
      this.bossMultiCartService.loadCart({
        userId,
        cartId,
        extraData: {
          active: true,
        },
      });
    } else if (!this.mergeInProgress) {
      this.mergeInProgress = true;
      this.bossMultiCartService.mergeToCurrentCart({
        userId,
        cartId,
        extraData: {
          active: true,
        },
      });
    }
  }
}
