import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossFacetNamePipe } from './boss-facet-name.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [BossFacetNamePipe],
  exports: [BossFacetNamePipe],
})
export class BossFacetNamePipeModule {}
