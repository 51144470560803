import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BossDocumentItemComponent } from './boss-document-item.component';
import { BossIconDirectiveModule } from '../../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [BossDocumentItemComponent],
  imports: [CommonModule, BossIconDirectiveModule],
  exports: [BossDocumentItemComponent],
})
export class BossDocumentItemComponentModule {}
