import { PLACED_ORDER_PROCESS_ID, PROCESS_FEATURE, StateUtils } from '@spartacus/core';

export const BOSS_PLACE_ORDER = '[Checkout] Boss Place Order';

export class BossPlaceOrder extends StateUtils.EntityLoadAction {
  readonly type = BOSS_PLACE_ORDER;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      payment_id: string;
      callId: string;
      termsChecked: boolean;
    },
  ) {
    super(PROCESS_FEATURE, PLACED_ORDER_PROCESS_ID);
  }
}
