import { Action } from '@ngrx/store';

export const initialState = {
  pageSize: 60,
};

export class SetPageSize implements Action {
  payload: number;
  readonly type = 'setPageSize';
  constructor(payload: number) {
    this.payload = payload;
  }
}

export class GetPageSize implements Action {
  payload: number;
  readonly type = 'getPageSize';
  constructor(payload: number) {
    this.payload = payload;
  }
}

export function pageSizeReducer(state = initialState, action: SetPageSize | GetPageSize): number | {} {
  switch (action.type) {
    case 'setPageSize': {
      const pageSize = action.payload;
      return {
        ...state,
        pageSize,
      };
    }

    case 'getPageSize': {
      return state.pageSize;
    }
  }

  return state;
}

export const getPageSize = (state): number => state.pageSize;
