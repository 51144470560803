import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '@spartacus/core';
import {
  BossProductVariant,
  BossProductVariantEffectuation,
  BossProductVariantLabel,
  BossProductVariantMaterial,
  BossProductVariants,
  BossProductVariantSize,
} from '../../../shared/models';

@Component({
  selector: 'boss-variant-selector',
  templateUrl: './boss-variant-selector.component.html',
})
export class BossVariantSelectorComponent implements OnChanges {
  @Input()
  product: Product;

  BossProductVariantLabel = BossProductVariantLabel;

  variantsEffectuations: BossProductVariantEffectuation[];
  variantsMaterials: BossProductVariantMaterial[];
  variantsSizes: BossProductVariantSize[];

  selectedEffectuation = '';
  selectedMaterial = '';
  selectedSize = '';

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product.currentValue) {
      this.setProductVariants(changes.product.currentValue.allVariants);
    }
  }

  goToProduct(url: string): void {
    this.router.navigateByUrl(url, { replaceUrl: true });
  }

  private setProductVariants(variants: BossProductVariants): void {
    this.variantsEffectuations = variants?.effectuationVariants?.length
      ? this.convertProductVariant(variants.effectuationVariants)
      : [];
    this.selectedEffectuation = this.variantsEffectuations.find((variant) => variant.selected)?.id;

    this.variantsMaterials = variants?.materialVariants?.length
      ? this.convertProductVariant(variants.materialVariants)
      : [];
    this.selectedMaterial = this.variantsMaterials.find((variant) => variant.selected)?.id;

    this.variantsSizes = variants?.sizeVariants?.length ? this.convertProductVariant(variants.sizeVariants) : [];
    this.selectedSize = this.variantsSizes.find((variant) => variant.selected)?.id;
  }

  private convertProductVariant(productVariants: BossProductVariant[]): BossProductVariant[] {
    return productVariants.map((productVariant: BossProductVariant) => {
      return { ...productVariant, id: productVariant.product.url };
    });
  }
}
