import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BossPillsModel } from '../boss-pills.model';

@Component({
  selector: 'boss-pill',
  templateUrl: './boss-pill.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossPillComponent {
  @Input() item: BossPillsModel;
}
