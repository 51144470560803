import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { BossProductDescriptionComponent } from './product-description.component';
import { BossProductDescriptionRoomPlayerComponent } from './boss-product-description-room-player.component';
import { BossSafePipeModule } from '../../../../shared/pipes/boss-safe-pipe.module';
import { BossDocumentItemComponentModule } from '../../../product/document-item/boss-document-item.module';
import { BossConsentModule } from '../../../../shared/components/consent/boss-consent.module';
import { BossSimpleCollapseComponentModule } from '../../../../shared/components/simple-collapse/boss-simple-collapse.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    BossSafePipeModule,
    GenericLinkModule,
    BossDocumentItemComponentModule,
    BossSimpleCollapseComponentModule,
    BossConsentModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductSummaryComponent: {
          component: BossProductDescriptionComponent,
        },
      },
    }),
  ],
  declarations: [BossProductDescriptionComponent, BossProductDescriptionRoomPlayerComponent],
  exports: [BossProductDescriptionComponent],
})
export class BossProductDescriptionComponentModule {}
