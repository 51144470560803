import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossIconDirective } from './boss-icon.directive';

@NgModule({
  declarations: [BossIconDirective],
  imports: [CommonModule],
  exports: [BossIconDirective],
})
export class BossIconDirectiveModule {}
