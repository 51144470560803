import { Injectable, isDevMode } from '@angular/core';
import { StateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BossTransferStateService {
  constructor(private transferState: TransferState) {}

  /**
   * Get data from `TransferState`. This is helpfull to omit doubled requests for SSR, where one request is done on server, the second by the browser.
   */
  get<T>(key: StateKey<T>): Observable<T> | undefined {
    const data = this.transferState.get<T>(key, undefined);

    // TODO: Let's leave this for now, as it will give us lots of information about our SSR
    if (isDevMode()) {
      console.log(`[BossTransferStateService] get('${key}'):`, data);
    }

    return data ? of(data) : undefined;
  }

  /**
   * Save data to `TransferState`. Firstly create a `StateKey` with `makeStateKey()`, then simply use this method, passing the key and data to be saved.
   */
  set<T>(key: StateKey<T>, data: T): void {
    if (data) {
      this.transferState.set<T>(key, data);

      return;
    }

    // TODO: Let's leave this for now, as it will give us lots of information about our SSR
    if (isDevMode()) {
      console.error('[BossTransferStateService] set(): Trying to set empty data');
    }
  }
}
