import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossCategoryTreeComponent } from './category-tree.component';
import { ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BossCategoryTreeComponent],
  exports: [BossCategoryTreeComponent],
  imports: [
    CommonModule,
    RouterModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CategoryTreeComponent: {
          component: BossCategoryTreeComponent,
        },
      },
    }),
  ],
})
export class BossCategoryTreeModule {}
