import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossGlobalMessageComponent } from './boss-global-message.component';
import { I18nModule } from '@spartacus/core';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [BossGlobalMessageComponent],
  exports: [BossGlobalMessageComponent],
  imports: [CommonModule, I18nModule, BossIconDirectiveModule],
})
export class BossGlobalMessageModule {}
