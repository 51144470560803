import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { TeaserNewsletterComponent } from './teaser-newsletter.component';
import { BossMediaDirectiveModule } from '../../shared/directives/media/boss-media.directive.module';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [TeaserNewsletterComponent],
  imports: [
    CommonModule,
    IconModule,
    FormsModule,
    I18nModule,
    FormErrorsModule,
    ReactiveFormsModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        TeaserNewsletterComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-shared" */
              './teaser-newsletter.component'
            ).then((m) => m.TeaserNewsletterComponent),
        },
      },
    }),
    BossMediaDirectiveModule,
  ],
  exports: [TeaserNewsletterComponent],
})
export class TeaserNewsletterComponentModule {}
