import { Injectable } from '@angular/core';
import { BossOccDeliveryAddressService } from '../occ/boss-occ-delivery-address.service';
import { Observable, of } from 'rxjs';
import { BossDeliveryAddressRepository } from '../repository/boss-delivery-address.repository';
import { ActiveCartService, Address, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { country } from '../../../shared/utils/boss-constants';

@Injectable()
export class BossDeliveryAddressService {
  constructor(
    private occDeliveryAddress: BossOccDeliveryAddressService,
    private activeCartService: ActiveCartService,
    private globalMessageService: GlobalMessageService,
    private deliveryAddressRepository: BossDeliveryAddressRepository,
  ) {}

  remove(): Observable<Address> {
    return this.activeCartService.getActiveCartId().pipe(
      switchMap((cartId: string) => {
        return cartId ? this.occDeliveryAddress.remove() : of({});
      }),
      map((address: Address) => {
        this.deliveryAddressRepository.reset();
        return address;
      }),
    );
  }

  setDeliveryAddress(address: Address): Observable<Address> {
    return this.getAddressWithCountry(address)?.id ? this.updateAddress(address) : this.createAndSetAddress(address);
  }

  updateAddress(address: Address): Observable<Address> {
    return this.occDeliveryAddress.updateDeliveryAddress(this.getAddressWithCountry(address)).pipe(
      catchError(() => of(this.getAddressWithCountry(address))),
      map(() => {
        this.deliveryAddressRepository.next(this.getAddressWithCountry(address));

        return this.getAddressWithCountry(address);
      }),
    );
  }

  createAndSetAddress(address: Address): Observable<Address> {
    return this.occDeliveryAddress.createAndSetAddress(this.getAddressWithCountry(address)).pipe(
      map((response: Address) => {
        this.deliveryAddressRepository.next(response);
        if (!this.activeCartService.isGuestCart()) {
          this.globalMessageService.add(
            { key: 'bossDeliveryAddress.saveAddressMessage' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION,
          );
        }

        return response;
      }),
    );
  }

  getAddressWithCountry(address: Address): Address {
    return {
      ...address,
      country,
    };
  }
}
