<ng-container *ngIf="items?.length">
  <boss-product-carousel-template [quantity]="items.length" [navigation]="{ prev: prevClass, next: nextClass }">
    <swiper [config]="swiperConfig">
      <ng-template swiperSlide *ngFor="let item of items">
        <ng-container *ngIf="item">
          <boss-product-card [product]="item"></boss-product-card>
        </ng-container>
      </ng-template>
    </swiper>
  </boss-product-carousel-template>
  <boss-dialog-wrapper></boss-dialog-wrapper>
</ng-container>
