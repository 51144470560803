import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Product, RoutingService } from '@spartacus/core';
import { bossIconConfig } from '../../utils/boss-icon-config';
import { BossProductVariantColor, BossProductVariantLabel } from '../../models';
import { BossSwatch } from './boss-swatches.model';

@Component({
  selector: 'boss-swatches[product]',
  templateUrl: './boss-swatches.component.html',
  styleUrls: ['./boss-swatches.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossSwatchesComponent implements OnChanges {
  @Input() product: Product;

  @Input() showSwatchLabel = false;

  @Input() anchor = false;

  @Input() redirect = false;

  @Output() changeProduct = new EventEmitter<string>();

  swatches: BossSwatch[] = [];

  swatchTypeLabel: BossProductVariantLabel = BossProductVariantLabel.Color;

  selectedSwatch: BossProductVariantColor;

  maxVisibleSwatches = 3;

  expanded = false;

  bossIconConfig = bossIconConfig;

  constructor(private cdRef: ChangeDetectorRef, private routingService: RoutingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const productChanges = changes.product;

    if (productChanges?.currentValue?.code !== productChanges?.previousValue?.code) {
      this.setSwatches();
    }
  }

  setSwatches(): void {
    const colorVariants = this.product.allVariants?.colorVariants ?? [];

    if (colorVariants?.length) {
      this.selectedSwatch = colorVariants.find((variant: BossProductVariantColor) => variant.selected);

      this.swatches = colorVariants
        .map((item: BossProductVariantColor) => {
          return {
            productUrl: item?.product?.url ?? '',
            productCode: item?.product?.code ?? '',
            selected: item.selected,
            name: item.name,
            media: {
              url: item.colorTileUrl,
              altText: item.name,
            },
            colorHexCode: item.colorHexCode,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    this.cdRef.detectChanges();
  }

  select(productCode: string): void {
    this.changeProduct.emit(productCode);
  }

  showMoreOrRedirect(): void {
    if (this.redirect) {
      this.routingService.go({ cxRoute: 'product', params: this.product });
    } else {
      this.expanded = true;
    }
  }
}
