import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { BossFacetNamePipeModule } from '../facet-name/boss-facet-name-pipe.module';
import { BossActiveFacetsComponent } from './boss-active-facets.component';
import { BossIconDirectiveModule } from '../../../directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    BossIconDirectiveModule,
    KeyboardFocusModule,
    BossFacetNamePipeModule,
  ],
  declarations: [BossActiveFacetsComponent],
  exports: [BossActiveFacetsComponent],
})
export class BossActiveFacetsModule {}
