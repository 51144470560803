export const user = {
  newCustomerLogin: {
    newsletter:
      'Ja, ich möchte per E-Mail-Newsletter über Trends, Aktionen, Gutscheine und mehr informiert werden. Ich bestätige, älter als 16 Jahre zu sein und willige ferner ein, dass das Öffnungs- und Klickverhalten der E-Mail-Newsletter verarbeitet werden darf. Eine Abmeldung ist jederzeit möglich. Mehr zum Datenschutz unter <a href="https://moebel-boss.de/datenschutz" target="_blank">Datenschutzhinweise</a>.',
    register: 'Jetzt registrieren',
    text: {
      header: 'Ich bin neu bei Möbel Boss',
      sectionRequired: 'Pflichtangaben',
      sectionOptional: 'Freiwillige Angaben',
    },
    title: {
      label: 'Anrede',
      labelMs: 'Frau',
      labelMr: 'Herr',
      labelCompany: 'Firma',
      placeholder: 'Anrede',
    },
    // todo: add title translations
    // todo: add model somewhere
    // todo: add logic somewhere
    firstName: {
      label: 'Vorname',
      placeholder: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
      placeholder: 'Nachname',
    },
    dob: {
      label: 'Geburtsdatum',
      placeholder: 'Geburtsdatum',
    },
    email: {
      label: 'E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Passwort',
      validationHint: 'Mindestlänge 6 Zeichen. Muss Zahl, Sonderzeichen, Groß- und Kleinbuchstaben enthalten',
    },
    forgetPassword: {
      newPassword: 'Neues Passwort vergeben',
      repeatPassword: 'Passwort wiederholen',
    },
    mandatoryFields: '*Pflichtfelder',
    passwordconf: {
      label: 'Passwort bestätigen',
      placeholder: 'Passwort bestätigen',
    },
    companyName: {
      label: 'Firma',
      placeholder: 'Firma',
    },
    streetName: {
      label: 'Straße',
      placeholder: 'Straße',
    },
    streetNumber: {
      label: 'Nr.',
      placeholder: 'Nr.',
    },
    additional: {
      label: 'Adresszusatz',
      placeholder: 'Adresszusatz',
    },
    additionalAddress: {
      label: 'Adresszusatz',
      placeholder: 'Adresszusatz',
    },
    floor: {
      label: 'Etage',
      placeholder: 'Etage',
    },
    postCode: {
      label: 'PLZ',
      placeholder: 'PLZ',
    },
    city: {
      label: 'Stadt',
      placeholder: 'Stadt',
    },
    country: {
      label: 'Land',
      placeholder: 'Land',
    },
    phoneNumber: {
      label: 'Telefonnummer',
      placeholder: 'Telefonnummer',
    },
    optIn: {
      success: 'Vielen Dank für die Bestätigung deiner Registrierung. Du kannst dich jetzt einloggen.',
      error: 'Ihre Registierung konnte leider nicht bestätigt werden.',
    },
    registration: {
      confirmation: {
        title:
          'Vielen Dank für Ihre Registrierung. Eine E-Mail zur Aktivierung Ihres Kundenkontos wurde an Sie versendet.',
        subtitle: 'Öffnen Sie die E-Mail in Ihrem Posteingang, um Ihre E-Mail-Adresse zu bestätigen.',
      },
      error: {
        accountExists: {
          title: 'Ein Konto mit dieser E-Mail-Adresse ist bereits vorhanden.',
          subtitle: "Wenn Sie Ihr Kennwort vergessen haben, verwenden Sie den Link 'Kennwort vergessen'.",
        },
      },
    },
  },
  resetPassword: {
    header: 'Passwort aktualisieren',
    advice: 'Bitte füllen Sie alle mit * markierten Felder aus',
    submit: {
      success: 'Dein Passwort wurde erfolgreich geändert. Du kannst dich jetzt mit deinen Daten anmelden.',
      error:
        'Hoppla, da ist etwas schief gelaufen. Bitte versuche es erneut oder starte die Passwortvergessen-Funktion erneut.',
    },
  },
  returningCustomerLogin: {
    signIn: 'Anmelden',
    forgotPassword: 'Passwort vergessen',
    text: {
      header: 'Ich bin bereits Kunde',
      sectionLogin: 'Melden Sie sich hier an',
    },
    email: {
      label: 'E-Mail-Adresse',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Passwort',
    },
  },
  bossForgottenPassword: {
    description:
      'Wir helfen dir. Gib einfach die E-Mail-Adresse aus deiner Registrierung ein. Wir senden dir umgehend einen Link zum Ändern deines Passworts zu.',
    success:
      'Wir haben eine E-Mail an {{email}} versendet. Bitte Klicken Sie den Link in dieser E-Mail. Anschließend können Sie ein neues Passwort wählen.',
    resetPassword: 'Passwort zurücksetzen',
    enterEmailAddressAssociatedWithYourAccount:
      'Geben Sie die E-Mail-Adresse an, die mit Ihrem Benutzerkonto verknüpft ist',
    emailAddress: {
      label: 'E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse',
    },
    dialog: {
      title: 'Passwort vergessen?',
    },
    submit: 'Passwort anfordern',
    close: 'Schließen',
    enterValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    passwordResetEmailSent:
      'Wir haben dir eben eine E-Mail gesendet. Bitte klicke in den darin enthaltenen Link um dein neues Passwort zu vergeben.',
    passwordResetSuccess: 'Erfolg! Sie können sich nun mit Ihrem neuen Passwort anmelden.',
  },
  forgottenPassword: {
    submit: 'Passwort anfordern',
    dialog: {
      title: 'Passwort vergessen?',
    },
    passwordResetEmailSent:
      'Wir haben dir eben eine E-Mail gesendet. Bitte klicke in den darin enthaltenen Link um dein neues Passwort zu vergeben.',
  },
};
