import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BossItemCounterComponent } from './boss-item-counter.component';
import { IconModule } from '@spartacus/storefront';

@NgModule({
  declarations: [BossItemCounterComponent],
  exports: [BossItemCounterComponent],
  imports: [CommonModule, ReactiveFormsModule, IconModule],
})
export class BossItemCounterModule {}
