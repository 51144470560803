import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossImageMoodSliderComponent } from './boss-image-mood-slider.component';
import { SwiperModule } from 'swiper/angular';
import { GenericLinkModule } from '@spartacus/storefront';
import { LinkTargetPipeModule } from '../../../shared/pipes/link-target/link-target.pipe.module';
import { ConfigModule } from '@spartacus/core';
import { BossIconDirectiveModule } from '../../../shared/directives/icon/boss-icon.directive.module';
import { BossMediaDirectiveModule } from '../../../shared/directives/media/boss-media.directive.module';

@NgModule({
  declarations: [BossImageMoodSliderComponent],
  imports: [
    CommonModule,
    SwiperModule,
    BossIconDirectiveModule,
    BossMediaDirectiveModule,
    GenericLinkModule,
    LinkTargetPipeModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ImageMoodSliderComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-shared" */
              './boss-image-mood-slider.component'
            ).then((m) => m.BossImageMoodSliderComponent),
        },
      },
    }),
  ],
})
export class BossImageMoodSliderModule {}
