import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossPriceComponent } from './boss-price.component';
import { I18nModule } from '@spartacus/core';
import { BossSafePipeModule } from '../../pipes/boss-safe-pipe.module';
import { BossPriceTypePipe } from './boss-price-type.pipe';
import { BossProductPriceService } from '../../../features/product/product-price/services/product-price.service';

@NgModule({
  declarations: [BossPriceComponent, BossPriceTypePipe],
  exports: [BossPriceComponent],
  imports: [CommonModule, I18nModule, BossSafePipeModule],
  providers: [BossProductPriceService],
})
export class BossPriceModule {}
