import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData, LinkComponent } from '@spartacus/storefront';
import { CmsLinkComponent, WindowRef } from '@spartacus/core';

@Component({
  selector: 'boss-link',
  templateUrl: './boss-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossLinkComponent extends LinkComponent {
  callback: () => void;

  constructor(component: CmsComponentData<CmsLinkComponent>, private winRef: WindowRef) {
    super(component);

    this.addCallback(this.component.uid);
  }

  addCallback(id: string): void {
    const nativeWindow = this.winRef.nativeWindow;

    if (nativeWindow) {
      if (id === 'CookiesLink') {
        this.callback = (): void => {
          const uc = nativeWindow['UC_UI'];

          if (uc?.showSecondLayer) {
            uc.showSecondLayer();
          }
        };
      }
    }
  }
}
