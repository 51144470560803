import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Product, WindowRef } from '@spartacus/core';
import { EnergyEfficiency } from '../../../../models';

const ALLOWED_HOSTNAMES = ['dev.moebel-boss.de', 'stage.moebel-boss.de', 'moebel-boss.de'];

@Component({
  selector: 'boss-energy-label-modal',
  templateUrl: './boss-energy-label-modal.component.html',
  styleUrls: ['./boss-energy-label-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossEnergyLabelModalComponent {
  @Input() data: { product: Product; energyEfficiency: EnergyEfficiency };

  constructor(@Inject(PLATFORM_ID) private platformId: string, private windowRef: WindowRef) {}

  transformImageUrl(imageUrl): string {
    if (isPlatformBrowser(this.platformId)) {
      const { hostname } = this.windowRef.nativeWindow.location;
      if (ALLOWED_HOSTNAMES.includes(hostname)) return imageUrl;
      return `https://dev.moebel-boss.de${imageUrl}`;
    }
    return imageUrl;
  }
}
