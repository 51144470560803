import { Injectable } from '@angular/core';
import { BadRequestHandler, GlobalMessageType } from '@spartacus/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BossBadRequestHandler extends BadRequestHandler {
  handleError(request: HttpRequest<never>, response: HttpErrorResponse): void {
    super.handleError(request, response);
    this.handleVoucherRedeemedError(request, response);
  }

  protected handleVoucherRedeemedError(_request: HttpRequest<never>, response: HttpErrorResponse): void {
    this.getErrors(response)
      .filter((e) => e.message === 'coupon.already.redeemed')
      .forEach(() => {
        this.globalMessageService.add({ key: 'bossCart.couponRedeemed' }, GlobalMessageType.MSG_TYPE_ERROR);
      });
  }
}
