import { NgModule } from '@angular/core';
import {
  CartAddEntryFailEvent,
  CartAddEntrySuccessEvent,
  CartRemoveEntrySuccessEvent,
  CartUpdateEntrySuccessEvent,
  LoginEvent,
  LogoutEvent,
  OrderPlacedEvent,
  provideConfig,
} from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { BaseTmsModule, TmsConfig } from '@spartacus/tracking/tms/core';
import { GtmModule } from '@spartacus/tracking/tms/gtm';
import {
  BossNewsletterRegistrationEvent,
  BossProductDetailsPageEvent,
  BossUserRegistrationEvent,
  BossWishlistAddEvent,
  BossWishlistRemoveEvent,
  TsCheckoutEvent,
} from '../../shared/events';
import { BossGtmCollectorService } from '../gtm/boss-gtm-collector.service';
import { environment } from '../../../../environments/environment';

@NgModule({
  imports: [BaseTmsModule.forRoot(), GtmModule],
  providers: [
    provideConfig({
      tagManager: {
        gtm: {
          collector: BossGtmCollectorService,
          debug: !environment.production,
          events: [
            // Spartacus out-of-the-box events
            // https://sap.github.io/spartacus-docs/event-service/#list-of-spartacus-events
            CartAddEntrySuccessEvent,
            CartAddEntryFailEvent,
            CartRemoveEntrySuccessEvent,
            CartUpdateEntrySuccessEvent,
            NavigationEvent,
            // Boss custom events
            BossWishlistAddEvent,
            BossWishlistRemoveEvent,
            BossNewsletterRegistrationEvent,
            BossUserRegistrationEvent,
            BossProductDetailsPageEvent,

            // TODO: these are not yet tested
            OrderPlacedEvent,
            LogoutEvent,
            LoginEvent,
            TsCheckoutEvent,
          ],
          gtmId: environment.gtmId,
        },
      },
    } as TmsConfig),
  ],
})
export class TagManagementFeatureModule {}
