import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossSwatchesComponent } from './boss-swatches.component';
import { I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { GenericLinkModule } from '@spartacus/storefront';
import { BossIconDirectiveModule } from '../../directives/icon/boss-icon.directive.module';
import { BossMediaDirectiveModule } from '../../directives/media/boss-media.directive.module';

@NgModule({
  declarations: [BossSwatchesComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    BossIconDirectiveModule,
    GenericLinkModule,
    BossMediaDirectiveModule,
    I18nModule,
  ],
  exports: [BossSwatchesComponent],
})
export class BossSwatchesModule {}
