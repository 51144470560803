import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { BREAKPOINT, BreakpointService, CmsComponentData, MediaContainer } from '@spartacus/storefront';
import { BossContentStageCmsData } from './boss-content-stage-cms-data';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'boss-content-stage',
  templateUrl: './boss-content-stage.component.html',
  styleUrls: ['./boss-content-stage.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossContentStageComponent implements OnInit, OnDestroy {
  data: BossContentStageCmsData;

  media: MediaContainer;

  private onDestroy$ = new Subject<void>();

  constructor(
    private componentData: CmsComponentData<BossContentStageCmsData>,
    private elementRef: ElementRef,
    private render: Renderer2,
    private breakPointService: BreakpointService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.componentData.data$.pipe(takeUntil(this.onDestroy$)).subscribe((data: BossContentStageCmsData) => {
      this.data = data;

      this.setBackgroundColor(data);
    });

    this.breakPointService
      .isDown(BREAKPOINT.sm)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isMobile: boolean) => {
        this.media = isMobile && this.data?.mobileMedia ? this.data.mobileMedia : this.data.media;

        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setBackgroundColor(data: BossContentStageCmsData): void {
    if (data?.colorTheme) {
      this.render.addClass(this.elementRef.nativeElement, data.colorTheme);
    }
  }
}
