import { StoreFinderConfig } from '@spartacus/storefinder/core';
import { environment } from 'src/environments/environment';

export const bossStoreFinderConfig: StoreFinderConfig = {
  googleMaps: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js',
    apiKey: environment.googleMapsApiKey,
    scale: 8,
    selectedMarkerScale: 17,
    radius: 500,
  },
};
