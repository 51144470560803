import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ActiveCartService, Address, Cart } from '@spartacus/core';
import { BossDeliveryAddressRepository } from '../../address/repository/boss-delivery-address.repository';
import { BossDeliveryAddressService } from '../../address/service/boss-delivery-address.service';

@Injectable()
export class BossCheckoutClickAndCollectService {
  constructor(
    private activeCartService: ActiveCartService,
    private deliveryAddressRepository: BossDeliveryAddressRepository,
    private deliveryAddressService: BossDeliveryAddressService,
  ) {}

  isClickAndCollect(): Observable<boolean> {
    return this.activeCartService.getActive().pipe(map((activeCart: Cart) => activeCart?.onlyPickUpEntries));
  }

  onPurgeDeliveryAddress(): Observable<Address> {
    return this.activeCartService.isStable().pipe(
      filter((isStable: boolean) => isStable),
      switchMap(() => {
        return combineLatest([this.isClickAndCollect(), this.deliveryAddressRepository.on()]);
      }),
      switchMap(([isOnlyClickAndCollect, deliveryAddress]: [boolean, Address]) => {
        return isOnlyClickAndCollect && deliveryAddress?.id ? this.deliveryAddressService.remove() : of({});
      }),
    );
  }
}
