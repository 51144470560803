import { NgModule } from '@angular/core';
import { SpartacusFeaturesModule } from './spartacus-features.module';
import { SpartacusConfigurationModule } from './spartacus-configuration.module';
import { CommonModule } from '@angular/common';
import { BossStorefrontComponentModule } from './features/boss-storefront/boss-storefront.module';

@NgModule({
  imports: [CommonModule, SpartacusFeaturesModule, SpartacusConfigurationModule],
  exports: [SpartacusFeaturesModule, BossStorefrontComponentModule],
})
export class SpartacusModule {}
