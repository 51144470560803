export interface BossDYBaseEvent {
  name: string;
  properties?: any; // eslint-disable-line
  value?: number;
}

export enum BossDYCurrency {
  EUR = 'EUR',
}

export enum BossDYContextType {
  HOMEPAGE = 'HOMEPAGE',
  PLP = 'CATEGORY',
  PDP = 'PRODUCT',
  CART = 'CART',
  OTHER = 'OTHER',
}

export enum BossDYEventType {
  ADD_TO_CART = 'add-to-cart-v1',
  ADD_TO_WISHLIST = 'add-to-wishlist-v1',
  KEYWORD_SEARCH = 'keyword-search-v1',
  PURCHASE = 'purchase-v1',
  REMOVE_FROM_CART = 'remove-from-cart-v1',
  SYNC_CART = 'sync-cart-v1',
}

export interface BossDYWindow extends Window {
  /* eslint-disable */
  DY: {
    API: any;
    UserActiveConsent: any;
    recommendationContext: any;
  };
  DYO: {
    ActiveConsent: any;
  };
  DY_categories?: string[];
  DY_cart?: any;
  /* eslint-enable */
}
