<ng-container *ngIf="categoryTree">
  <div class="sub-navigation">
    <div class="sub-navigation__list-wrapper">
      <ul *ngFor="let cat of categoryTree.categories" class="sub-navigation__level1">
        <li>
          <ul *ngFor="let subCat of cat.subCategories" class="sub-navigation__level2">
            <li class="category">
              <a
                [routerLink]="subCat.url"
                [class.is-active]="currentCategory === subCat.code"
                class="small-copy-bold"
                >{{ subCat.name }}</a
              >
              <ul *ngFor="let subSubCat of subCat.subCategories" class="sub-navigation__level3">
                <li>
                  <a
                    [routerLink]="subSubCat.url"
                    [class.is-active]="currentCategory === subSubCat.code"
                    class="small-copy"
                    >{{ subSubCat.name }}</a
                  >
                  <ul *ngFor="let subSubSubCat of subSubCat.subCategories" class="sub-navigation__level3">
                    <li>
                      <a
                        [routerLink]="subSubSubCat.url"
                        [class.is-active]="currentCategory === subSubSubCat.code"
                        class="small-copy"
                        >{{ subSubSubCat.name }}</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
