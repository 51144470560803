import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossPillsComponent } from './boss-pills.component';
import { provideDefaultConfig } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { SwiperModule } from 'swiper/angular';
import { BossPillComponent } from './pill/boss-pill.component';
import { BossTextLimitPipeModule } from '../../pipes/text-limit/boss-text-limit-pipe.module';
import { BossIconDirectiveModule } from '../../directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [BossPillsComponent, BossPillComponent],
  imports: [CommonModule, GenericLinkModule, BossIconDirectiveModule, BossTextLimitPipeModule, SwiperModule],
  providers: [
    provideDefaultConfig({
      cmsComponents: {
        BossPillContainerComponent: {
          component: BossPillsComponent,
        },
      },
    }),
  ],
})
export class BossPillsModule {}
