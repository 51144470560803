<a bossSetActiveState elementUrl="favorites" [routerLink]="'favorites'" class="wishlist">
  <i bossIcon class="wishlist-icon" [type]="bossIconConfig.EMPTY_HEART" aria-hidden="true">
    <ng-container *ngIf="wishlist$ | async as wishlist">
      <span class="count" *ngIf="wishlist?.entries?.length > 0">
        {{ wishlist?.entries?.length || '0' }}
      </span>
    </ng-container>
  </i>
  <span class="title">{{ 'bossWishListIcon.title' | cxTranslate }}</span>
</a>
