import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideDefaultConfig } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { BossBannerAutomaticCarouselComponent } from './banner-automatic-carousel.component';
import { BossCarouselBaseModule } from '../../shared/components/boss-carousel-base/boss-carousel-base.module';
import { BossMediaDirectiveModule } from '../../shared/directives/media/boss-media.directive.module';

@NgModule({
  imports: [CommonModule, GenericLinkModule, BossMediaDirectiveModule, BossCarouselBaseModule],
  providers: [
    provideDefaultConfig({
      cmsComponents: {
        RotatingImagesComponent: {
          component: BossBannerAutomaticCarouselComponent,
        },
        CMSResponsiveRotatingImagesComponent: {
          component: BossBannerAutomaticCarouselComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [BossBannerAutomaticCarouselComponent],
  entryComponents: [BossBannerAutomaticCarouselComponent],
  exports: [BossBannerAutomaticCarouselComponent],
})
export class BossBannerAutomaticCarouselComponentModule {}
