import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { WindowRef } from '@spartacus/core';
import { Observable, of, timer } from 'rxjs';
import { BossScrollTo } from './boss-scroll-to';

@Injectable()
export class BossScrollPositionService {
  static factory(scrollPositionService: BossScrollPositionService): () => void {
    return (): void => {
      scrollPositionService.initScrollPositionRestoration();
    };
  }

  constructor(private router: Router, private windowRef: WindowRef) {}

  private initScrollPositionRestoration(): void {
    if (this.windowRef.isBrowser()) {
      this.router.events
        .pipe(
          filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
          distinctUntilChanged((prevEvent: RouterEvent, currEvent: RouterEvent) =>
            this.compareURL(prevEvent.url, currEvent.url),
          ),
          switchMap((routerEvent: RouterEvent) => {
            return this.hasHash(routerEvent) ? this.onScrollToAnchor() : of(BossScrollTo.TOP);
          }),
        )
        .subscribe((scrollTo: BossScrollTo) => {
          this.scrollTo(scrollTo);
        });
    }
  }

  private scrollTo(scrollTo: BossScrollTo): void {
    switch (scrollTo) {
      case BossScrollTo.TOP: {
        this.scrollToTop();
        break;
      }
      case BossScrollTo.ANCHOR: {
        this.scrollToAnchor();
        break;
      }
      default: {
        this.scrollToTop();
      }
    }
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  private scrollToAnchor(): void {
    const regex = /^(?!\d+$).*/;
    const id = this.router.url.split('#')[1],
      isValid = regex.test(id),
      anchorEl = isValid ? document.querySelector(`#${id}`) : '';

    if (anchorEl) {
      anchorEl.scrollIntoView();
    }
  }

  private onScrollToAnchor(): Observable<BossScrollTo> {
    return timer(3000).pipe(map(() => BossScrollTo.ANCHOR));
  }

  private compareURL(prevUrl: string, currUrl: string): boolean {
    const prevUrlBase = prevUrl.split('?')[0],
      currUrlBase = currUrl.split('?')[0];
    return prevUrlBase === currUrlBase;
  }

  private hasHash(routerEvent: RouterEvent): boolean {
    return routerEvent.url.includes('#');
  }
}
