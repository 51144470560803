import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BossStoreAvailabilityService } from './store-availability.service';
import {
  BossStoreAvailability,
  BossStoreAvailabilityProduct,
  PointOfServiceWithStock,
} from './store-availability.model';
import { ITEMS_PER_LOAD } from './store-availability.constants';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BossActiveCartService } from '../../../product/product-add-to-cart/active-cart.service';
import { BossPointOfService } from '../../../../shared/models';
import { BossDialogService } from '../../../../shared/components/dialog/boss-dialog.service';
import { BossDYEventType } from '../../../dynamic-yield/model';
import { BossDynamicYieldService } from '../../../dynamic-yield/boss-dy.service';

@Component({
  selector: 'boss-store-availability',
  templateUrl: './store-availability.component.html',
  styleUrls: ['./store-availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreAvailabilityComponent implements OnInit, OnDestroy {
  pointOfServices: PointOfServiceWithStock[] = [];

  storesToDisplay: number = ITEMS_PER_LOAD;

  storeAvailabilityForm: FormGroup = this.fb.group({
    selectedStoreControl: ['', Validators.required],
  });

  storeSearchForm: FormGroup = this.fb.group({
    queryControl: ['', Validators.required],
  });

  showOnlyAvailable = false;

  isLoading = true;

  private productDetails: BossStoreAvailabilityProduct;

  private onDestroy$ = new Subject<void>();

  constructor(
    private storeAvailabilityService: BossStoreAvailabilityService,
    private dialogService: BossDialogService,
    private fb: FormBuilder,
    private globalMessageService: GlobalMessageService,
    private bossActiveCartService: BossActiveCartService,
    private cdRef: ChangeDetectorRef,
    private dynamicYieldService: BossDynamicYieldService,
  ) {}

  ngOnInit(): void {
    this.setMarketAvailability();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  submitAndClose(): void {
    if (this.storeAvailabilityForm.valid) {
      this.addToCartWithStore();
      this.dialogService.close();
    } else {
      this.storeAvailabilityForm.markAllAsTouched();
    }
  }

  trackByName(index: number, item: BossPointOfService): string {
    return item?.name;
  }

  loadMore(): void {
    this.storesToDisplay += ITEMS_PER_LOAD;
  }

  selectStore(name): void {
    this.storeAvailabilityForm.get('selectedStoreControl').patchValue(name);
  }

  toggle(): void {
    this.showOnlyAvailable = !this.showOnlyAvailable;
    this.searchStores();
  }

  searchStores(): void {
    const query: string = this.storeSearchForm.get('queryControl')?.value;
    this.setMarketAvailability(query);
  }

  private addToCartWithStore(): void {
    const { code, quantity, price } = this.productDetails;
    this.globalMessageService.add({ key: 'addToCart.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
    this.bossActiveCartService.addEntryWithStore(
      code,
      quantity,
      this.storeAvailabilityForm.get('selectedStoreControl')?.value,
    );

    this.dynamicYieldService.triggerEvent({
      name: 'Add to cart',
      properties: {
        dyType: BossDYEventType.ADD_TO_CART,
        productId: code,
        quantity,
        value: price * quantity,
      },
    });
  }

  private setMarketAvailability(query?: string): void {
    combineLatest([this.storeAvailabilityService.getStoreAvailability$, this.getStores(query)])
      .pipe(
        map(([marketAvailability, allStores]) => this.getMappedStores(marketAvailability, allStores)),
        map((stores: PointOfServiceWithStock[]) => {
          return this.showOnlyAvailable ? stores.filter((store) => store?.stockInfo?.stockLevel) : stores;
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((stores: PointOfServiceWithStock[]) => {
        this.pointOfServices = stores;
        this.isLoading = false;
        this.cdRef.detectChanges();
      });
  }

  private getMappedStores(
    storesAvailability: BossStoreAvailability[],
    allStores: BossPointOfService[],
  ): PointOfServiceWithStock[] {
    return allStores.reduce((acc, val) => {
      const posWithStock: PointOfServiceWithStock = storesAvailability.find(
        (store: BossPointOfService): boolean => store.name === val.name,
      );
      const posWithoutStock: PointOfServiceWithStock = { ...val, stockInfo: { stockLevel: 0 } };

      return [...acc, posWithStock ? { ...val, ...posWithStock } : posWithoutStock];
    }, []);
  }

  private getStores(query?: string): Observable<BossPointOfService[]> {
    return this.getProductDetails().pipe(
      switchMap((productDetails) => {
        return this.storeAvailabilityService.fetchStores(productDetails?.code, query);
      }),
    );
  }

  private getProductDetails(): Observable<BossStoreAvailabilityProduct> {
    return this.storeAvailabilityService.getProductDetails$.pipe(
      tap((productDetails) => (this.productDetails = productDetails)),
    );
  }
}
