import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Product } from '@spartacus/core';
import {
  ICON_TYPE,
  ProductGridItemComponent,
  ProductListItemContext,
  ProductListItemContextSource,
} from '@spartacus/storefront';
import { BehaviorSubject, Subject } from 'rxjs';
import { BossWishlistService } from '../../../wishlist/wishlist.service';
import { BossProductVariantColor } from '../../../../shared/models';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { bossIconConfig } from '../../../../shared/utils/boss-icon-config';

@Component({
  selector: 'boss-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class ProductCardComponent extends ProductGridItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() maxEnergyLabels = 4;

  @Input() product: Product;

  iconTypes = ICON_TYPE;

  isOnWishlist = false;

  initialProductCode: string;

  bossIconConfig = bossIconConfig;

  private product$: BehaviorSubject<Product> = new BehaviorSubject(null);

  private onDestroy$ = new Subject<void>();

  constructor(
    productListItemContextSource: ProductListItemContextSource,
    private cdRef: ChangeDetectorRef,
    private wishlistService: BossWishlistService,
  ) {
    super(productListItemContextSource);
  }

  ngOnInit(): void {
    this.product$.next(this.product);

    this.product$
      .pipe(
        mergeMap((product: Product) => this.wishlistService.isProductOnWishlist(product)),
        takeUntil(this.onDestroy$),
      )
      .subscribe((isOn: boolean) => {
        this.isOnWishlist = isOn;

        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.setInitialProductCode(changes);
  }

  setInitialProductCode(changes: SimpleChanges): void {
    const productChanges = changes.product;

    if (productChanges?.firstChange) {
      this.initialProductCode = productChanges.currentValue.code;
    }
  }

  updateProduct(productCode: string): void {
    if (this.product.code === productCode) return;

    const colorVariants = this.product.allVariants?.colorVariants;
    if (!colorVariants) return;

    const selectedVariant: BossProductVariantColor = colorVariants.find(
      (variant: BossProductVariantColor) => variant.product.code === productCode,
    );

    const updatedColorVariants = colorVariants.map((variant: BossProductVariantColor) => {
      const updatedVariant = { ...variant };
      updatedVariant.selected = variant.product.code === productCode;
      return updatedVariant;
    });

    const productNameAndCode = selectedVariant.product.url.replace('/p/', '');

    this.product = {
      ...this.product,
      // Price Update
      priceDisplayType: selectedVariant.product.priceDisplayType,
      price: selectedVariant.product.price,
      crossedPrice: selectedVariant.product.crossedPrice,
      // Name Update
      title: selectedVariant.product.title,
      // Other
      code: selectedVariant.product.code,
      // Url Update
      productNameAndCode: productNameAndCode,
      // Color Swatches Update
      allVariants: {
        ...this.product.allVariants,
        colorVariants: updatedColorVariants,
      },
      // Images Update
      images: selectedVariant.product.images,
      hoverImage: selectedVariant.product.hoverImage,
    };

    this.isOnWishlist = false;
    this.product$.next(this.product);
  }

  toggleFromWishlist(): void {
    this.wishlistService.toggleFromWishlist(this.product.code, this.isOnWishlist);
  }
}
