import { Component, Input } from '@angular/core';
import { bossIconConfig } from '../../../shared/utils/boss-icon-config';
import { ProductDocument } from '../../../shared/models';

@Component({
  selector: 'boss-document-item',
  templateUrl: './boss-document-item.component.html',
  styleUrls: ['./boss-document-item.component.scss'],
})
export class BossDocumentItemComponent {
  @Input() document: ProductDocument;

  bossIconConfig = bossIconConfig;

  get documentType(): string {
    return this.document?.mime === 'application/pdf' ? 'PDF' : '';
  }
}
