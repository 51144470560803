import { Injectable } from '@angular/core';
import { RoutingService, TranslationService, WindowRef, EventService } from '@spartacus/core';
import { SearchBoxComponentService, SearchBoxConfig } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BossSearchboxService } from './boss-search-box.service';
import { BossOccSuggestions, BossSearchResults } from './boss-search-box.model';

const HAS_SEARCH_RESULT_CLASS = 'has-searchbox-results';

@Injectable({
  providedIn: 'root',
})
export class BossSearchBoxComponentService extends SearchBoxComponentService {
  constructor(
    routingService: RoutingService,
    translationService: TranslationService,
    winRef: WindowRef,
    eventService: EventService,
    private bossSearchService: BossSearchboxService,
  ) {
    super(bossSearchService, routingService, translationService, winRef, eventService);
  }

  bossGetResults(config: SearchBoxConfig, forceEmpty?: boolean): Observable<BossSearchResults> {
    return this.getProductBossSuggestions(config).pipe(
      map(
        (suggestions): BossSearchResults => ({
          ...suggestions,
          bossSuggestions: suggestions?.suggestions?.suggestions?.map((suggestion) => suggestion?.value),
        }),
      ),
      tap((results: BossSearchResults) => this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, this.bossHasResults(results))),
    );
  }

  clearResults(): void {
    this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, false);
    this.bossSearchService.forceEmptyResults();
  }

  search(query: string, config: SearchBoxConfig): void {
    if (!query || query === '') {
      this.clearResults();

      return;
    }

    if (config.minCharactersBeforeRequest && query.length < config.minCharactersBeforeRequest) {
      return;
    }

    if (config.displaySuggestions) {
      this.searchService.searchSuggestions(query, {
        pageSize: config.maxSuggestions,
      });
    }
  }

  private bossHasResults(results: BossSearchResults): boolean {
    return results?.products?.length > 0 || results?.bossSuggestions?.length > 0;
  }

  private getProductBossSuggestions(config: SearchBoxConfig): Observable<BossOccSuggestions> {
    if (!config.displaySuggestions) {
      return of();
    } else {
      return this.bossSearchService.bossGetSuggestionResults();
    }
  }
}
