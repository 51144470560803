import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductHighlightTileComponent } from './product-highlight-tile.component';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { MediaModule } from '@spartacus/storefront';
import { WishlistButtonComponent } from './components/wishlist-button/wishlist-button.component';
import { ClampTextDirective } from './directives/clamp-text.directive';
import { BossPriceModule } from '../../shared/components/boss-price/boss-price.module';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    UrlModule,
    I18nModule,
    BossPriceModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductTileHighlight: {
          component: () =>
            import(
              /* webpackChunkName: "boss-product" */
              './product-highlight-tile.component'
            ).then((m) => m.ProductHighlightTileComponent),
        },
      },
    }),
  ],
  declarations: [ProductHighlightTileComponent, WishlistButtonComponent, ClampTextDirective],
})
export class ProductHighlightTileModule {}
