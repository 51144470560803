import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Facet, FacetValue, OccConfig } from '@spartacus/core';
import { FacetService } from '@spartacus/storefront';
import { BossFilterCodes } from '../boss-filter-codes';

@Component({
  selector: 'boss-facet-checkbox',
  templateUrl: './boss-facet-checkbox.component.html',
  styleUrls: ['./boss-facet-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossFacetCheckboxComponent implements OnInit {
  @Input() facet: Facet;

  @Input() initialCount = 5;

  visibleFacets: number = this.initialCount;

  bossFilterCodes = BossFilterCodes;

  imageBaseUrl: string = '';

  constructor(private config: OccConfig, private facetService: FacetService, private cdRef: ChangeDetectorRef) {
    this.imageBaseUrl = this.config.backend?.media?.baseUrl || this.config.backend?.occ?.baseUrl;
  }

  ngOnInit(): void {
    // If any facets are selected, show all of them
    if (this.facet?.values?.some((value: FacetValue) => value?.selected)) {
      this.showMore();
    }
  }

  getLinkParams(value: FacetValue): { [key: string]: string } {
    return this.facetService.getLinkParams(value.query?.query?.value);
  }

  getName(facetValue: FacetValue): string {
    return this.facet?.code === BossFilterCodes.COLOR ? facetValue?.colorName : facetValue?.name;
  }

  showMore(): void {
    this.visibleFacets = this.facet?.values?.length;
    this.cdRef.detectChanges();
  }

  showLess(): void {
    this.visibleFacets = this.initialCount;
    this.cdRef.detectChanges();
  }
}
