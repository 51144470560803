import { Injectable } from '@angular/core';
import { BossAddressRepository } from './boss-address-repository';
import { BossOccBillingAddressService } from '../occ/boss-occ-billing-address.service';
import { Observable } from 'rxjs';
import { Address } from '@spartacus/core';

@Injectable()
export class BossBillingAddressRepository extends BossAddressRepository {
  constructor(private occBillingAddressService: BossOccBillingAddressService) {
    super();
  }

  getAddressFromApi(): Observable<Address> {
    return this.occBillingAddressService.getBillingAddress();
  }
}
