export enum bossIconConfig {
  CART = 'ri-shopping-cart-line',
  ARROW_UP = 'ri-arrow-up-line',
  ARROW_LEFT = 'ri-arrow-left-line',
  ARROW_RIGHT = 'ri-arrow-right-line',
  SEARCH = 'ri-search-line',
  STAR = 'ri-star-fill',
  CARET_UP = 'ri-arrow-up-s-line',
  CARET_DOWN = 'ri-arrow-down-s-line',
  CARET_RIGHT = 'ri-arrow-right-s-line',
  CARET_LEFT = 'ri-arrow-left-s-line',
  SUCCESS = 'ri-checkbox-circle-fill',
  CLOSE = 'ri-close-line',
  COLLAPSE = 'ri-subtract-fill',
  EXPAND = 'ri-add-fill',
  RESET = 'ri-close-circle-fill',
  CIRCLE = 'ri-checkbox-blank-circle-fill',
  HEART = 'ri-heart-fill',
  EMPTY_HEART = 'ri-heart-line',
  FILTER = 'ri-equalizer-line',
  ACTIVE = 'ri-check-fill',
  ON = 'ri-toggle-fill',
  OFF = 'ri-toggle-line',
  LINK_OUT = 'ri-external-link-fill',
  SORT_DOWN = 'ri-arrow-down-s-fill',
  SORT = 'ri-sort-desc',
  HOME = 'ri-home-line',
  ARROW_DROP_RIGHT = 'ri-arrow-drop-right-line',
  MAP_PIN = 'ri-map-pin-2-line',
  FILE_COPY = 'ri-file-copy-line',
  CHECKBOX_CIRCLE_LINE = 'ri-checkbox-circle-line',
  PHONE = 'ri-phone-line',
  MAIL_SEND = 'ri-mail-send-line',
  USER = 'ri-user-3-line',
  HEART_3_FILL = 'ri-heart-3-fill',
  HEART_3 = 'ri-heart-3-line',
  EYE_OFF = 'ri-eye-off-line',
  EYE = 'ri-eye-line',
  ADD = 'ri-add-line',
  INFORMATION = 'ri-information-line',
  SUBTRACT = 'ri-subtract-line',
  STAR_LINE = 'ri-star-line',
  STAR_HALF_LINE = 'ri-star-half-line',
  PDF_FILE = 'ri-file-pdf-line',
  FILE_DOWNLOAD = 'ri-file-download-line',
  USER_3_FILL = 'ri-user-3-fill',
  ADMIN = 'ri-admin-line',
  BANK = 'ri-bank-line',
  MAIL = 'ri-mail-line',
  ARCHIVE = 'ri-archive-line',
  CALENDAR_CHECK = 'ri-calendar-check-line',
  FLAG = 'ri-flag-line',
  GLOBAL = 'ri-global-line',
  SEND_PLANE = 'ri-send-plane-line',
  STACK = 'ri-stack-line',
  LOGOUT_BOX = 'ri-logout-box-r-line',
  CHECK = 'ri-check-line',
}
