import { Cart, MULTI_CART_DATA, StateUtils } from '@spartacus/core';
import { GiftCardApplyRequest } from '../../../shared/models';

export const CART_ADD_ENTRY_WITH_STORE = '[Cart-entry] Add Entry With Store';

export const CART_UPDATE_ENTRY_WITH_RETURN_AMOUNT = '[Cart-entry] Update Entry With Return Amount';

export const GIFT_CARD_ADD_SUCCESS = '[Cart] Gift Card add Success';
export const GIFT_CARD_ADD_FAIL = '[Cart] Gift Card add Fail';
export const GIFT_CARD_REMOVE_SUCCESS = '[Cart] Gift Card Remove Success';
export const GIFT_CARD_REMOVE_ALL_SUCCESS = '[Cart] Gift Card Remove All Success';

export const GIFT_CARD_ADD = '[Cart] Gift Card add ';
export const GIFT_CARD_REMOVE = '[Cart] Gift Card Remove';
export const GIFT_CARD_REMOVE_ALL = '[Cart] Gift Card Remove All';

export class CartAddEntryWithStore extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CART_ADD_ENTRY_WITH_STORE;
  constructor(
    public payload: {
      cartId: string;
      userId: string;
      productCode: string;
      pickupStore: string;
      quantity: number;
    },
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class CartUpdateEntryWithReturnAmount extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CART_UPDATE_ENTRY_WITH_RETURN_AMOUNT;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      entryNumber: string;
      quantity: number;
      returnAmount: number;
    },
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class GiftCardAddSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = GIFT_CARD_ADD_SUCCESS;
  constructor(public payload: { cart: Cart; cartId: string }) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class GiftCardAddFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = GIFT_CARD_ADD_FAIL;
  constructor(
    // eslint-disable-next-line
    public payload: { error: any; cartId: string; userId: string; giftCardApplyRequest: GiftCardApplyRequest },
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class GiftCardRemoveSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = GIFT_CARD_REMOVE_SUCCESS;
  constructor(public payload: { cart: Cart; cartId: string }) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}
export class GiftCardRemoveAllSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = GIFT_CARD_REMOVE_ALL_SUCCESS;
  constructor(public payload: { cart: Cart; cartId: string }) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class GiftCardAdd extends StateUtils.EntityProcessesIncrementAction {
  readonly type = GIFT_CARD_ADD;
  constructor(
    public payload: {
      cartId: string;
      userId: string;
      giftCardApplyRequest: GiftCardApplyRequest;
    },
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}
export class GiftCardRemove extends StateUtils.EntityProcessesIncrementAction {
  readonly type = GIFT_CARD_REMOVE;
  constructor(
    public payload: {
      cartId: string;
      userId: string;
      giftcard: string;
    },
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}
export class GiftCardRemoveAll extends StateUtils.EntityProcessesIncrementAction {
  readonly type = GIFT_CARD_REMOVE_ALL;
  constructor(
    public payload: {
      cartId: string;
      userId: string;
    },
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}
