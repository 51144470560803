import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { BossStoreDropdownComponent } from './boss-store-dropdown.component';
import { BossDropdownComponentModule } from '../../../shared/components/dropdown/boss-dropdown.module';

@NgModule({
  declarations: [BossStoreDropdownComponent],
  imports: [CommonModule, BossDropdownComponentModule, I18nModule],
  exports: [BossStoreDropdownComponent],
})
export class BossStoreDropdownComponentModule {}
