import { NgModule } from '@angular/core';
import { JSONLD_PRODUCT_BUILDER, ProductSchemaBuilder, SCHEMA_BUILDER } from '@spartacus/storefront';
import { BossBreadcrumbSchemaBuilder } from './boss-breadcrumb-schema.builder';
import { BossJsonLdBaseProductBuilder } from './boss-json-ld-base-product.builder';
import { BossJsonLdProductOfferBuilder } from './boss-json-ld-product-offer.builder';
import { BossImageConverterModule } from '../../../shared/utils/image-converter/boss-image-converter.module';

@NgModule({
  imports: [BossImageConverterModule],
  providers: [
    {
      provide: SCHEMA_BUILDER,
      useExisting: ProductSchemaBuilder,
      multi: true,
    },
    {
      provide: SCHEMA_BUILDER,
      useExisting: BossBreadcrumbSchemaBuilder,
      multi: true,
    },
    // lower level json-ld builder classes offering fine-grained control
    // for product related schemas
    {
      provide: JSONLD_PRODUCT_BUILDER,
      useExisting: BossJsonLdBaseProductBuilder,
      multi: true,
    },
    {
      provide: JSONLD_PRODUCT_BUILDER,
      useExisting: BossJsonLdProductOfferBuilder,
      multi: true,
    },
  ],
})
export class BossJsonLdBuilderModule {}
