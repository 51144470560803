import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActiveCartService, getCartIdByUserId, UserIdService } from '@spartacus/core';
import { combineLatest, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BuyBoxCurrentZipService } from '../buy-box/buy-box-current-zip.service';
import { BossStockService } from '../../../../shared/services/stock.service';
import { BossDialogService } from '../../../../shared/components/dialog/boss-dialog.service';

@Component({
  selector: 'boss-product-post-code-dialog',
  templateUrl: './product-post-code-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossProductPostCodeModalComponent implements OnDestroy {
  postcode = '';

  isLoading = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private bossStockService: BossStockService,
    private userIdService: UserIdService,
    private activeCartService: ActiveCartService,
    private currentZipCodeService: BuyBoxCurrentZipService,
    private dialogService: BossDialogService,
    private cdRef: ChangeDetectorRef,
  ) {}

  submitPostCode(postCode: string): void {
    this.isLoading = true;

    combineLatest([this.activeCartService.requireLoadedCart(), this.userIdService.getUserId()])
      .pipe(
        switchMap(([cartState, userId]) => {
          const cartId = getCartIdByUserId(cartState.value, userId);

          return this.bossStockService.addZipToCart(userId, cartId, postCode);
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => {
        this.isLoading = false;

        this.currentZipCodeService.next(postCode);
        this.dialogService.close();

        this.cdRef.detectChanges();
      });
  }

  get validPostCode(): boolean {
    return !(this.postcode?.replace(/\D/g, '')?.length < 5 || this.postcode < '01067');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
