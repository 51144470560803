export const PspRoutes = [
  {
    path: 'psp',
    children: [
      {
        path: 'saferpay/accepted',
        // eslint-disable-next-line
        loadChildren: () => import('./safer-pay-success/safer-pay-success.module').then((m) => m.SaferPaySuccessModule),
      },
      {
        path: 'giftcard/accepted',
        // eslint-disable-next-line
        loadChildren: () => import('./gift-card-success/gift-card-success.module').then((m) => m.GiftCardSuccessModule),
      },
      {
        path: 'klarna/accepted',
        // eslint-disable-next-line
        loadChildren: () => import('./klarna-success/klarna-success.module').then((m) => m.KlarnaSuccessModule),
      },
      {
        path: 'payever/accepted',
        // eslint-disable-next-line
        loadChildren: () => import('./pay-ever-success/pay-ever-success.module').then((m) => m.PayEverSuccessModule),
      },
      {
        path: 'prepaid/accepted',
        // eslint-disable-next-line
        loadChildren: () => import('./prepaid-success/prepaid-success.module').then((m) => m.PrepaidSuccessModule),
      },
      {
        path: 'fail',
        // eslint-disable-next-line
        loadChildren: () => import('./psp-fail/psp-fail.module').then((m) => m.PspFailModule),
      },
    ],
  },
];
