import { IconConfig, IconResourceType } from '@spartacus/storefront';
import { BOSS_CUSTOM_ICON_TYPE } from './boss-icon-type';
import { iconConfig } from '../configuration/boss-icon-config';
import { ENERGY_LABEL_TYPE } from './boss-energy-label-type';
import { PROFILE_ICON_TYPE } from './boss-profile-icon-type';

const bossCustomIconSymbols: { [ICON_TYPE: string]: string } = {};
Object.keys(BOSS_CUSTOM_ICON_TYPE).forEach((item) => {
  bossCustomIconSymbols[item] = item.toLowerCase();
});

const energyLabelSymbols: { [ICON_TYPE: string]: string } = {}; // eslint-disable-line
Object.keys(ENERGY_LABEL_TYPE).forEach((item) => {
  energyLabelSymbols[item] = item.toLowerCase();
});

const profileSymbols: { [ICON_TYPE: string]: string } = {};
Object.keys(PROFILE_ICON_TYPE).forEach((item) => {
  profileSymbols[item] = item.toLowerCase();
});

export const bossIconConfig: IconConfig = {
  icon: {
    symbols: {
      ...bossCustomIconSymbols,
      ...energyLabelSymbols,
      ...profileSymbols,
      ...iconConfig.icon.symbols,
    },
    resources: [
      {
        type: IconResourceType.SVG,
        url: './assets/icons/icons.svg',
        types: Object.keys(bossCustomIconSymbols),
      },
      {
        type: IconResourceType.SVG,
        url: './assets/icons/energy-labels.svg',
        types: Object.keys(energyLabelSymbols),
      },
      {
        type: IconResourceType.SVG,
        url: './assets/icons/profile-icons.svg',
        types: Object.keys(profileSymbols),
      },
    ],
  },
};
