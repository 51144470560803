import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { PAGE_LAYOUT_HANDLER } from '@spartacus/storefront';
import { OrderConfirmationPageLayoutHandler } from './components/order-confirmation/order-conformation-layout-handler';
import { PspRoutes } from './components/psp/psp-routes';

@NgModule({
  imports: [RouterModule.forChild(PspRoutes)],
  providers: [
    provideConfig({
      featureModules: {
        'boss-order-module': {
          module: () =>
            import(
              /* webpackChunkName: "boss-order" */
              './components/order-confirmation/order-confirmation.module'
            ).then((m) => m.BossOrderConfirmationComponentModule),
          cmsComponents: ['OrderConfirmationFlexComponent'],
        },
      },
    } as CmsConfig),
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: OrderConfirmationPageLayoutHandler,
      multi: true,
    },
  ],
})
export class BossOrderFeatureModule {}
