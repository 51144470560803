import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveCartService, Address, Cart, OccEndpointsService, UserIdService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

const BILLING_PARAMS = 'billingAddress(FULL)';

@Injectable()
export class BossOccBillingAddressService {
  constructor(
    private http: HttpClient,
    private activeCartService: ActiveCartService,
    private userIdService: UserIdService,
    private occEndpoints: OccEndpointsService,
  ) {}

  getBillingAddress(): Observable<Address> {
    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()]).pipe(
      switchMap(([occUserId, activeCartId]) => {
        const url = this.occEndpoints.buildUrl(`users/${occUserId}/carts/${activeCartId}/`);
        const params = new HttpParams({
          fromString: `fields=${BILLING_PARAMS}`,
        });

        return this.http.get(url, { params }).pipe(
          map((cartDetails: Cart) => {
            const address: Address = cartDetails['billingAddress'] ?? {};
            return address;
          }),
        );
      }),
    );
  }

  updateBillingAddress(billingAddressId: string, address?: Address): Observable<Address> {
    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()]).pipe(
      switchMap(([occUserId, activeCartId]) => {
        const url = this.occEndpoints.buildUrl('createUpdateBillingAddress', {
          urlParams: {
            occUserId,
            activeCartId,
          },
        });
        return this.http.post(url, address || {}, { params: { addressId: billingAddressId } });
      }),
    );
  }

  createAndSetAddress(billingAddress: Address): Observable<Address> {
    billingAddress.billingAddress = true;

    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()]).pipe(
      switchMap(([occUserId, activeCartId]) => {
        const url = this.occEndpoints.buildUrl('createUpdateBillingAddress', {
          urlParams: {
            occUserId,
            activeCartId,
          },
        });
        return this.http.post(url, billingAddress);
      }),
    );
  }

  remove(): Observable<Address> {
    return combineLatest([this.userIdService.getUserId(), this.activeCartService.getActiveCartId()]).pipe(
      switchMap(([occUserId, activeCartId]: [string, string]) => {
        const orderEndpoint = 'users/' + occUserId + '/carts/';
        const url = `${this.occEndpoints.getEndpoint(orderEndpoint)}${activeCartId}/addresses/billing`;
        return this.http.delete<Address>(url);
      }),
    );
  }
}
