import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, ProductSearchPage, WindowRef } from '@spartacus/core';
import { FacetList } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { BossProductListComponentService } from '../services/product-list-component.service';
import { takeUntil } from 'rxjs/operators';
import { ProductCardComponent } from '../product-card/product-card.component';
import { BossWindowWithDataLayer } from '../../../../shared/models/boss-window-data-layer';
import { BossStorageKeys } from '../../../../shared/utils/boss-constants';

@Component({
  selector: 'boss-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossProductListComponent implements OnInit, OnDestroy {
  @ViewChildren(ProductCardComponent, { read: ElementRef }) productCards!: QueryList<ElementRef>;

  model: ProductSearchPage;

  facetList?: FacetList;

  private initalized = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private bossProductListComponentService: BossProductListComponentService,
    private winRef: WindowRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.bossProductListComponentService.model$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((model: ProductSearchPage) => {
        this.model = model;

        this.handleNoResults(model);
        this.handleDataLayer(model);

        this.initalized = true;
        this.facetList = {
          facets: model.facets,
          activeFacets: model.breadcrumbs,
        };

        this.cdRef.detectChanges();

        this.scrollToSelectedProduct();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  sortList(sortCode: string): void {
    this.bossProductListComponentService.sort(sortCode);
  }

  productListTracker(index: number, product: Product): string {
    return product.code;
  }

  clickOnProductCard(productIndex: number): void {
    if (this.winRef.isBrowser()) {
      this.winRef.localStorage.setItem(BossStorageKeys.SELECTED_PRODUCT_ID, productIndex.toString());
    }
  }

  private handleNoResults(model: ProductSearchPage): void {
    if (model.products.length === 0 && this.initalized && this.activatedRoute.routeConfig.data.pageLabel == 'search') {
      this.router.navigate(['/searchEmpty'], { replaceUrl: true });
    }
  }

  private handleDataLayer(model: ProductSearchPage): void {
    if (model && this.winRef.isBrowser()) {
      const dataLayer = (this.winRef.nativeWindow as BossWindowWithDataLayer).dataLayer || [];
      const queryParts = model.currentQuery?.query?.value?.split(':');
      const potentialCategoryId = queryParts[queryParts.length - 1];
      const isCategoryNumber = potentialCategoryId.match(/[0-9]+/);

      if (model.products?.length > 0) {
        dataLayer.push({
          event: 'customViewListing',
          viewListingData: {
            products: model.products?.map(({ code }) => code),
            category: isCategoryNumber ? potentialCategoryId : undefined,
            keywords: model.freeTextSearch,
            page_number: model.pagination?.currentPage,
          },
        });
      }
    }
  }

  private scrollToSelectedProduct(): void {
    if (this.winRef.isBrowser()) {
      const productIndex = this.winRef.localStorage.getItem(BossStorageKeys.SELECTED_PRODUCT_ID);

      if (productIndex) {
        const selectedProduct = this.productCards.find((el, index) => index === +productIndex);

        if (selectedProduct) {
          selectedProduct.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.winRef.localStorage.removeItem(BossStorageKeys.SELECTED_PRODUCT_ID);
        }
      }
    }
  }
}
