import { Observable } from 'rxjs';
import { Cart, CartModification } from '@spartacus/core';
import { GiftCardApplyRequest } from '../../../shared/models';

export abstract class BossCartEntryAdapter {
  abstract addWithStore(
    userId: string,
    cartId: string,
    productCode: string,
    pickupStore: string,
    quantity?: number,
    returnAmount?: number,
  ): Observable<CartModification>;

  abstract updateWithReturnAmount(
    userId: string,
    cartId: string,
    entryNumber: string,
    qty: number,
    returnAmount?: number,
  ): Observable<CartModification>;

  abstract addGiftCard(userId: string, cartId: string, giftCardApplyRequest: GiftCardApplyRequest): Observable<Cart>;

  abstract removeGiftCard(userId: string, cartId: string, giftcard: string): Observable<Cart>;

  abstract removeAllGiftCards(userId: string, cartId: string): Observable<Cart>;
}
