import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CmsComponentsService, PageSlotService } from '@spartacus/storefront';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class BossPageSlotService extends PageSlotService {
  constructor(
    cmsComponentsService: CmsComponentsService,
    @Inject(PLATFORM_ID) platformId: any, // eslint-disable-line
    @Inject(DOCUMENT) document,
    private router: Router,
  ) {
    super(cmsComponentsService, platformId, document);
  }

  shouldNotDefer(slot: string): boolean {
    const hasHash = this.router.url.includes('#');

    if (hasHash) {
      return true;
    }
    super.shouldNotDefer(slot);
  }
}
