import { UrlMatchResult, UrlSegment } from '@angular/router';

export function bossProductUrlMatcher(segments: UrlSegment[]): UrlMatchResult | null {
  if (segments?.length) {
    if (segments[0].path === 'p') {
      const productNameAndCode = segments[1]?.path;

      if (productNameAndCode) {
        const productCode = productNameAndCode.slice(productNameAndCode.lastIndexOf('-') + 1);

        return {
          consumed: segments,
          posParams: { productCode: new UrlSegment(productCode, {}) },
        };
      } else {
        return null;
      }
    }
  }

  return null;
}
