import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { BossDialogDirective } from './boss-dialog.directive';

import { BossDialogContent } from './boss-dialog-content';
import { BossDialogService } from './boss-dialog.service';
import { Subject, fromEvent } from 'rxjs';
import { BossDialogSize, BossDialogType } from './boss-dialog.model';
import { skip, takeUntil, tap } from 'rxjs/operators';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'boss-dialog',
  templateUrl: './boss-dialog.component.html',
  styleUrls: ['./boss-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossDialogComponent implements OnInit, OnDestroy {
  dialogType: BossDialogType;

  modalWidth?: BossDialogSize;

  isCloseButtonVisible = true;

  readonly bossDialogType = BossDialogType;

  private onDestroy$ = new Subject<void>();

  @Input() content: BossDialogContent;

  @ViewChild(BossDialogDirective, { static: true }) bossDialogHost: BossDialogDirective;

  @ViewChild('bossDialog', { static: true }) bossDialog: ElementRef;

  constructor(
    private dialogService: BossDialogService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private winRef: WindowRef,
  ) {}

  ngOnInit(): void {
    this.loadContent();
  }

  ngOnDestroy(): void {
    this.close();
  }

  private loadContent(): void {
    const viewContainerRef = this.bossDialogHost?.viewContainerRef;
    viewContainerRef?.clear();

    if (this.content) {
      const { type, size, data, isCloseButtonVisible = false, injector } = this.content.options,
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.content.component),
        componentRef = viewContainerRef?.createComponent(componentFactory, 0, injector);

      this.dialogType = type;
      this.modalWidth = size;
      this.isCloseButtonVisible = isCloseButtonVisible;
      componentRef.instance.data = data;
      componentRef?.changeDetectorRef.detectChanges();

      this.createClickOutsideListener();
    }
  }

  close(): void {
    this.dialogService.close();
    this.bossDialogHost.viewContainerRef.clear();

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private createClickOutsideListener(): void {
    if (this.winRef.isBrowser()) {
      fromEvent(document, 'click')
        .pipe(
          skip(1),
          tap((dialogWrapperEvent) => {
            if (!this.bossDialog.nativeElement.contains(dialogWrapperEvent.target)) {
              this.close();
            }
          }),
          takeUntil(this.onDestroy$),
        )
        .subscribe();
    }
  }
}
