import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BossStatusLabelType } from '../../models';

// TODO: BSN-3852
@Component({
  selector: 'boss-status-label',
  templateUrl: './boss-status-label.component.html',
  styleUrls: ['./boss-status-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossStatusLabelComponent {
  @Input() label: string = '';

  @Input() status?: Partial<BossStatusLabelType>;
}
