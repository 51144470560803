import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossProductPriceTopDownComponent } from './product-price-top-down.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BossProductPriceTopDownComponent],
  exports: [BossProductPriceTopDownComponent],
})
export class BossProductPriceTopDownComponentModule {}
