import { LayoutConfig } from '@spartacus/storefront';
import { DeferLoadingStrategy } from '@spartacus/core';

export const bossLayoutConfig: LayoutConfig = {
  deferredLoading: {
    strategy: DeferLoadingStrategy.DEFER,
    intersectionMargin: '100px',
  },
  layoutSlots: {
    // Header predefined
    headerBefore: {
      slots: [],
    },
    headerUpper: {
      slots: [],
    },
    header: {
      lg: {
        slots: [
          'HeaderSalesBanner',
          'SiteLogo',
          'SearchBox',
          'BossMyMarketSlot',
          'BossUserAccountSlot',
          'WishListSlot',
          'MiniCart',
        ],
      },
      slots: [
        'HeaderSalesBanner',
        'PreHeader',
        'SiteLogo',
        'SearchBox',
        'BossMyMarketSlot',
        'BossUserAccountSlot',
        'WishListSlot',
        'MiniCart',
      ],
    },
    navigation: {
      lg: {
        slots: ['NavigationBar'],
      },
      slots: ['NavigationBar'],
    },
    headerAfter: {
      slots: ['BottomHeaderSlot'],
    },
    // Footer predefined
    footerBefore: {
      slots: [],
    },
    footer: {
      slots: [
        // footer slots are defined and controlled in boss-storefront-component.ts
      ],
    },
    footerAfter: {
      slots: [
        /*'FooterBottomLeft'*/
      ],
    },
    // Page templates
    StartPageTemplateBoss: {
      // TODO change 'SlickSlider' in the future, to a new above-the-fold slot
      pageFold: 'SlickSlider',
      slots: ['SlickSlider', 'StoreFinder'],
    },
    LoginPageTemplate: {
      pageFold: 'RightContentSlot',
      slots: ['LeftContentSlot', 'RightContentSlot'],
    },
    SearchResultsGridPageTemplate: {
      pageFold: 'Section2',
      slots: ['Section2', 'ProductLeftRefinements', 'SearchResultsGridSlot', 'Section4'],
    },
    SearchResultsEmptyPageTemplate: {
      pageFold: 'MiddleContent-searchEmpty',
      slots: [
        'SideContent-searchEmpty',
        'TopContent-searchEmpty',
        'MiddleContent-searchEmpty',
        'BottomContentSlot-searchEmpty',
      ],
    },
    StoreFinderPageTemplate: {
      pageFold: 'MiddleContent',
      slots: ['MiddleContent', 'ProspektTeaserContent', 'LocalCounselorDetailsContent'],
    },
    ProductDetailsPageTemplate: {
      pageFold: 'Summary',
      slots: ['Summary', 'UpSelling', 'CrossSelling', 'PlaceholderContentSlot', 'LastViewedProducts', 'AddToCartCta'],
    },
    ProductGridPageTemplate: {
      pageFold: 'TopHeaderSlot',
      slots: [
        'TopHeaderSlot',
        'TopProductTeaserSlot',
        'categoryTree',
        'ProductLeftRefinements',
        'ProductGridSlot',
        'BottomTeaserSlot',
      ],
    },
    CartPageTemplate: {
      slots: ['EmptyCartMiddleContent', 'TopContent', 'MiddleContent'],
    },
    SpaMultiStepCheckoutSummaryPageTemplate: {
      slots: ['BodyContent'],
    },
    CheckoutHeader: {
      slots: ['HeaderSlot'],
    },
    CheckoutFooter: {
      slots: ['Footer'],
    },
    ProspectPageTemplate: {
      slots: ['Prospect'],
    },
    ContentPage1Template: {
      pageFold: 'Section1',
      slots: ['Section1', 'Section2A', 'Section2B', 'Section3'],
    },
    ContactPageTemplate: {
      slots: ['Section2A', 'Section2B'],
    },
    CheckoutLoginPageTemplate: {
      slots: ['LeftContentSlot', 'RightContentSlot', 'BottomCheckoutContentSlot'],
    },
    StoreFinderDetailsPageTemplate: {
      slots: ['TopContent', 'MiddleContent', 'BottomContentSlot', 'ContentMarketDetailsContent'],
    },
    OrderConfirmationPageTemplate: {
      slots: ['TopContent', 'ShippingContent', 'ClickCollectContent', 'HybridContent'],
    },
    AccountPageTemplate: {
      pageFold: 'BodyContent',
      slots: ['SideContent', 'BodyContent'],
    },
    AccountOrderTrackingPageTemplate: {
      pageFold: 'BodyContent',
      slots: ['SideContent', 'BodyContent'],
    },
    AccountFavoritesDetailPageTemplate: {
      slots: ['BodyContent'],
    },
    CategoryPageTemplate: {
      pageFold: 'Section1',
      slots: ['TopHeaderSlot', 'Section1', 'Section2', 'Section3', 'Section5', 'Section6', 'categoryTree'],
    },
    ErrorPageTemplate: {
      slots: ['SpecialPictures'],
    },
    AccountUpdatePasswordPageTemplate: {
      slots: ['BodyContent'],
    },
    ContentPageNoHeaderMinimalFooterTemplate: {
      slots: ['Content', 'FooterBottom'],
    },
  },
};
