import { Injectable } from '@angular/core';
import { ActivatedRouterStateSnapshot, PageType, ProductSearchPage } from '@spartacus/core';
import { ProductFacetService } from '@spartacus/storefront';

@Injectable({
  providedIn: 'root',
})
export class BossProductFacetService extends ProductFacetService {
  protected filterForPage(state: ActivatedRouterStateSnapshot, page: ProductSearchPage): boolean {
    if (!page.currentQuery?.query?.value) {
      return false;
    }

    // PLP
    if (state.context.type === PageType.CATEGORY_PAGE) {
      return page.currentQuery.query.value.indexOf(`allCategories:${state.context.id}`) > -1;
    }

    if (state.context.type === PageType.CONTENT_PAGE) {
      // Search page
      if (state.context.id === 'search') {
        return page.currentQuery.query.value.startsWith(`${state.params.query}:`);
      }

      // Solr grid
      return !!page.products.length;
    }

    return false;
  }
}
