import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { SeoMetaService } from '@spartacus/storefront';
import { BossStructuredDataModule } from './boss-structured-data.module';
import { BossSeoConfig } from './models/boss-seo.model';

export function bossInitSeoService(injector: Injector): () => void {
  const result = (): void => {
    const service = injector.get(SeoMetaService);
    service.init();
  };

  return result;
}

@NgModule({
  imports: [BossStructuredDataModule],
  providers: [
    provideConfig({
      seo: {
        structuredData: {
          disableInDevMode: true,
        },
      },
    } as BossSeoConfig),
    {
      provide: APP_INITIALIZER,
      useFactory: bossInitSeoService,
      deps: [Injector],
      multi: true,
    },
  ],
})
export class BossSeoModule {}
