import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[bossSetActiveState]',
})
export class SetActiveStateDirective implements OnInit, OnDestroy {
  @Input() elementUrl: string;
  private subscription = new Subscription();
  constructor(private elm: ElementRef, private router: Router, private renderer: Renderer2) {}

  ngOnInit(): void {
    // Initial active set => then listen for router changes
    this.markActiveRoute(this.router.url);
    this.subscription.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        if (event.url) {
          this.markActiveRoute(event.url);
        }
      }),
    );
  }

  private markActiveRoute(currentRoute: string): void {
    this.elementUrl = this.removeSlash(this.elementUrl);

    if (currentRoute === `/${this.elementUrl}`) {
      this.renderer.addClass(this.elm.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.elm.nativeElement, 'active');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private removeSlash(url: string): string {
    return url.charAt(0) === '/' ? (url = url.substring(1)) : url;
  }
}
