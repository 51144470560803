import { Injectable } from '@angular/core';
import { CmsStructureModel, Occ, OccCmsPageNormalizer } from '@spartacus/core';

@Injectable({ providedIn: 'root' })
export class BossOccCmsPageNormalizer extends OccCmsPageNormalizer {
  /**
   * Overrides the OccCmsPageNormalizer to not set component.properties hard to undefinded
   */
  protected normalizeComponentData(source: Occ.CMSPage, target: CmsStructureModel): void {
    if (!source?.contentSlots?.contentSlot) {
      return;
    }

    for (const slot of source.contentSlots.contentSlot) {
      if (Array.isArray(slot.components?.component)) {
        for (const component of slot.components.component) {
          // while we're hoping to get this right from the backend api,
          // the OCC api stills seems out of sync with the right model.
          if (component.modifiedtime) {
            component.modifiedTime = component.modifiedtime;
            delete component.modifiedtime;
          }

          // Start customization. We need component.properties in frontend
          //   if (component.properties) {
          //     component.properties = undefined;
          //   }
          // End customization
          if (!target.components) {
            target.components = [];
          }
          target.components.push(component);
        }
      }
    }
  }
}
