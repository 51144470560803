export const cart = {
  bossCart: {
    breadcrumbs: {
      title: 'Warenkorb',
    },
    column: {
      label: {
        items: 'Artikel',
        quantity: 'Menge',
        priceUnit: 'Einzelpreis',
        priceTotal: 'Gesamtpreis',
      },
    },
    text: {
      title: 'Dein Warenkorb', //'DEIN WARENKORB',
      goToCheckout: 'Zur Kasse gehen', //'ZUR KASSE',
      homeDelivery: 'Lieferung nach Hause', //'LIEFERUNG NACH HAUSE',
      changeArticle: 'Artikel & Lieferart ändern',
      continueShopping: 'Weiter einkaufen',
      emptyCartNewStuff: 'Im Kopf schon eingerichtet?',
      emptyCartAddToCart: 'Schnell in den Warenkorb damit',
    },
    orderTicks: {
      header: 'Bestellen ohne Risiko', //'BESTELLEN OHNE RISIKO',
      line1: 'Tiefpreis-Garantie',
      line2: 'Qualitäts-Garantie',
      line3: '14-tägiges Rückgaberecht',
    },
    totals: {
      headline: 'Zusammenfassung der Bestellung',
      orderAmount: 'Bestellsumme',
      shippingCosts: 'Versandkosten',
      shippingCostsTooltip:
        'Bei deiner Bestellung fallen einmalig Versandkosten an. Die Höhe dieser Versandkosten ist abhängig vom Warenwert deines Warenkorbes und der Versandart, jedoch nicht von der Anzahl der Artikel.',
      discount: 'Rabatt',
      totalAmount: 'Gesamt',
      taxInfo: '(inkl. MwSt.)',
    },
    couponsToggle: 'Rabatt-Code eingeben',
    couponsInfo:
      'Pro Bestellung kann nur ein Rabatt-Code genutzt werden. Geschenkkarten können nur beim Bezahlvorgang eingelöst werden.',
    couponsAdded: 'Rabatt-Code eingelöst',
    couponRedeemed: 'Bitte überprüfe deine Eingabe. Eventuell wurde der Rabattcode bereits verwendet.',
    pickup: {
      available: 'Abholbereit in {{store}}',
    },
    returnDevices: {
      labelRetrun: 'Altgeräte-Mitnahme',
      labelPickUp: 'Altgeräte-Abgabe',
      infoDelivery: 'Bei Lieferung nimmt unser Spediteur dein Altgerät mit.',
      infoPickUp: 'Bei Abholung deiner Ware kannst du bei uns dein Altgerät abgeben.',
      infoLink: 'Weitere Informationen',
      headingDelivery: 'Altgeräte-Mitnahme',
      headingPickUp: 'Altgeräte-Abgabe',
      headingFor: 'für',
      removeButton: 'Entfernen',
    },
  },
  cartItems: {
    id: 'Artikelnr.',
    description: 'Beschreibung',
    item: 'Artikel',
    itemPrice: 'Artikelpreis',
    quantity: 'Menge',
    quantityTitle: 'Die Menge repräsentiert die Gesamtmenge dieses Artikels in Ihrem Warenkorb.',
    total: 'Gesamt',
    cartTotal: 'Warenkorb gesamt ({{count}} Artikel)',
    cartTotal_plural: 'Warenkorb gesamt ({{count}} Artikel)',
    changedCount: 'Die Artikelanzahl wurde aktualisiert',
    changeAmount: 'Die Bestellsumme wurde aktualisiert.',
    deleteNow: 'Jetzt löschen',
    removeItem: 'Produkt entfernen',
    addedToCart: 'Eine gute Wahl! Wir haben das Produkt in Deinen Warenkorb gelegt',
    removeFromWishlist: 'Das Produkt wurde aus deiner Merkliste entfernt',
    addToWishlist: 'Der Artikel wurde zur Merkliste hinzugefügt',
  },
  voucher: {
    apply: 'Einlösen',
    placeholder: 'Rabatt-Code',
    applyVoucherSuccess: '{{voucherCode}} wurde angewendet.',
    removeVoucherSuccess: '{{voucherCode}} wurde entfernt.',
    anchorLabel: 'Geben Sie einen Rabatt-Code ein oder entfernen sie einen Rabatt-Code',
    vouchersApplied: 'Angewendete Rabatt-Codes',
    availableCoupons: 'Verfügbare Rabatt-Codes',
    availableCouponsLabel: 'Sie können diese Rabatt-Codes zu der Bestellung hinzufügen.',
    voucherInfo:
      'Hier können Sie Ihren Onlinegutschein einlösen. Ihre Geschenkkarte geben Sie bitte bei den Zahlungsmitteln ein.',
    removeButton: 'Entfernen',
  },
  orderCost: {
    orderSummary: 'Bestellungs-Zusammenfassung',
    subtotal: 'Zwischensumme nach Rabatten:',
    shipping: 'Versandkosten:',
    estimatedShipping: 'Geschätzte Versandkosten:',
    discount: 'Sie haben gespart:',
    salesTax: 'Mehrwertsteuer:',
    grossTax: 'Die Gesammtsumme der Bestellung enthält keine Steuern i.H.v.',
    grossIncludeTax: 'Die Gesamtsumme der Bestellung enthält Steuern i.H.v.',
    total: 'Gesamt:',
    toBeDetermined: 'Zu ermitteln',
  },
};
