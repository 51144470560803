import { CheckoutActions, PaymentType, PaymentTypesState } from '@spartacus/core';
import {
  BOSS_SET_PAYMENT_TYPE_SUCCESS,
  BossSetPaymentTypeSuccess,
} from '../../features/checkout/services/payment-types.action';

export const initialState: PaymentTypesState = {
  entities: {},
  selected: undefined,
};

export function reducer(
  state = initialState,
  action:
    | CheckoutActions.PaymentTypesAction
    | CheckoutActions.ClearCheckoutData
    | CheckoutActions.CheckoutClearMiscsData
    | BossSetPaymentTypeSuccess,
): PaymentTypesState {
  switch (action.type) {
    case CheckoutActions.LOAD_PAYMENT_TYPES_SUCCESS: {
      const paymentTypes: PaymentType[] = action.payload;
      const entities = paymentTypes.reduce(
        (paymentTypesEntities: { [code: string]: PaymentType }, name: PaymentType) => {
          return {
            ...paymentTypesEntities,
            [name.code]: name,
          };
        },
        {
          ...state.entities,
        },
      );

      return {
        ...state,
        entities,
      };
    }

    case CheckoutActions.SET_PAYMENT_TYPE_SUCCESS: {
      return {
        ...state,
        selected: action.payload.paymentType.code,
      };
    }

    case CheckoutActions.CLEAR_CHECKOUT_DATA: {
      return {
        ...state,
        selected: undefined,
      };
    }

    case CheckoutActions.CHECKOUT_CLEAR_MISCS_DATA: {
      return initialState;
    }

    case BOSS_SET_PAYMENT_TYPE_SUCCESS: {
      const paymentMethod = action.payload;
      return {
        ...state,
        selected: paymentMethod,
      };
    }
  }

  return state;
}
