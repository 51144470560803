import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossHeadlineAndIntroComponent } from './headline-and-intro.component';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BossHeadlineAndIntroComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        HeadlineIntroComponent: {
          component: BossHeadlineAndIntroComponent,
        },
      },
    }),
  ],
})
export class BossHeadlineAndIntroModule {}
