import { Injectable } from '@angular/core';
import { Occ } from '@spartacus/core';
import WeekdayOpeningDay = Occ.WeekdayOpeningDay;
import { BossOpeningSchedule } from '../../../shared/models/store.models';

@Injectable({ providedIn: 'root' })
export class StorefinderOpeningHoursService {
  normalizeOpeningHours(openingHours: BossOpeningSchedule): string {
    let hours = '';

    const hoursArr: string[] = this.getHoursArr(openingHours);

    if (hoursArr[0] && hoursArr[1]) {
      hours += `Mo-Fr: ${this.getWeekdayOpeningHours(openingHours)}`;
    }

    if (hoursArr[2] && hoursArr[3]) {
      hours += `, Sa: ${this.getSaturdayOpeningHours(openingHours)}`;
    }

    return hours;
  }

  getWeekdayOpeningHours(openingHours: BossOpeningSchedule): string {
    const hoursArr: string[] = this.getHoursArr(openingHours);
    return hoursArr[0] && hoursArr[1] ? `${hoursArr[0]}-${hoursArr[1]}` : '';
  }

  getSaturdayOpeningHours(openingHours: BossOpeningSchedule): string {
    const hoursArr: string[] = this.getHoursArr(openingHours);
    return hoursArr[2] && hoursArr[3] ? `${hoursArr[2]}-${hoursArr[3]}` : '';
  }

  getSundayOpeningHours(openingHours: BossOpeningSchedule): string {
    const sunday = openingHours.weekDayOpeningList.find((openingDay: WeekdayOpeningDay) => openingDay.weekDay === 'So');
    return sunday?.closed ? '' : `${sunday.openingTime} - ${sunday.closingTime}`;
  }

  private getHoursArr(openingHours: BossOpeningSchedule): string[] {
    return openingHours.code.split('_').map((time: string) => {
      return time.slice(0, 2) + ':' + time.slice(2);
    });
  }
}
