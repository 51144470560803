import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossMainStageComponent } from './boss-main-stage.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { BossTextLimitPipeModule } from '../../shared/pipes/text-limit/boss-text-limit-pipe.module';
import { BossHeadlineModule } from '../../shared/components/headline/boss-headline.module';
import { BossCarouselBaseModule } from '../../shared/components/boss-carousel-base/boss-carousel-base.module';
import { BossMediaDirectiveModule } from '../../shared/directives/media/boss-media.directive.module';

@NgModule({
  declarations: [BossMainStageComponent],
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        HomepageBasicStageHighlight: {
          component: BossMainStageComponent,
        },
      },
    } as CmsConfig),
    BossMediaDirectiveModule,
    GenericLinkModule,
    BossCarouselBaseModule,
    BossHeadlineModule,
    BossTextLimitPipeModule,
  ],
  exports: [BossMainStageComponent],
})
export class BossMainStageModule {}
