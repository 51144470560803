import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossImageMoodTextComponent } from './image-mood-text.component';
import { GenericLinkModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { LinkTargetPipeModule } from '../../../shared/pipes/link-target/link-target.pipe.module';
import { BossDecodeUriPipeModule } from '../../../shared/pipes/encode-uri/boss-decode-uri-pipe.module';
import { BossMediaDirectiveModule } from '../../../shared/directives/media/boss-media.directive.module';

@NgModule({
  declarations: [BossImageMoodTextComponent],
  imports: [
    CommonModule,
    LinkTargetPipeModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ImageMoodTextComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-shared" */
              './image-mood-text.component'
            ).then((m) => m.BossImageMoodTextComponent),
        },
      },
    } as CmsConfig),
    RouterModule,
    GenericLinkModule,
    BossMediaDirectiveModule,
    BossDecodeUriPipeModule,
  ],
  exports: [BossImageMoodTextComponent],
})
export class ImageMoodTextModule {}
