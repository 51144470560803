export const myAccount = {
  orderDetails: {
    order: 'Bestellung',
    orderId: 'Bestellnummer',
    paymentMethod: 'Zahlart',
    total: 'Gesamtsumme',
    totalTitle: 'Zusammenfassung der Bestellung',
    orderTotal: 'Bestellsumme',
    shippingCosts: 'Versandkosten',
    emptyPurchaseOrderId: 'Keine',
    none: 'Keine {{value}}',
    placed: 'Aufgegeben',
    placedBy: 'Aufgegeben von',
    unit: 'Einheit',
    status: 'Status',
    size: 'Größe',
    color: 'Farbe',
    quantity: 'Anzahl',
    includeVat: 'inkl. MwSt.',
    priceNoDelivery: 'Stückpreis',
    recentLowestPrice: '30 Tage Tiefpreis',
    shippedOn: 'Versandt am',
    startOn: 'Start am',
    nextOrderDate: 'Nächstes Bestelldatum',
    frequency: 'Frequenz',
    cancelled: ' Abgebrochen',
    deliveryStatus_IN_TRANSIT: 'Auf dem Transportweg',
    deliveryStatus_READY_FOR_PICKUP: 'Bereit zur Abholung',
    deliveryStatus_READY_FOR_SHIPPING: 'Bereit zur Lieferung',
    deliveryStatus_WAITING: 'Wartend',
    deliveryStatus_DELIVERING: 'Liefernd',
    deliveryStatus_PICKPACK: 'Vorbereiten für Lieferung',
    deliveryStatus_PICKUP_COMPLETE: 'Abholung abgeschlossen',
    deliveryStatus_DELIVERY_COMPLETED: 'Lieferung abgeschlossen',
    deliveryStatus_PAYMENT_NOT_CAPTURED: 'Zahlungsprobleme',
    deliveryStatus_READY: 'In Arbeit',
    deliveryStatus_DELIVERY_REJECTED: 'Lieferung abgelehnt',
    deliveryStatus_SHIPPED: 'Versendet',
    deliveryStatus_TAX_NOT_COMMITTED: 'Steuer-Probleme',
    deliveryStatus_CANCELLED: 'Storniert',
    statusDisplay_cancelled: 'Storniert',
    statusDisplay_cancelling: 'Stornierung ausstehend',
    statusDisplay_completed: 'Abgeschlossen',
    statusDisplay_created: 'Erstellt',
    statusDisplay_error: 'Ausstehend',
    statusDisplay_Error: 'Ausstehend',
    statusDisplay_processing: 'Ausstehend',
    statusDisplay_open: 'Offen',
    statusDisplay_pending: {
      approval: 'Genehmigung ausstehend',
      merchant: {
        approval: 'Genehmigung durch Verkäufer ausstehend',
      },
    },
    statusDisplay_approved: 'Genehmigt',
    statusDisplay_rejected: 'Abgelehnt',
    statusDisplay_merchant: {
      approved: 'Durch Verkäufer bestätigt',
      rejected: 'Durch Verkäufer abgelehnt',
    },
    statusDisplay_assigned: {
      admin: 'An Administrator zugeteilt',
    },
    consignmentTracking: {
      action: 'Paket verfolgen',
      dialog: {
        header: 'Paketverfolgungs-Informationen',
        shipped: 'Geliefert',
        estimate: 'Geschätzte Lieferzeit',
        carrier: 'Lieferservice',
        trackingId: 'Paketverfolgungs-Nummer',
        noTracking:
          'Das Paket wurde nicht vom Lager versendet. Die Paketverfolgungsinformationen werden zur Verfügung stehen, nachdem das Paket versandt wurde.',
        loadingHeader: 'Sendungsverfolgung',
      },
    },
    cancellationAndReturn: {
      returnAction: 'Rücksendung anfragen',
      cancelAction: 'Artikel stornieren',
      item: 'Artikel',
      itemPrice: 'Artikelpreis',
      quantity: 'Maximale Anzahl',
      returnQty: 'Zurückzusendende Anzahl',
      cancelQty: 'Stornierte Anzahl',
      setAll: 'Setze alle Anzahlen auf Maximum',
      totalPrice: 'Gesamt',
      submit: 'Anfrage absenden',
      returnSuccess: 'Ihre Rücksendungsanfrage ({{rma}}) wurde übermittelt',
      cancelSuccess:
        'Ihre Stornierungsanfrage wurde übermittelt (Ursprüngliche Bestellung {{orderCode}} wird aktualisiert)',
    },
  },
  orderHistory: {
    orderHistory: 'Bestellverlauf',
    currentScrollStatus: '{{current}} von {{total}} Bestellungen angezeigt',
    orderId: 'Bestellnummer',
    orderDay: 'Bestellt am',
    title: 'Produkte - Details der Bestellung',
    searchText:
      'Hier findest du alle Bestellungen, die du über den Onlineshop getätigt hast.<br/> Den Status einer Bestellung kannst du auch unter <a href="my-account/tracking" class="boss-link copy">Auftragsverfolgung</a> einsehen',
    noSearchResults:
      'Entschuldigung, wir konnten keine Bestellung mit dieser Nummer finden. Bitte überprüfe deine Bestellnummer. Sie besteht aus 7 Zeichen und du findest sie in deiner Bestellbestätigung oder auf deiner Rechnung. ',
    noOrders: 'Du hast noch keine Bestellung getätigt. Finde jetzt deine neuen Lieblingsmöbel für dein Zuhause!',
    shopMore: 'Jetzt stöbern und einkaufen',
    searchOrder: 'Bestellung finden',
    emptyPurchaseOrderId: 'Keine',
    date: 'Datum',
    status: 'Status',
    total: 'Gesamt',
    startShopping: 'Einkauf starten',
    sortByMostRecent: 'Sortieren nach: Neuste',
    purchaseOrderNumber: 'Bestellnummer',
    startOn: 'Start am',
    frequency: 'Frequenz',
    nextOrderDate: 'Nächstes Bestelldatum',
    cancel: 'Abbrechen',
    cancelled: 'Abgebrochen',
    notFound: 'Keine Bestellungen gefunden',
  },
  closeAccount: {
    confirmAccountClosure: 'Accountlöschung bestätigen',
    confirmAccountClosureMessage: 'Sind Sie sicher, dass Sie Ihren Account löschen möchten?',
    closeMyAccount: 'LÖSCHE MEINEN ACCOUNT',
    accountClosedSuccessfully: 'Account erfolgreich gelöscht',
    accountClosedFailure: 'Accountlöschung fehlgeschlagen',
  },

  updatePasswordForm: {
    oldPassword: {
      label: 'Altes Passwort',
      placeholder: 'Altes Passwort',
    },
    oldPasswordIsRequired: 'Altes Passwort wird benötigt.',
    newPassword: {
      label: 'Neues Passwort',
      placeholder: 'Neues Passwort',
    },
    passwordMinRequirements:
      'Passwort muss mindestens 6 Zeichen lang sein, mit einem Großbuchstaben, einer Nummer und einem Sonderzeichen',
    confirmPassword: {
      label: 'Neues Passwort bestätigen',
      placeholder: 'Passwort bestätigen',
    },
    bothPasswordMustMatch: 'Beide Passwörter müssen übereinstimmen',
    passwordUpdateSuccess: 'Dein Passwort wurde erfolgreich geändert.',
  },
  updateProfileForm: {
    title: 'Anrede',
    none: '',
    firstName: {
      label: 'Vorname',
      placeholder: 'Nachname',
    },
    firstNameIsRequired: 'Vorname wird benötigt.',
    lastName: {
      label: 'Nachname',
      placeholder: 'Nachname',
    },
    lastNameIsRequired: 'Nachname wird benötigt.',
    profileUpdateSuccess: 'Persönliche Daten erfolgreich aktualisiert',
    customerId: 'Kundennummer',
  },
  consentManagementForm: {
    clearAll: 'Alle löschen',
    selectAll: 'Alle auswählen',
    message: {
      success: {
        given: 'Zustimmung erfolgreich erteilt.',
        withdrawn: 'Zustimmung erfolgreich entzogen.',
      },
    },
  },
  myCoupons: {
    noCouponsMessage: 'Sie haben keine Coupons zur Verfügung.',
    effectiveTitle: 'Wirksam:',
    Effective: 'WIRKSAM',
    PreSession: 'BALD WIRKSAM',
    ExpireSoon: 'BALD ABLAUFEND',
    readMore: 'Lies mehr',
    notification: 'Benachrichtigung',
    findProducts: 'Produkte finden',
    status: 'Status:',
    dialogTitle: 'Coupon',
    claimCustomerCoupon: 'Du hast diesen Coupon erfolgreich beansprucht.',
    myCoupons: 'Meine Coupons',
    startDateAsc: 'Start-Datum (aufsteigend)',
    startDateDesc: 'Start-Datum (absteigend)',
    endDateAsc: 'End-Datum (aufsteigend)',
    endDateDesc: 'End-Datum (absteigend)',
    sortByMostRecent: 'Sortieren nach: Neuste',
    notesPreffix:
      'Sie können Ihre bevorzugten Kommunikationskanäle für das Erhalten von Couponbenachrichtigungen auf der ',
    notesLink: 'Benachrichtigungskanäle',
    notesSuffix: ' Seite einstellen.',
  },
  notificationPreference: {
    message: 'Wählen Sie Ihre bevorzugten Kommunikationskanäle',
    note: 'Hinweis: ',
    noteMessage: 'Wenn Sie alle Kanäle deaktivieren, werden Sie keine weiteren Benachrichtigungen erhalten können.',
    EMAIL: 'E-Mail:',
    SMS: 'SMS:',
    SITE_MESSAGE: 'SiteMessage',
  },
  myInterests: {
    header: 'Meine Interessen',
    item: 'ARTIKEL',
    price: 'PREIS',
    notifications: 'BENACHRICHTIGUNGEN',
    noInterests: 'Sie haben noch keine gespeicherten Interessen.',
    inStock: 'Auf Lager',
    lowStock: 'Wenige auf Lager',
    outOfStock: 'Nicht verfügbar',
    BACK_IN_STOCK: 'Wieder auf Lager',
    sortByMostRecent: 'Sortiere nach: Neuste',
    expirationDate: ' - Bis {{ expirationDate }}',
    productId: 'Produktnummer {{ code }}',
    remove: 'ENTFERNEN',
    sorting: {
      byNameAsc: 'Name (aufsteigend)',
      byNameDesc: 'Name (absteigend)',
    },
  },
  AccountOrderHistoryTabContainer: {
    tabs: {
      AccountOrderHistoryComponent: 'ALLE BESTELLUNGEN ({{param}})',
      OrderReturnRequestListComponent: 'RÜCKSENDUNGEN ({{param}})',
    },
  },
  returnRequestList: {
    returnRequestId: 'Rücksendungsnummer',
    orderId: 'Bestellnummer',
    date: 'Erstellt am',
    status: 'Status',
    sortByMostRecent: 'Sortiere nach: Neuste',
    statusDisplay_APPROVAL_PENDING: 'Genehmigung ausstehend',
    statusDisplay_CANCELED: 'Abgebrochen',
    statusDisplay_CANCELLING: 'Abbrechen',
    statusDisplay_WAIT: 'Warte',
    statusDisplay_RECEIVED: 'Empfangen',
    statusDisplay_RECEIVING: 'Empfange',
    statusDisplay_APPROVING: 'Bestätige',
    statusDisplay_REVERSING_PAYMENT: 'Zahlung wird rÜckgängig gemacht',
    statusDisplay_PAYMENT_REVERSED: 'Zahlung rückgängig gemacht',
    statusDisplay_PAYMENT_REVERSAL_FAILED: 'Zahlung konnte nicht rückgängig gemaacht werden',
    statusDisplay_REVERSING_TAX: 'Steuern werden rückgängig gemacht',
    statusDisplay_TAX_REVERSED: 'Steuern rückgängig gemacht',
    statusDisplay_TAX_REVERSAL_FAILED: 'Steuern konnten nicht rückgängig gemacht werden',
    statusDisplay_COMPLETED: 'Abgeschlossen',
  },
  returnRequest: {
    returnRequestId: 'Rücksendungsanfrage-Nummer',
    orderCode: 'Für Bestellnummer',
    status: 'Rücksendungsstatus',
    cancel: 'Rücksendungsanfrage stornieren',
    item: 'Beschreibung',
    itemPrice: 'Artikelpreis',
    returnQty: 'Rücksendungs-Anzahl',
    total: 'Gesamt',
    summary: 'Rücksendung Gesamt',
    subtotal: 'Zwischensumme',
    deliveryCode: 'Lieferkosten',
    estimatedRefund: 'Geschätzte Erstattung',
    note: 'Die Gesamtbeträge sind geschätzt und beinhalten möglicherweise keine anfallenden Steuern oder andere Gebühren.',
    cancelSuccess: 'Ihre Rücksendungsanfragen ({{rma}}) wurde storniert',
  },
  wishlist: {
    empty: 'Noch keine Produkte in Ihrer Wunschliste',
    toggleFromWishlist: 'Wunschlisten Status',
  },
  updateEmailForm: {
    newEmailAddress: {
      label: 'Neue E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse',
    },
    confirmNewEmailAddress: {
      label: 'Neue E-Mail-Adresse bestätigen',
      placeholder: 'E-Mail-Adresse',
    },
    enterValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
    bothEmailMustMatch: 'Beide E-Mail-Adressen müssen übereinstimmen',
    password: {
      label: 'Passwort',
      placeholder: 'Passwort',
    },
    pleaseInputPassword: 'Bitte Passwort eingeben',
    emailUpdateSuccess: 'Deine E-Mail-Adresse wurde geändert. Bitte melde dich mit {{ newUid }} erneut an.',
  },
};
