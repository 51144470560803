export const address = {
  addressForm: {
    title: 'Anrede',
    firstName: {
      label: 'Vorname',
      placeholder: 'Vorname',
    },
    lastName: {
      label: 'Nachname',
      placeholder: 'Nachname',
    },
    address1: 'Adresse',
    address2: 'Adresszusatz',
    country: 'Land/Region',
    city: {
      label: 'Stadt',
      placeholder: 'Stadt',
    },
    state: 'Bundesland',
    zipCode: {
      label: 'Postleitzahl',
      placeholder: 'Postleitzahl',
    },
    phoneNumber: {
      label: 'Telefonnummer (optional)',
      placeholder: 'Telefonnummer',
    },
    saveAsDefault: 'Als Standard festlegen',
    chooseAddress: 'Adresse auswählen',
    streetAddress: 'Straße',
    aptSuite: 'Wohnung',
    selectOne: 'Wähle einen...',
    setAsDefault: 'Als Standard festlegen',
    titleRequired: 'Anrede wird benötigt.',
    userAddressAddSuccess: 'Deine neue Adresse wurde erfolgreich hinzugefügt',
    userAddressUpdateSuccess: 'Deine Adresse wurde erfolgreich aktualisiert.',
    userAddressDeleteSuccess: 'Die Adresse wurde erfolgreich gelöscht',
    invalidAddress: 'Ungültige Adresse',
  },
  addressBook: {
    title: 'Mein Addressbuch',
    addNewShippingAddress: 'Neue Adresse hinzufügen',
    editShippingAddress: 'Lieferadresse bearbeiten',
    areYouSureToDeleteAddress: 'Sicher, dass du folgende Adresse löschen möchtest?',
    addNewAddress: 'Neue Adresse hinzufügen',
    addAddress: 'Adresse hinzufügen',
    editAddress: 'Adresse bearbeiten',
    updateAddress: 'Adresse aktualisieren',
    backToAddressList: 'Zurück zum Adressbuch',
    deleteAddress: 'Adresse Löschen',
  },
  addressCard: {
    default: 'STANDARDADRESSE',
    selected: 'Ausgewählt',
    setAsDefault: 'Als Standard festlegen',
    shipTo: 'Lieferung an',
    billTo: 'Rechnung an',
  },
};
