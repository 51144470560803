import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { CheckoutService, RoutingService, SmartEditService } from '@spartacus/core';
import { PageLayoutHandler } from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BossCheckoutService } from '../../../checkout/services/boss-checkout.service';

@Injectable({
  providedIn: 'root',
})
export class OrderConfirmationPageLayoutHandler implements PageLayoutHandler, OnDestroy {
  subscription: Subscription = new Subscription();

  constructor(
    private bossCheckoutService: BossCheckoutService,
    private checkoutService: CheckoutService,
    private routingService: RoutingService,
    private smartEditService: SmartEditService,
    @Inject(PLATFORM_ID) protected platform: any, // eslint-disable-line
  ) {
    if (isPlatformBrowser(this.platform)) {
      const cartId = window.localStorage.getItem('__CID');
      if (cartId) {
        this.bossCheckoutService.bossPlaceOrder(cartId, true);
        this.subscription.add(
          this.routingService.getNextPageContext().subscribe((context) => {
            if (context) {
              window.localStorage.removeItem('__CID'); // navigated from the page
            }
          }),
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private excludeItems(arr: string[], args: string[]): string[] {
    return arr.filter((item) => args.every((arg) => arg !== item));
  }

  handle(slots$: Observable<string[]>, pageTemplate?: string, section?: string): Observable<string[]> {
    if (this.smartEditService.isLaunchedInSmartEdit()) {
      return slots$;
    }
    if (pageTemplate === 'OrderConfirmationPageTemplate' && !section) {
      return combineLatest([slots$, this.checkoutService.getOrderDetails()]).pipe(
        map(([slots, order]) => {
          if (order.pickupItemsQuantity && order.deliveryItemsQuantity) {
            return this.excludeItems(slots, ['ClickCollectContent', 'ShippingContent']);
          }
          if (order.pickupItemsQuantity) {
            return this.excludeItems(slots, ['ShippingContent', 'HybridContent']);
          }
          if (order.deliveryItemsQuantity) {
            return this.excludeItems(slots, ['ClickCollectContent', 'HybridContent']);
          }
          return this.excludeItems(slots, ['ClickCollectContent', 'ShippingContent', 'HybridContent']);
        }),
      );
    }
    return slots$.pipe(
      map((slots) => this.excludeItems(slots, ['ClickCollectContent', 'ShippingContent', 'HybridContent'])),
    );
  }
}
