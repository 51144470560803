import { Cart, CartActions, StateUtils } from '@spartacus/core';
import { BossCartActions } from '../../features/product/product-add-to-cart';

export const activeCartInitialState = null;
export const activeCartDefaultState = '';
export const wishListInitialState = '';

export function bossActiveCartReducer(
  state = activeCartDefaultState,
  action: CartActions.CartAction | CartActions.MultiCartActions,
): string {
  switch (action.type) {
    case CartActions.LOAD_CART_SUCCESS:
    case CartActions.CREATE_CART_SUCCESS:
    case CartActions.CREATE_CART:
      if (action?.payload?.extraData?.active) {
        return action.meta.entityId as string;
      }
      return state;
    case CartActions.SET_ACTIVE_CART_ID:
      return action.payload;
    case CartActions.REMOVE_CART:
    case CartActions.DELETE_CART_SUCCESS:
      if (action.payload?.cartId === state) {
        return activeCartDefaultState;
      }
      return state;
    case CartActions.CLEAR_CART_STATE:
      return state === activeCartInitialState ? activeCartInitialState : activeCartDefaultState;
  }
  return state;
}

export const cartEntitiesInitialState = undefined;

export function bossCartEntitiesReducer(state = cartEntitiesInitialState, action: StateUtils.LoaderAction): Cart {
  switch (action.type) {
    case CartActions.LOAD_CARTS_SUCCESS:
      return action.payload;

    case CartActions.LOAD_CART_SUCCESS:
    case CartActions.CREATE_CART_SUCCESS:
    case CartActions.CREATE_WISH_LIST_SUCCESS:
    case CartActions.LOAD_WISH_LIST_SUCCESS:
    case CartActions.SET_TEMP_CART:
      return action.payload.cart;
    case BossCartActions.GIFT_CARD_ADD_SUCCESS:
      return {
        ...state,
        giftCards: action.payload.cart.giftCards,
        toPayAmount: action.payload.cart.toPayAmount,
      };
    case BossCartActions.GIFT_CARD_REMOVE_SUCCESS:
      return {
        ...state,
        giftCards: undefined,
        toPayAmount: action.payload.cart.toPayAmount,
      };
    case BossCartActions.GIFT_CARD_REMOVE_ALL_SUCCESS:
      return {
        ...state,
        giftCards: undefined,
        toPayAmount: action.payload.cart.toPayAmount,
      };
  }
  return state;
}

export function bossWishListReducer(
  state = wishListInitialState,
  action: CartActions.WishListActions | CartActions.ClearCartState,
): string {
  switch (action.type) {
    case CartActions.CREATE_WISH_LIST_SUCCESS:
    case CartActions.LOAD_WISH_LIST_SUCCESS:
      return action.meta.entityId as string;
    case CartActions.CLEAR_CART_STATE:
      return wishListInitialState;
  }
  return state;
}
