import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideConfig({
      featureModules: {
        'boss-rebrush-test-flex': {
          module: () => import('./rebrush-test-flex.module').then((m) => m.RebrushTestFlexModule),
          cmsComponents: ['rebrushtestcomponent'],
        },
      },
    } as CmsConfig),
  ],
})
export class RebrushTestFlexFeatureModule {}
