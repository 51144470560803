<a *ngIf="anchorLink?.length; else rating" [routerLink]="anchorLink">
  <ng-container *ngTemplateOutlet="rating"></ng-container>
</a>

<ng-template #rating>
  <cx-icon *ngFor="let i of [1, 2, 3, 4, 5]" [type]="icon" class="star" [attr.tabindex]="disabled ? null : 0"></cx-icon>
  <div *ngIf="showCount && reviewsCount" class="user-rating-wrapper d-inline-flex">
    (
    <span class="user-rating-span">
      <i bossIcon [type]="bossIconConfig.USER_3_FILL" aria-hidden="true"></i>{{ reviewsCount }})
    </span>
  </div>
</ng-template>
