export const checkout = {
  bossDeliveryAddress: {
    header: 'Wohin dürfen wir deine Bestellung liefern?',
    subheader: 'Bitte geben Sie die Adresse an, zu der Ihre Bestellung geliefert werden soll',
    subheaderBilling: 'Bitte geben Sie die Adresse an, die als Rechungsadresse verwendet werden soll',
    boxTitle: 'Lieferadresse',
    // subheader: 'Bitte überprüfen Sie Ihre Lieferadresse. Sie haben jetzt die Möglichkeit, eine abweichende Rechnungsadresse anzugeben.',
    boxDeliveryAddress: 'Lieferadresse',
    boxBillingAddress: 'Teile uns Deine Rechnungsadresse mit',
    boxBillingAddressPayment: 'Rechnungsadresse',
    editAdress: 'Adresse bearbeiten',
    deliveryAsShippingAddress: 'obenstehende Lieferaddresse als Rechnungsadresse übernehmen',
    companyNameOptional: 'Firma',
    additionalOptional: 'Adresszusatz',
    phoneInformation: {
      label: 'Information',
      description: 'Teile uns deine Telefonnummer mit, unter der wir dich für eventuelle Rückfragen erreichen können.',
    },
    addressBook: 'Adressbuch öffnen',
    modal: {
      billingHeader: 'Dein Adressbuch',
      deliveryHeader: 'Dein Adressbuch',
      billingSubheader: 'Wähle eine Adresse als Rechnungsadresse aus',
      billingSubheaderEmpty: 'Es ist aktuell keine Adresse im Adressbuch hinterlegt..',
      deliverySubheader: 'Wähle eine Adresse als Lieferadresse aus',
      deliverySubheaderEmpty: 'Es ist aktuell keine Adresse im Adressbuch hinterlegt..',
    },
    newAddress: 'Neue Adresse anlegen',
    continueToPayment: 'Weiter zur Zahlart',
    mandatoryFields: '*Pflichtfelder',
    saveAddress: 'Adresse speichern',
    cancel: 'Abbrechen',
    countryDeliveryAlert: '<b>Bitte beachte:</b> Der Versand ist nur innerhalb Deutschlands möglich!',
    chooseAddress: 'Adresse verwenden',
    saveAddressMessage: 'Die Adresse wurde in deinem Adressbuch gespeichert.',
  },
  bossReviewOrder: {
    title: 'Überprüfe deine Bestellung',
    deliveryAddress: 'Lieferadresse',
    billingAddress: 'Rechnungsadresse',
    telephone: 'Tel. ',
    paymentType: 'Zahlungsart',
    selectedPaymentType: 'Ausgewählte Zahlungsart: ',
    totalAmount: 'Gesamtbetrag: ',
    checkData:
      'Bitte überprüfen Sie Ihre Eingaben. Sie können einzelne Angaben durch Klicken des Buttons [Ändern] korrigieren.',
    change: 'Adresse bearbeiten',
    change_payment: 'Zahlungsart bearbeiten',
    subTotal: 'Zwischensumme',
    pickup: 'Im Markt abholen',
    info: 'Information',
    shippingCostInfo:
      'Bei Ihrer Bestellung fallen einmalig Versandkosten an. Die Höhe dieser Versandkosten ist abhängig vom Warenwert Ihres Warenkorbes und der Versandart, jedoch nicht von der Anzahl der Artikel.',
    additionalDeliveryCost1: '(inkl. Inselzuschlag',
    additionalDeliveryCost2: 'i.H.v. 100 Euro)',
    additionalDeliveryCostMessage:
      'Bei Lieferung von Speditionsware auf eine deutsche Insel, berechnen wir einen Inselzuschlag von 100€.',
    termsCheck: {
      part1: 'Durch das Abschicken der Bestellung bestätige ich, dass ich die',
      part2: 'Allgemeinen Geschäftsbedingungen',
      part3: 'gelesen habe und erkläre mich mit ihnen einverstanden. Die Einzelheiten zum',
      part4: 'Datenschutz',
      part5: ' und zum',
      part6: 'Widerrufsrecht',
      part7: 'sind mir bekannt.',
    },
    giftCardTitle: 'Geschenkkarte',
    giftCard: 'Geschenkkartennummer:',
    giftCardAmount: 'Betrag:',
    buyNow: 'Jetzt kaufen',
    termsAndConditionError:
      'Akzeptieren Sie die Allgemeinen Geschäftsbedingungen, um Ihre Bestellung aufgeben zu können.',
  },
  bossOrderConfirmation: {
    header: {
      progressBar: {
        step1: 'Anmelden',
        step2: 'Bezahlen',
        step3: 'Bestellübersicht',
        step4: 'Fertig!',
      },
      header: 'Vielen Dank für deine Bestellung, ',
      subHeader: 'Deine Bestellnummer lautet: ',
      sub: 'In der Bestellübersicht kannst du dir deine Bestellung noch einmal ansehen. Zusätzlich erhälst du deine Bestellbestätigung per E-Mail.',
    },
    delivery: {
      number: 'Ihre Bestellnummer lautet:',
      date: 'Ihr vorraussichtlicher Liferzeitraum:',
      secure: {
        secure: 'Bestellung absichern:',
        text: 'Käuferschutz bis xxx € bei Nicht-Lieferung oder Nicht-Erstattung, zB. nach Widerruf.',
        buttonText: 'Kostenlos Absichern',
      },
      text1: 'In der Bestellübersicht können Sie sich Ihre Bestellung noch einmal ansehen.',
      text2: 'Zusätzlich erhalten Sie Ihre Bestellbestätigung per E-Mail.',
      text3:
        'Sobald Ihre Bestellung versendet wird, erhalten Sie eine Tracking-ID per E-Mail, mit der Sie den Lieferstatus verfolgen können.',
      buttonText: 'Zur Bestellübersicht',
    },
    account: {
      title: 'Vervollständigen Sie jetzt Ihr Konto, um den Status Ihrer Bestellung nachzuverfolgen.',
      email: 'E-Mailadresse*',
      password: {
        password: 'Passwort*',
        note: 'Zu Ihrer Sicherheit müssen Passwörter mind. 8 Zeichen lang sein und aus Buchstaben und mind. einer Zahl bestehen.',
        confirmation: 'Passwort wiederholen*',
      },
      newsletter: {
        part1: 'Ja, ich möchte über Trends, Aktionen & Gutscheine per E-Mail informiert werden.',
        part2: 'Die Abmeldung ist jederzeit möglich.',
      },
      buttonText: 'Kostenlos Registrieren',
    },
    steps: {
      one: {
        title: '1. Bestellbestätigung per E-Mail',
        text: 'Sie erhalten Ihre Bestellbestätigung und Ihre Bestellübersicht per E-Mail an die von Ihnen angegebene E-Mailadresse.',
      },
      two: {
        title: '2. Bearbeitung und Versandvorbereitung',
        text: 'Ihre Bestellung wird in unserem Logistikzentrum verarbeitet und für den Transport sicher verpackt.',
      },
      three: {
        title: '3. Versandbestätigung per E-Mail',
        text: 'Sobald Ihre Bestellung versendet wird, erhalten Sie eine Tracking-ID per E-Mail, mit der Sie den Lieferstatus verfolgen können.',
      },
    },
    orderNumber: 'Ihre Auftragsnummer lautet <strong>{0}</strong>.',
    orderNumbers: 'Ihre Auftragsnummern lauten: <strong>{0}</strong>.',
    orderNumberFinancing: 'Ihre vorläufige Auftragsnummer lautet <strong>{0}</strong>.',
    message: {
      message:
        'In Kürze senden wir Ihnen eine Eingangsbestätigung Ihrer Bestellung an die von Ihnen angegebene E-Mail-Adresse <strong>{0}</strong>.<br>Dort erhalten Sie noch einmal alle Bestelldaten in der Übersicht.',
      financingStepsHeadline: 'Jetzt sind es nur noch wenige Schritte bis zur Finanzierung Ihrer Bestellung:',
      financing:
        'Sie werden in wenigen Sekunden auf die Finanzierungsseite der Santander Consumer Bank weitergeleitet. Sollten Sie nicht automatisch weitergeleitet werden, nutzen Sie bitte den Button "zur Finanzierung".',
      financing2:
        'Nach erfolgreicher Prüfung Ihrer Daten erhalten Sie eine E-Mail mit Ihrer Bestellbestätigun Ihres Auftrags.',
    },
    trackingIdMessage:
      'Sobald Ihre Bestellung versendet wird, erhalten Sie eine E-Mail mit Ihrer Tracking ID,<br> mit der Sie den Lieferstatus verfolgen können.',
    pickupMessage: 'In der Regel ist die Ware in der nächsten halben Stunde abholbereit.',
    title: {
      delivery: 'Ihre Bestellinformationen',
      account: 'Kundenkonto Anlegen und Vorteile Sichern',
      steps: 'So geht es weiter',
      unkown: 'Liebe/r Frau/Herr {0},',
      company: 'Liebe/r Frau/Herr {0},',
      mrs: 'Liebe Frau {0},',
      mr: 'Lieber Herr {0},',
    },
    financing: 'Zur Finanzierung',
    pickuponline: {
      title: 'ONLINEKAUF & FILIALABHOLUNG - WIE GEHT ES WEITER?',
      topleft: {
        imgAlt: 'Markt abholen',
        text: 'Sie erhalten von uns eine Auftragsbestätigung',
      },
      topright: {
        imgAlt: 'Bestellung prüfen',
        text: '@TODO@ TDB - Wir prüfen Ihre Bestellung und bereiten den Versand vor',
      },
      bottomleft: {
        imgAlt: 'Versandbestätigung,',
        text: '@TODO@ TDB - Sie erhalten eine Versandbestätigung sobald',
      },
      bottomright: {
        imgAlt: 'Paketverfolung',
        text: '@TODO@ TDB - Verfolgen Sie anhand der Trackingnummer Ihr Paket',
      },
    },
    pickup: {
      title: 'FILIALABHOLUNG - WIE GEHT ES WEITER?',
      topleft: {
        img: 'auftragsbestaetigung.svg',
        imgAlt: 'Auftragsbestätigung',
        text: 'Sie erhalten von uns eine Auftragsbestätigung und darin enthalten auch eine Bestätigung, dass Ihre Bestellung zur Abholung bereit steht',
      },
      topright: {
        img: 'bestellung_pruefen.svg',
        imgAlt: 'Bestellung prüfen',
        text: 'Wir prüfen Ihre Bestellung und bereiten die Abholung vor',
      },
      bottomleft: {
        img: 'versandbenachrichtigung.svg',
        imgAlt: 'Zur Abholung bereit,',
        text: 'Sie erhalten eine Bestätigung sobald ihre Bestellung zur Abholung bereit steht',
      },
      bottomright: {
        img: 'confirmation_markt_abholen.svg',
        imgAlt: 'Abholung',
        text: 'Holen Sie Ihre Ware im Markt ab',
      },
    },
    online: {
      title: 'ONLINEKAUF - WIE GEHT ES WEITER?',
      topleft: {
        img: 'auftragsbestaetigung.svg',
        imgAlt: 'Auftragsbestätigung',
        text: 'Sie erhalten von uns eine Auftragsbestätigung',
      },
      topright: {
        img: 'bestellung_pruefen.svg',
        imgAlt: 'Bestellung prüfen',
        text: 'Wir prüfen Ihre Bestellung und bereiten den Versand vor',
      },
      bottomleft: {
        img: 'versandbenachrichtigung.svg',
        imgAlt: 'Versandbestätigung',
        text: 'Sie erhalten eine Versandbestätigung sobald der Versand erfolgt ist',
      },
      bottomright: {
        img: 'trackingnummer.svg',
        imgAlt: 'Paketverfolung',
        text: 'Verfolgen Sie anhand der Trackingnummer Ihr Paket',
      },
    },
  },
  bossGuestCheckoutLogin: {
    title: 'Als Gast bestellen',
    continueToPayment: 'Weiter zur Bezahlung',
    phone: 'Telefonnummer für Rückfragen',
    phoneLabel: 'Telefonnummer',
    phoneShort: 'Telefonnummer',
    contactDetails: 'Kontaktdaten',
    guestPrivacy: {
      part1: 'Ja, ich habe die ',
      part2: 'Datenschutzerklärung',
      part3:
        ' zur Kenntnis genommen und bin damit einverstanden, dass die von mir angegebenen Daten elektronisch erhoben und gespeichert werden.',
      part4:
        'Meine Daten werden dabei nur streng zweckgebunden zur Bearbeitung und Beantwortung meiner Anfrage benutzt.',
      part5: 'Mit dem Absenden der Bestellung erkläre ich mich mit der Verarbeitung einverstanden.',
    },
    countryDeliveryAlert: {
      part1: 'Bitte beachte:',
      part2: 'Der Versand ist nur innerhalb Deutschlands möglich!',
    },
    deliveryAsShippingAddress: 'obenstehende Lieferaddresse als Rechnungsadresse übernehmen',
  },
  checkoutProgress: {
    shippingAddress: 'Deine Daten',
    paymentDetails: 'Bezahlen',
    reviewOrder: 'Übersicht',
  },
  checkoutOrderConfirmation: {
    confirmationOfOrder: 'Bestätigung der Bestellung:',
    thankYou: 'Vielen Dank für Ihre Bestellung!',
    invoiceHasBeenSentByEmail: 'Eine Rechnung wurde bei E-Mail geschickt. Sie sollten sie bald erhalten.',
    orderItems: 'Bestellungs-Artikel',
    orderPlacedSuccessfully: 'Bestellung erfolgreich aufgegeben',
    createAccount: 'Benutzerkonto anlegen?',
    createAccountForNext:
      'Erstellen Sie ein Benutzerkonto für <{{email}}> für einen schnelleren Bezahlvorgang bei Ihrem nächsten Besuch.',
    placedOn: 'Aufgegeben am',
    status: 'Status',
    active: 'Aktiv',
    cancelled: 'Storniert',
    frequency: 'Frequenz',
    nextOrderDate: 'Nächstes Bestelldatum',
    orderNumber: 'Bestellnummer',
  },
  bossCheckoutPaymentDetails: {
    selectPaymentMethod: 'Bitte wähle deine Zahlungsart',
    sum: 'Summe',
    toOrderPreview: 'Weiter zur Bestellübersicht',
    payPaypal: 'Per PayPal bezahlen',
    descPaypal:
      'Für die Bearbeitung der Zahlung per Paypal werden Sie nach Abschluss der Bestellung auf die Seite unseres Zahlungspartners Saferpay weitergeleitet. Die Weiterleitung kann bis zu 30 Sekunden dauern. Bitte haben Sie Geduld.',
    paySofort: 'Sofort',
    descSofort:
      'Sie werden am Ende Ihres Bestellvorgangs für die Zahlungsabwicklung direkt zu sofort.com weitergeleitet. Hier benötigen Sie Ihre Online-Banking- Daten (PIN und TAN).',
    payCreditCard: 'Kreditkarte',
    descCreditCard:
      'Für die Bearbeitung der Zahlung per Kreditkarte werden Sie nach Abschluss der Bestellung auf die Seite unseres Zahlungspartners Saferpay weitergeleitet. Die Weiterleitung kann bis zu 30 Sekunden dauern. Bitte haben Sie Geduld.',
    giftCard: {
      title: 'Geschenkgutschein?',
      desc: 'Wenn du eine Geschenkkarte einlösen möchtest, dann gib bitte den 19-stelligen Code deiner Geschenkkarte und den dazugehörigen PIN-Code ein.',
      redeem: 'Einlösen',
      cardCode: '19-stelliger Code deiner Geschenkkarte',
      cardCodePlaceholder: '19-stelliger Code',
      cardPIN: 'PIN deiner Geschenkkarte',
      cardPINPlaceholder: 'PIN',
      creditBalance: 'Guthaben Ihrer Geschenkkarte',
      invoiceAmount: 'Rechnungsbetrag',
      newSaldo: 'Neues Guthaben',
      updatedInvoice: 'aktualisierter Rechnungsbetrag',
      choosePaymentMethod:
        'Bitte wählen Sie eine der oben aufgeführten Zahlungsarten, um den verbleibenden Rechnungsbetrag zu bezahlen.',
      abord: 'Abbrechen',
      proceedToCheckout:
        'Nach dem Kaufabschluss wird der Gesamtbetrag von Ihrer Geschenkkarte abgezogen. Bitte gehen Sie weiter zur Bestellübersicht um Ihren Kauf abzuschließen.',
      orderOverview: 'Zur Bestellübersicht',
      desiredAmount: 'Dein Wunschbetrag in €',
      desiredAmountPlaceholder: 'Dein Wunschbetrag',
      addGiftCard: 'Einlösen',
      tooHighAmount:
        'Der anzuwendende Betrag des Geschenkgutscheins ist höher als der Rechnungsbetrag. Bitte geben Sie einen Betrag kleiner oder gleich des Rechnungsbetrags an.',
      incorrectPinOrCode: 'Pin oder Code deines Gutscheins ist nicht korrekt',
    },
  },
};
