import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BossInfoLineComponent } from './boss-info-line.component';
import { BossIconDirectiveModule } from '../../directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [BossInfoLineComponent],
  imports: [CommonModule, BossIconDirectiveModule],
  exports: [BossInfoLineComponent],
})
export class BossInfoLineComponentModule {}
