export enum BossFilterCodes {
  COLOR = 'color',
  SALE = 'sale',
  SURFACE = 'surface',
  MATERIAL = 'material',
  MESH_NAME = 'meshName',
  STYLE = 'style',
  LENGTH = 'length',
  WIDTH_SLIDER = 'widthSlider',
  PRICE_SLIDER = 'priceSlider',
  HEIGHT_SLIDER = 'heightSlider',
  DEPTH_SLIDER = 'depthSlider',
}
