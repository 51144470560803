import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { BossProductCardComponentModule } from '../product/product-list/product-card/product-card.module';
import { BossWeeklyOfferComponent } from './weekly-offer.component';
import { BossCarouselBaseModule } from '../../shared/components/boss-carousel-base/boss-carousel-base.module';
import { BossProductCarouselTemplateModule } from '../../shared/components/boss-product-carousel-template/boss-product-carousel-template.module';
import { BossDialogWrapperModule } from '../../shared/components/dialog/dialog-wrapper/boss-dialog-wrapper.module';

@NgModule({
  imports: [
    CommonModule,
    BossProductCardComponentModule,
    BossCarouselBaseModule,
    BossProductCarouselTemplateModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductSliderComponent: {
          component: BossWeeklyOfferComponent,
        },
      },
    }),
    BossDialogWrapperModule,
  ],
  declarations: [BossWeeklyOfferComponent],
  exports: [BossWeeklyOfferComponent],
})
export class BossWeeklyOfferComponentModule {}
