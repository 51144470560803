import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossModalCloseButtonComponent } from './boss-modal-close-button.component';
import { BossIconDirectiveModule } from '../../directives/icon/boss-icon.directive.module';
import { I18nModule } from '@spartacus/core';

@NgModule({
  imports: [CommonModule, BossIconDirectiveModule, I18nModule],
  declarations: [BossModalCloseButtonComponent],
  exports: [BossModalCloseButtonComponent],
})
export class BossModalCloseButtonModule {}
