<div class="modal-header">
  <span class="modal-title">Energieeffizienzklasse</span>
  <boss-modal-close-button></boss-modal-close-button>
</div>

<div *ngIf="data" class="modal-body">
  <div class="modal-text" *ngIf="data.product?.title || data.energyEfficiency?.displayName">
    <div *ngIf="data.energyEfficiency?.displayName"
      ><strong>{{ data.energyEfficiency.displayName }}</strong></div
    >
    <div *ngIf="data.product?.title">{{ data.product?.title }}</div>
  </div>

  <img [src]="transformImageUrl(data.energyEfficiency?.documentUrl)" alt="Energie Label" />
</div>
