import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BossSimpleCollapseComponent } from './boss-simple-collapse.component';
import { BossIconDirectiveModule } from '../../directives/icon/boss-icon.directive.module';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [BossSimpleCollapseComponent],
  imports: [CommonModule, BossIconDirectiveModule, I18nModule],
  exports: [BossSimpleCollapseComponent],
})
export class BossSimpleCollapseComponentModule {}
