export enum ENERGY_LABEL_TYPE {
  OLD_ENERGY_LABEL_A_2 = 'OLD_ENERGY_LABEL_A_2',
  OLD_ENERGY_LABEL_A_1 = 'OLD_ENERGY_LABEL_A_1',
  OLD_ENERGY_LABEL_A = 'OLD_ENERGY_LABEL_A',
  OLD_ENERGY_LABEL_B = 'OLD_ENERGY_LABEL_B',
  OLD_ENERGY_LABEL_C = 'OLD_ENERGY_LABEL_C',
  OLD_ENERGY_LABEL_D = 'OLD_ENERGY_LABEL_D',
  NEW_ENERGY_LABEL_A = 'NEW_ENERGY_LABEL_A',
  NEW_ENERGY_LABEL_B = 'NEW_ENERGY_LABEL_B',
  NEW_ENERGY_LABEL_C = 'NEW_ENERGY_LABEL_C',
  NEW_ENERGY_LABEL_D = 'NEW_ENERGY_LABEL_D',
  NEW_ENERGY_LABEL_E = 'NEW_ENERGY_LABEL_E',
  NEW_ENERGY_LABEL_F = 'NEW_ENERGY_LABEL_F',
  NEW_ENERGY_LABEL_G = 'NEW_ENERGY_LABEL_G',
}
