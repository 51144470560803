import { SimpleChange, SimpleChanges } from '@angular/core';

export const isIOS = (): boolean => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

export const getUniqueClass = (className: string): string => {
  const date = new Date().getTime();
  return `${className}-${date}`;
};

// eslint-disable-next-line
export const bossSimpleChangeFactory = (key: any, currentValue: any, previousValue: any = null): SimpleChanges => ({
  [key]: new SimpleChange(previousValue, currentValue, false),
});
