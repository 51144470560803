import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  CmsService,
  SemanticPathService,
  ProtectedRoutesService,
  AuthRedirectService,
  GlobalMessageService,
  TranslationService,
  GlobalMessageType,
} from '@spartacus/core';
import { LogoutGuard } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BossLogoutGuard extends LogoutGuard implements OnDestroy {
  private subscription = new Subscription();

  constructor(
    auth: AuthService,
    cms: CmsService,
    semanticPathService: SemanticPathService,
    protectedRoutes: ProtectedRoutesService,
    router: Router,
    authRedirectService: AuthRedirectService,
    private globalMessageService: GlobalMessageService,
    private translationService: TranslationService,
  ) {
    super(auth, cms, semanticPathService, protectedRoutes, router, authRedirectService);
  }

  protected logout(): Promise<void> {
    this.subscription.add(
      this.translationService.translate('bossUserAccount.logoutConfirmation').subscribe((text: string) => {
        this.globalMessageService.add(text, GlobalMessageType.MSG_TYPE_INFO);
      }),
    );

    return super.logout();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
