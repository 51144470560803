import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WindowRef } from '@spartacus/core';
import { bossIconConfig } from '../../../shared/utils/boss-icon-config';
import { BossScrolledDownService } from '../../../features/product/boss-scrolled-down.service';

@Component({
  selector: 'boss-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossBackToTopComponent implements OnInit, OnDestroy {
  windowScrolled: boolean;

  bossIconConfig = bossIconConfig;

  private subscription = new Subscription();

  constructor(
    private bossScrolledDownService: BossScrolledDownService,
    private winRef: WindowRef,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.winRef.isBrowser()) {
      this.subscription.add(
        this.bossScrolledDownService.isScrolledDown().subscribe((isScrolled) => {
          this.windowScrolled = isScrolled;

          this.cdRef.detectChanges();
        }),
      );
    }
  }

  scrollToTop(): void {
    if (this.winRef.isBrowser()) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
