import { AuthConfig } from '@spartacus/core';
import { environment } from '../../../environments/environment';

export const bossAuthConfig: AuthConfig = {
  authentication: {
    client_id: environment.occClientUser,
    client_secret: environment.occClientSecret,
    tokenEndpoint: '/oauth/token',
    revokeEndpoint: '/oauth/revoke',
    loginUrl: '/oauth/authorize',
    OAuthLibConfig: {
      scope: '',
      customTokenParameters: ['token_type'],
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true,
      disablePKCE: true,
      oidc: false,
      clearHashAfterLogin: false,
    },
  },
};
