import { translationChunksConfig, TranslationChunksConfig } from '@spartacus/assets';

export const bossTranslationChunks: TranslationChunksConfig = {
  ...translationChunksConfig,
  forms: ['generalForm', 'bossContactForm', 'bossMyAccountServiceForm'],
  user: [
    ...(translationChunksConfig ? [...translationChunksConfig.user] : []),
    'newCustomerLogin',
    'returningCustomerLogin',
    'bossForgottenPassword',
    'resetPassword',
  ],
  common: [
    ...(translationChunksConfig ? [...translationChunksConfig.common] : []),
    'bossMiniCart',
    'bossUserAccount',
    'bossMyMarket',
    'bossSearchBox',
    'bossWishListIcon',
    'bossNewsletter',
    'bossNewsletterTeaser',
    'bossSocialFooter',
    'bossNavHeader',
    'bossCheckoutHeader',
    'bossCheckoutFooter',
    'bossProspect',
    'bossGeneral',
    'bossMyAccountTracking',
    'bossMyAccount',
    'bossBreadcrumb',
    'bossProduct',
    'bossBackToTop',
    'bossKitchenPlanner',
  ],
  cart: [...(translationChunksConfig ? [...translationChunksConfig.cart] : []), 'bossCart'],
  checkout: [
    ...(translationChunksConfig ? [...translationChunksConfig.checkout] : []),
    'bossDeliveryAddress',
    'bossReviewOrder',
    'bossOrderConfirmation',
    'bossGuestCheckoutLogin',
    'bossCheckoutPaymentDetails',
  ],
  product: [
    ...(translationChunksConfig ? [...translationChunksConfig.product] : []),
    'bossProductCarousel',
    'bossProductCard',
    'bossProductSwatches',
  ],
};
