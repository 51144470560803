import { Price, Product } from '@spartacus/core';

export enum BossProductVariantLabel {
  Color = 'Farbe',
  Type = 'Ausführung',
  Material = 'Material',
  Size = 'Größe',
}

export interface BossProductVariant {
  disabled?: boolean;
  selected?: boolean;
  name?: string;
  product?: Product;
  id?: string;
}

export interface BossProductVariantColor extends BossProductVariant {
  colorTileUrl?: string;
  exaCode?: string;
  colorHexCode?: string;
  colorHexCodeList?: string[];
}

export interface BossProductVariantEffectuation extends BossProductVariant {}

export interface BossProductVariantMaterial extends BossProductVariant {}

export interface BossProductVariantSize extends BossProductVariant {}

export interface BossProductVariants {
  colorVariants?: BossProductVariantColor[];
  effectuationVariants?: BossProductVariantEffectuation[];
  materialVariants?: BossProductVariantMaterial[];
  sizeVariants?: BossProductVariantSize[];
}

export interface EnergyEfficiency {
  classificationCode?: string;
  classificationName?: string;
  displayName?: string;
  documentUrl?: string;
  energyClass?: string;
}

export interface ProductDocument {
  altText: string;
  mime: string;
  url: string;
}

export interface CrossedPrice extends Price {
  discountRate?: number;
}

export interface ProductStatusLabel {
  classNames: string;
  label: string;
}

export enum StatusItems {
  SALE = 'SALE',
  NEW = 'NEU',
  ONLY_ONLINE = 'NUR ONLINE',
  AR_3D = 'AR/3D',
}
