import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BossScrollPositionService } from './boss-scroll-position.service';

@NgModule({
  providers: [
    BossScrollPositionService,
    {
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: BossScrollPositionService.factory,
      deps: [BossScrollPositionService],
    },
  ],
})
export class BossScrollPositionFeatureModule {}
