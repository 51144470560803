import { Injectable } from '@angular/core';
import { CmsService, Page, PageMeta, PageMetaService } from '@spartacus/core';
import { SchemaBuilder } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BossBreadcrumbSchemaBuilder implements SchemaBuilder {
  constructor(private pageMetaService: PageMetaService, private cms: CmsService) {}

  // eslint-disable-next-line
  build(): Observable<any> {
    return combineLatest([this.pageMetaService.getMeta(), this.cms.getCurrentPage()]).pipe(
      map(([pageMeta, page]) => {
        return this.collect(pageMeta, page);
      }),
    );
  }

  // eslint-disable-next-line
  protected collect(pageMeta: PageMeta, page: Page): any {
    if (page?.template === 'StartPageTemplateBoss') {
      return {};
    }

    if (!pageMeta?.breadcrumbs) {
      return {};
    }

    const crumbs = pageMeta.breadcrumbs.map((crumb, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': crumb.link ? crumb.link : crumb.label,
          name: crumb.label,
        },
      };
    });

    if (pageMeta.title) {
      crumbs.push({
        '@type': 'ListItem',
        position: crumbs.length + 1,
        item: {
          '@id': pageMeta.title,
          name: pageMeta.title,
        },
      });
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: crumbs,
    };
  }
}
