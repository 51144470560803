import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  GlobalMessageService,
  GlobalMessageType,
  InterceptorUtil,
  OccEndpointsService,
  PointOfService,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, filter, map } from 'rxjs/operators';
import { BossContactForm } from '../../shared/models/contact-form';

@Injectable()
export class BossContactFormService {
  constructor(
    private occEndpoints: OccEndpointsService,
    private http: HttpClient,
    private globalMessageService: GlobalMessageService,
  ) {}

  submitData(formData: BossContactForm): Observable<Object> {
    const headers = this.getHeaders(),
      url = this.occEndpoints.buildUrl('service/contactForm');

    return this.http.post(url, formData, { headers }).pipe(
      map((response) => {
        this.showMessageSuccess();
        return response;
      }),
      catchError(() => {
        this.showMessageError();
        return of('');
      }),
      filter((response) => !!response),
    );
  }

  getPos(onlineOption: boolean = false): Observable<PointOfService[]> {
    const url = this.occEndpoints.buildUrl('pos?fields=BASIC&includeOnlineShop=' + onlineOption);
    return this.http.get(url).pipe(map((data: object) => data['pointOfServices']));
  }

  showMessageSuccess(): void {
    this.globalMessageService.add({ key: 'bossContactForm.submit.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
  }

  showMessageError(): void {
    this.globalMessageService.add({ key: 'bossContactForm.submit.error' }, GlobalMessageType.MSG_TYPE_ERROR);
  }

  protected getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);

    return headers;
  }
}
