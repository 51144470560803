import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideConfig({
      featureModules: {
        'boss-components': {
          module: () => import('./boss-components.module').then((m) => m.BossComponentsModule),
          cmsComponents: [
            'ListModuleComponent',
            'AccordionComponent',
            'CMSImageComponent',
            'CMSImageLinkComponent',
            'ContactFormComponent',
            'BigTextGridComponent',
            'CategoryOptionalSEOComponent',
            'CategoryPageTeaserProductGroup2ColumnsComponent',
            'ContentPageNavigationComponent',
            'ImageBoardContainerComponent',
            'TeaserProductGroupLargeComponent',
            'TeaserProductGroupSmallComponent',
            'countdown22',
            'ProductTileInspiration',
            'BossNewsletterRegistrationComponent',
            'ProspectComponent',
            'ResponsiveImageBannerComponent',
            'gewinnspiel2022',
            'ScriptComponent',
            'ServiceTeaserComponent',
            'SolrCategoryProductGridComponent',
            'TextPictureComponent',
            'UspComponent',
          ],
        },
      },
    } as CmsConfig),
  ],
})
export class BossComponentsFeatureModule {}
