import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BossDatePickerService } from '../components/boss-date-picker/boss-date-picker.service';
import { BossCustomPatternConfig } from './custom-pattern-config.model';

@Injectable({
  providedIn: 'root',
})
export class BossValidatorService {
  constructor(private bossDatePickerService: BossDatePickerService) {}

  get maxBirthdayDate(): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 16);

    return date.toISOString().split('T')[0];
  }

  birthdayValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '') return null;

      if (control.value !== '') {
        const dateRegex = this.bossDatePickerService.patternRegex;

        if (dateRegex.test(control.value)) {
          const maxDateTimeStamp = new Date(this.maxBirthdayDate).getTime();
          const controlValueTimeStamp = new Date(control.value).getTime();

          return controlValueTimeStamp > maxDateTimeStamp ? { birthday: true } : null;
        } else {
          return {
            pattern: true,
          };
        }
      }

      return null;
    };
  }

  nameValidator(msg: string = 'cxInvalidFormat'): ValidatorFn {
    const pattern = /^[a-zA-ZäöüßÄÖÜẞ_ -]+$/;

    return this.customPatternValid({ pattern, msg });
  }

  customPatternValid(config: BossCustomPatternConfig): ValidatorFn {
    const { pattern, msg }: BossCustomPatternConfig = config;

    return (control: FormControl): ValidationErrors | null =>
      control.value && !control.value?.toString().match(pattern) ? { [msg]: true } : null;
  }

  /**
   * makes the field required if the predicate function returns true
   */
  customIfValidator(predicate: (control?: AbstractControl) => boolean, childValidator: ValidatorFn): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null =>
      formControl.parent && predicate(formControl) ? childValidator(formControl) : null;
  }

  currencyValidator(msg: string = 'invalid'): ValidatorFn {
    return this.customPatternValid({
      pattern: /^\d*,?\d+$/,
      msg,
    });
  }

  phoneValidator(msg: string = 'bossInvalidPhoneNumber'): ValidatorFn {
    return this.customPatternValid({
      pattern: /^\+?\d{8,15}$/,
      msg,
    });
  }

  postalCodeValidator(msg = 'cxInvalidFormat'): ValidatorFn {
    return this.customPatternValid({
      pattern: /^\d+$/g,
      msg,
    });
  }

  streetNumberValidator(msg = 'cxInvalidFormat'): ValidatorFn {
    const pattern = /^[1-9]/g;

    return this.customPatternValid({
      pattern,
      msg,
    });
  }

  compareSize(
    controlNameToCompare: string,
    config?: { smallerThan?: boolean; range?: { min: number; max: number } },
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.parent) {
        return null;
      }
      const thisValue = control.value,
        otherValue = control.parent.get(controlNameToCompare).value,
        checkSize = config?.smallerThan ? thisValue <= otherValue : thisValue >= otherValue,
        checkRange = config?.range ? thisValue <= config.range.max && thisValue >= config.range.min : true;

      if (checkSize && checkRange) {
        return null;
      }

      return {
        invalidEntry: true,
      };
    };
  }
}
