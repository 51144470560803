import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossUserAccountPageTitleComponent } from './boss-user-account-page-title.component';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [BossUserAccountPageTitleComponent],
  imports: [CommonModule],
  exports: [BossUserAccountPageTitleComponent],
  providers: [
    provideConfig({
      cmsComponents: {
        PageTitle: {
          component: () =>
            import(
              /* webpackChunkName: "boss-my-account" */
              './boss-user-account-page-title.component'
            ).then((m) => m.BossUserAccountPageTitleComponent),
        },
      },
    } as CmsConfig),
  ],
})
export class BossUserAccountPageTitleModule {}
