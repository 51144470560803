<ng-container *ngIf="items?.length">
  <div class="boss-pills container">
    <swiper [config]="swiperConfig" [ngClass]="{ hidden: !isMobile }">
      <ng-template swiperSlide *ngFor="let item of items; index as i; trackBy: trackById">
        <boss-pill [item]="item"></boss-pill>
      </ng-template>
    </swiper>

    <ng-container *ngIf="!isMobile">
      <ng-container *ngFor="let item of items | slice: 0:pillsToDisplay; trackBy: trackById">
        <boss-pill [item]="item"></boss-pill>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="(!isMobile && items.length > pillsToDisplay) || isExpanded">
      <ng-container *ngIf="pillsToDisplay !== items.length; else showLessTemplate">
        <a class="boss-pills-item boss-pills-icon-only" (click)="showMore()">
          <i bossIcon aria-hidden="true" [type]="addIcon"></i>
        </a>
      </ng-container>

      <ng-template #showLessTemplate>
        <a class="boss-pills-item boss-pills-icon-only" (click)="showLess()">
          <i bossIcon aria-hidden="true" [type]="subtractIcon"></i>
        </a>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
