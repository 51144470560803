import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { FormErrorsModule, LoginFormModule } from '@spartacus/storefront';
import { BossReturningCustomerLoginComponent } from './returning-customer-login.component';
import { BossShowPasswordModule } from '../../shared/components/boss-show-password/boss-show-password.module';
import { BossDialogWrapperModule } from '../../shared/components/dialog/dialog-wrapper/boss-dialog-wrapper.module';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturningCustomerLoginComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-login" */
              './returning-customer-login.component'
            ).then((m) => m.BossReturningCustomerLoginComponent),
        },
        ReturningCustomerCheckoutLoginComponent: {
          component: () =>
            import(
              /* webpackChunkName: "boss-login" */
              './returning-customer-login.component'
            ).then((m) => m.BossReturningCustomerLoginComponent),
        },
      },
    }),
    LoginFormModule,
    I18nModule,
    FormErrorsModule,
    FormsModule,
    ReactiveFormsModule,
    BossShowPasswordModule,
    BossIconDirectiveModule,
    BossDialogWrapperModule,
  ],
  declarations: [BossReturningCustomerLoginComponent],
  exports: [BossReturningCustomerLoginComponent],
})
export class BossReturningCustomerLoginComponentModule {}
