import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, UrlModule } from '@spartacus/core';
import { PageComponentModule } from '@spartacus/storefront';
import { BossTwoColumnComponent } from './two-column.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    PageComponentModule,
    ConfigModule.withConfig({
      cmsComponents: {
        TwoColumnComponentContainer: {
          component: () =>
            import(
              /* webpackChunkName: "boss-shared" */
              './two-column.component'
            ).then((m) => m.BossTwoColumnComponent),
        },
      },
    }),
  ],
  declarations: [BossTwoColumnComponent],
  exports: [BossTwoColumnComponent],
})
export class BossTwoColumnsModule {}
