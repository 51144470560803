import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule, SpinnerModule } from '@spartacus/storefront';
import { BossProductImagesComponent } from './product-images.component';
import { BossCarouselBaseModule } from '../../../../shared/components/boss-carousel-base/boss-carousel-base.module';
import { BossMediaDirectiveModule } from '../../../../shared/directives/media/boss-media.directive.module';
import { BossIconDirectiveModule } from '../../../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  imports: [
    CommonModule,
    MediaModule,
    SpinnerModule,
    BossMediaDirectiveModule,
    BossCarouselBaseModule,
    BossIconDirectiveModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductImagesComponent: {
          component: BossProductImagesComponent,
        },
      },
    }),
    I18nModule,
  ],
  declarations: [BossProductImagesComponent],
  exports: [BossProductImagesComponent],
})
export class BossProductImagesComponentModule {}
