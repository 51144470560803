import { Injectable, OnDestroy } from '@angular/core';
import {
  BreadcrumbMeta,
  PageBreadcrumbResolver,
  PageMetaResolver,
  PageType,
  TranslationService,
} from '@spartacus/core';
import { Observable, of, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BossCartPageMetaResolver extends PageMetaResolver implements PageBreadcrumbResolver, OnDestroy {
  cartLabel = '';

  private subscription = new Subscription();

  constructor(private translation: TranslationService) {
    super();
    this.pageTemplate = 'CartPageTemplate';
    this.pageType = PageType.CONTENT_PAGE;
    this.subscription.add(
      this.translation.translate('bossCart.breadcrumbs.title').subscribe((label) => {
        this.cartLabel = label;
      }),
    );
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    const breadcrumbs = [];

    breadcrumbs.push({
      label: this.cartLabel,
      link: null,
    } as BreadcrumbMeta);

    return of(breadcrumbs);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
