import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { TooltipBase } from '../../shared/utils/tooltip.base';
import { takeUntil } from 'rxjs/operators';
import { bossIconConfig } from '../../shared/utils/boss-icon-config';

@Component({
  selector: 'boss-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossUserAccountComponent extends TooltipBase implements OnInit, OnDestroy {
  myAccountRoute = '/my-account/update-profile';

  isLoggedIn: Observable<boolean> = this.auth.isUserLoggedIn();
  onDestroy$ = new Subject<void>();
  bossIconConfig = bossIconConfig;

  constructor(
    private breakpoint: BreakpointService,
    private auth: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(breakpoint);
  }

  ngOnInit(): void {
    this.breakpoint
      .isDown(BREAKPOINT.sm)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isMobile: boolean) => {
        this.myAccountRoute = this.getMyAccountRoute(isMobile);
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getMyAccountRoute(isMobile: boolean): string {
    return isMobile ? '/my-account' : '/my-account/update-profile';
  }
}
