import { Injectable } from '@angular/core';
import { Product } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class BossProductStatusService {
  hasStatus(product: Product): boolean {
    return !!(
      product &&
      (product?.saleProduct || product?.newProduct || product?.onlyOnlineProduct || product?.augmentedRealityID)
    );
  }
}
