<ng-container *ngIf="data$ | async as data">
  <cx-generic-link
    *ngIf="!callback; else button"
    [url]="data.url | bossDecodeUri"
    [style]="data.styleAttributes"
    [target]="getTarget(data)"
    >{{ data.linkName }}</cx-generic-link
  >

  <ng-template #button>
    <button (click)="callback()" type="button">{{ data.linkName }}</button>
  </ng-template>
</ng-container>
