import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossDatePickerComponent } from './boss-date-picker.component';
import { I18nModule } from '@spartacus/core';
import { DatePickerService, FormErrorsModule } from '@spartacus/storefront';
import { ReactiveFormsModule } from '@angular/forms';
import { BossDatePickerService } from './boss-date-picker.service';

@NgModule({
  declarations: [BossDatePickerComponent],
  imports: [CommonModule, ReactiveFormsModule, FormErrorsModule, I18nModule],
  exports: [BossDatePickerComponent],
  providers: [
    {
      provide: DatePickerService,
      useClass: BossDatePickerService,
    },
  ],
})
export class BossDatePickerModule {}
