import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { bossIconConfig } from '../../../shared/utils/boss-icon-config';

interface NavigationButtonClasses {
  prev: string;
  next: string;
}

@Component({
  selector: 'boss-product-carousel-template',
  templateUrl: './boss-product-carousel-template.component.html',
  styleUrls: ['./boss-product-carousel-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossProductCarouselTemplateComponent {
  @Input() quantity: number;

  @Input() navigation: NavigationButtonClasses;

  bossIconConfig = bossIconConfig;
}
