import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { BossZipCodeService } from '../../../../shared/services/zip-code/boss-zip-code.service';

@Injectable()
export class BuyBoxCurrentZipService {
  private currentZipCode$ = new ReplaySubject<string>(1);

  constructor(private zipCodeService: BossZipCodeService) {}

  on(): Observable<string> {
    return this.currentZipCode$.asObservable();
  }

  next(zipCode: string): void {
    this.zipCodeService.set(zipCode);
    this.currentZipCode$.next(zipCode);
  }
}
