import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BossMediaDirective } from './boss-media.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [BossMediaDirective],
  exports: [BossMediaDirective],
})
export class BossMediaDirectiveModule {}
