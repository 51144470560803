import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossBackToTopComponent } from './back-to-top.component';
import { BossIconDirectiveModule } from '../../directives/icon/boss-icon.directive.module';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [BossBackToTopComponent],
  imports: [CommonModule, BossIconDirectiveModule, I18nModule],
  exports: [BossBackToTopComponent],
})
export class BossBackToTopModule {}
