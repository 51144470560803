import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BossTooltipComponent } from './boss-tooltip.component';

@NgModule({
  declarations: [BossTooltipComponent],
  imports: [CommonModule],
  exports: [BossTooltipComponent],
})
export class BossTooltipComponentModule {}
