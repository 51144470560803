import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  ActivatedRouterStateSnapshot,
  CurrencyService,
  LanguageService,
  ProductSearchService,
  RouterState,
  RoutingService,
  SearchConfig,
} from '@spartacus/core';
import { ProductListComponentService, SearchCriteria } from '@spartacus/storefront';
import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BossProductListComponentService extends ProductListComponentService {
  defaultPageSize = 60;

  customSearchCriteria: SearchCriteria | null;

  customSearchCriteriaTrigger$: Subject<void> = new Subject();

  constructor(
    productSearchService: ProductSearchService,
    routing: RoutingService,
    activatedRoute: ActivatedRoute,
    currencyService: CurrencyService,
    languageService: LanguageService,
    router: Router,
    private pageSizeStore: Store<any>, // eslint-disable-line
  ) {
    super(productSearchService, routing, activatedRoute, currencyService, languageService, router);
  }

  searchByRouting$: Observable<ActivatedRouterStateSnapshot> = combineLatest([
    this.routing.getRouterState().pipe(
      debounceTime(0),
      map((routerState: RouterState) => routerState?.state),
      distinctUntilKeyChanged('url'),
    ),
    this.pageSizeStore.pipe(select('pageSize')),
    this.customSearchCriteriaTrigger$.pipe(startWith({})),
  ]).pipe(
    // eslint-disable-next-line
    map(([snapshot, size]: [ActivatedRouterStateSnapshot, any, ActivatedRouterStateSnapshot]) => {
      const criteria = this.customSearchCriteria
        ? this.customSearchCriteria
        : this.getCriteriaFromRoute(snapshot.params, {
            ...snapshot.queryParams,
            pageSize: size?.pageSize?.pageSize,
          });

      this.search(criteria);

      // Reset the value so we can still use PLP and search page after route change
      this.customSearchCriteria = null;

      return snapshot;
    }),
  );

  search(criteria: SearchCriteria): void {
    const currentPage = criteria.currentPage ?? 1;
    const pageSize = criteria.pageSize;
    const sort = criteria.sortCode;

    const searchConfig: SearchConfig = {
      ...(+currentPage - 1 && { currentPage: +currentPage - 1 }),
      ...(pageSize && { pageSize }),
      ...(sort && { sort }),
    };

    this.productSearchService.search(criteria.query, searchConfig);
  }

  /**
   * Used to set custom search criteria, which allows product listings outside of PLP and search page to work.
   * Usually search criteria (on PLP and search page) are taken from router info.
   * For pages outside, we need to pass custom criteria, as router has no required information.
   *
   * @param {SearchCriteria} criteria
   */
  setCustomSearchCriteria(criteria: SearchCriteria): void {
    // Due to Spartacus' behavior, we have to split the data and trigger here.
    // There are scenarios where the data is there, but the trigger is not firing and vice versa.
    this.customSearchCriteria = criteria;
    this.customSearchCriteriaTrigger$.next();
  }
}
