import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { bossIconConfig } from '../../utils/boss-icon-config';

@Component({
  selector: 'boss-collapse[headline]',
  templateUrl: './boss-collapse.component.html',
  styleUrls: ['./boss-collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossCollapseComponent {
  @Input() isExpanded = false;

  @Input() headline: string;

  @Output() shouldBeExpanded = new EventEmitter<boolean>();

  bossIconConfig = bossIconConfig;

  constructor(private cdRef: ChangeDetectorRef) {}

  toggle(): void {
    this.isExpanded = !this.isExpanded;
    this.shouldBeExpanded.emit(this.isExpanded);
    this.cdRef.detectChanges();
  }
}
