import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BossContentStageComponent } from './boss-content-stage.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { BossSafePipeModule } from '../../shared/pipes/boss-safe-pipe.module';
import { BossDecodeUriPipeModule } from '../../shared/pipes/encode-uri/boss-decode-uri-pipe.module';
import { RouterModule } from '@angular/router';
import { BossTextLimitPipeModule } from '../../shared/pipes/text-limit/boss-text-limit-pipe.module';
import { BossHeadlineModule } from '../../shared/components/headline/boss-headline.module';
import { BossMediaDirectiveModule } from '../../shared/directives/media/boss-media.directive.module';

@NgModule({
  imports: [
    CommonModule,
    BossMediaDirectiveModule,
    BossSafePipeModule,
    BossDecodeUriPipeModule,
    RouterModule,
    BossHeadlineModule,
    BossTextLimitPipeModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ContentStageComponent: {
          component: BossContentStageComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [BossContentStageComponent],
})
export class BossContentStageModule {}
