import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { BossFacetCheckboxComponent } from './boss-facet-checkbox.component';

@NgModule({
  imports: [CommonModule, RouterModule, I18nModule],
  declarations: [BossFacetCheckboxComponent],
  exports: [BossFacetCheckboxComponent],
})
export class BossFacetCheckboxModule {}
