import { UrlMatchResult, UrlSegment } from '@angular/router';

export function bossCategoryUrlMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length === 0) {
    return null;
  }

  const reg = '(.*)-[cC]([0-9]+)$';
  const param = url.toString();
  const regExpMatchArray = param.match(reg);

  if (regExpMatchArray) {
    const categoryCodeValue = regExpMatchArray[2];
    const categoryCodeSegment: UrlSegment = new UrlSegment(categoryCodeValue, {
      categoryCode: categoryCodeValue,
    });

    return {
      consumed: url,
      posParams: { categoryCode: categoryCodeSegment },
    };
  }
  return null;
}
