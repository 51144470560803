import { CurrentProductService } from '@spartacus/storefront';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PointOfService, Product, RouterState, RoutingService, User, UserService } from '@spartacus/core';
import { BossProductVariant, BossStatusLabel, BossStatusLabelType } from '../../../../shared/models';
import { FormControl, FormGroup } from '@angular/forms';
import { BossDialogContent } from '../../../../shared/components/dialog/boss-dialog-content';
import { BossDialogSize, BossDialogType } from '../../../../shared/components/dialog/boss-dialog.model';
import { BossFinancingCalculatorComponent } from '../../../../shared/components/financing-calculator/boss-financing-calculator.component';
import { BossStoreAvailability } from '../store-availability/store-availability.model';
import { StoreAvailabilityComponent } from '../store-availability/store-availability.component';
import { BossProductPostCodeModalComponent } from '../post-code-dialog/product-post-code-modal.component';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BossProductPriceService } from '../../../product/product-price/services/product-price.service';
import { BossZipCodeService } from '../../../../shared/services/zip-code/boss-zip-code.service';
import { BossDialogService } from '../../../../shared/components/dialog/boss-dialog.service';
import { BossStoreAvailabilityService } from '../store-availability/store-availability.service';
import { BuyBoxCurrentZipService } from './buy-box-current-zip.service';
import { BossProductStatusService } from '../../../product/product-status/boss-product-status.service';
import { BossStockService } from '../../../../shared/services/stock.service';
import { Router } from '@angular/router';
import { bossIconConfig } from '../../../../shared/utils/boss-icon-config';

@Component({
  selector: 'boss-buy-box',
  templateUrl: './buy-box.component.html',
  styleUrls: ['./buy-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossBuyBoxComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  product: Product;

  context: string = this.router.serializeUrl(this.router.createUrlTree([]));

  colorVariants: BossProductVariant[] = [];

  currentZip: string = '';

  addToCartForm = new FormGroup({
    quantity: new FormControl(1),
  });

  selectedPickupStore: string = '';

  hasOtherVariants = false;

  maxReturnAmountCollapsed: boolean = true;

  bossIconConfig = bossIconConfig;

  isStoreAvailable: boolean;

  constructor(
    private currentProductService: CurrentProductService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private stockService: BossStockService,
    private productStatusService: BossProductStatusService,
    private currentZipCodeService: BuyBoxCurrentZipService,
    private storeAvailabilityService: BossStoreAvailabilityService,
    private dialogService: BossDialogService,
    private zipCodeService: BossZipCodeService,
    private routingService: RoutingService,
    private productPriceService: BossProductPriceService,
    private userProfileFacade: UserService,
  ) {}

  ngOnInit(): void {
    // Handle initial load and getting zip code
    this.subscription.add(
      this.userProfileFacade
        .get()
        .pipe(
          withLatestFrom(
            this.routingService.getRouterState().pipe(map((state: RouterState) => state?.state?.params['productCode'])),
          ),
          map(([user, productCode]: [User, string]) => {
            const { defaultAddress, store } = user;
            const sessionStorageZipCode: string = this.zipCodeService.get();
            const userAddressZipCode: string = defaultAddress?.shippingAddress ? defaultAddress?.postalCode : '';
            const selectedStoreZipCode: string = store?.address?.postalCode;

            if (sessionStorageZipCode) {
              this.currentZip = sessionStorageZipCode;
            } else if (userAddressZipCode) {
              this.currentZip = userAddressZipCode;
            } else if (selectedStoreZipCode) {
              this.currentZip = selectedStoreZipCode;
            }

            return productCode;
          }),
          switchMap((productCode: string) =>
            this.stockService.checkStockByZip(
              this.currentZip,
              this.addToCartForm.get('quantity').value,
              this.product?.code || productCode,
            ),
          ),
        )
        .subscribe(() => {
          this.cdRef.detectChanges();
        }),
    );

    this.subscription.add(
      this.currentProductService.getProduct('zipCodeAndQty').subscribe((product) => {
        this.product = product;

        this.colorVariants.splice(
          0,
          this.colorVariants.length,
          ...((product?.allVariants?.colorVariants as BossProductVariant[]) || []),
        );

        this.hasOtherVariants = !!Object.entries(product?.allVariants || {}).filter(
          ([key, variant]) => key !== 'colorVariants' && variant && (variant as BossProductVariant[]).length,
        ).length;

        this.setStoreAvailability();

        this.cdRef.detectChanges();
      }),
    );

    // Handle zip code change
    // For example via off-canvas zip code form
    this.subscription.add(
      this.currentZipCodeService
        .on()
        .pipe(
          withLatestFrom(
            this.routingService.getRouterState().pipe(map((state: RouterState) => state?.state?.params['productCode'])),
          ),
          tap(([currentZip, _]: [string, string]) => {
            this.currentZip = currentZip;
          }),
          switchMap(([currentZip, productCode]: [string, string]) =>
            this.stockService.checkStockByZip(
              currentZip,
              this.addToCartForm.get('quantity').value,
              this.product?.code || productCode,
            ),
          ),
        )
        .subscribe(() => {
          this.cdRef.detectChanges();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openPostCodeModal(): void {
    this.dialogService.open(
      new BossDialogContent(BossProductPostCodeModalComponent, {
        type: BossDialogType.DRAWER,
        isCloseButtonVisible: true,
      }),
    );
  }

  openMarketAvailabilityModal(): void {
    this.dialogService.open(
      new BossDialogContent(StoreAvailabilityComponent, {
        type: BossDialogType.DRAWER,
        isCloseButtonVisible: true,
        data: this.currentZip,
      }),
    );

    this.setProductQuantity();
  }

  setProductQuantity(): void {
    this.storeAvailabilityService.nextProductDetails(
      this.product.code,
      this.addToCartForm.get('quantity').value,
      this.product.price.value,
    );
  }

  get stockColor(): BossStatusLabelType | null {
    // Red when product turned off
    if (!this.product?.purchasable) {
      return BossStatusLabel.DANGER;
    }

    // Green when product click&collect only
    if (
      this.product?.stock?.stockLevelStatus === 'outOfStock' &&
      this.product?.availableForPickup &&
      this.isStoreAvailable
    ) {
      return BossStatusLabel.SUCCESS;
    }

    // When zip code required first and hybrid delivery
    if (this.product?.hybridDeliverySupported && !this.currentZip) {
      return BossStatusLabel.SUCCESS;
    }

    switch (this.product?.onlineStockColor) {
      case 'green':
        return BossStatusLabel.SUCCESS;
      case 'blue':
        return BossStatusLabel.INFO;
      case 'yellow':
        return BossStatusLabel.WARNING;
      case 'red':
        return BossStatusLabel.DANGER;
      default:
        return null;
    }
  }

  get stockLabel(): string {
    // When product turned off
    if (!this.product?.purchasable) {
      return 'Nicht verfügbar';
    }

    // When zip code required first and hybrid delivery
    if (this.product?.hybridDeliverySupported && !this.currentZip) {
      return 'Verfügbar,';
    }

    switch (this.product?.onlineStockColor) {
      case 'green':
        return 'Verfügbar,';
      case 'blue':
        return 'Bestellbar,';
      case 'yellow':
        return 'Wenige verfügbar,';
      case 'red':
        return this.product?.availableForPickup && this.isStoreAvailable
          ? 'Im Möbelhaus verfügbar,'
          : 'Nicht verfügbar,';
      default:
        return '';
    }
  }

  get deliveryTimeString(): string {
    // When product turned off
    if (!this.product?.purchasable) {
      return '';
    }

    // When zip code required first and hybrid delivery
    if (this.product?.hybridDeliverySupported && !this.currentZip) {
      return 'Lieferzeit ist abhängig von deinem Standort';
    }

    // When no stock
    if (this.product?.onlineStockColor === 'red') {
      return 'Verfügbarkeit: Online nicht lieferbar';
    }

    return this.product?.deliveryTime;
  }

  get productMax(): number {
    const allowedMaximum = 99;

    if (this.selectedPickupStore) {
      const store: PointOfService = this.product?.storeAvailability?.find(
        (stock: PointOfService) => stock.name === this.selectedPickupStore,
      );

      if (store?.stockInfo) {
        return Math.min(store.stockInfo.stockLevel, allowedMaximum);
      }
    }

    if (this.product?.stock) {
      return Math.min(this.product?.stock.stockLevel, allowedMaximum);
    }

    return allowedMaximum;
  }

  get isPricedCrossed(): boolean {
    return this.productPriceService.isPriceCrossed(this.product);
  }

  setStoreAvailability(): void {
    const quantity = this.addToCartForm.get('quantity').value;
    let storesWithStock: BossStoreAvailability[] = [];
    let storesWithStockAndDisplay: BossStoreAvailability[] = [];

    if (this.product?.storeAvailability) {
      // Stores with correct stock
      storesWithStock = this.product?.storeAvailability?.filter(
        (store: BossStoreAvailability) => quantity <= store?.stockInfo?.stockLevel,
      );
      // Stores with correct stock and isInDisplay property for further processing
      // isInDisplay is needed in store-availability drawer
      storesWithStockAndDisplay = this.product?.storeAvailability?.filter(
        (store: BossStoreAvailability) => quantity <= store?.stockInfo?.stockLevel || store?.isInDisplay,
      );

      this.storeAvailabilityService.nextStoreAvailability(storesWithStockAndDisplay);
    }

    this.isStoreAvailable = !!storesWithStock.length;
  }

  openFinanceCalculator(): void {
    this.dialogService.open(
      new BossDialogContent(BossFinancingCalculatorComponent, {
        type: BossDialogType.MODAL,
        size: BossDialogSize.XL,
      }),
    );
  }

  get hasStatus(): boolean {
    return this.productStatusService.hasStatus(this.product);
  }
}
