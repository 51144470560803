import { SwiperOptions } from 'swiper';

export const carouselConfigServer: SwiperOptions = {
  virtual: false,
  slidesPerView: 4,
  slidesPerGroup: 4,
  spaceBetween: 32,
};

export const carouselConfigBrowser: SwiperOptions = {
  breakpoints: {
    320: {
      slidesPerView: 1.5,
      slidesPerGroup: 1.5,
      centeredSlides: true,
      centeredSlidesBounds: true,
    },
    576: {
      slidesPerView: 2.5,
      slidesPerGroup: 2.5,
    },
    768: {
      slidesPerView: 3.5,
      slidesPerGroup: 3.5,
    },
    992: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  },
  spaceBetween: 30,
  lazy: true,
  preloadImages: false,
};
