import { AfterViewInit, Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { BreakpointService } from '@spartacus/storefront';
import { Subscription, fromEvent, interval } from 'rxjs';
import { throttle } from 'rxjs/operators';
@Directive({
  selector: '[bossHideHeader]',
})
export class HideHeaderDirective implements AfterViewInit, OnInit, OnDestroy {
  private header: HTMLHeadElement;

  curScroll: number;
  prevScroll: number;
  curDirection = 0;
  prevDirection = 0;
  lastY = 0;
  toggled: boolean;
  downThreshold = 168;
  upThreshold = 0;
  private subscription: Subscription = new Subscription();

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private breakpointService: BreakpointService,
    private winRef: WindowRef,
  ) {}

  ngOnInit(): void {
    // emits when the screen size changes so header will be shown again
    this.subscription.add(
      this.breakpointService.breakpoint$.subscribe(() => {
        this.setDownThreshold();
        this.showHeader();
      }),
    );

    if (this.winRef.isBrowser()) {
      this.subscription.add(
        fromEvent(window, 'scroll')
          .pipe(throttle(() => interval(10)))
          .subscribe(() => {
            this.curScroll = window.scrollY;

            if (this.curScroll > 0 && this.curScroll > this.prevScroll) {
              // scrolled down
              this.curDirection = 2;
            } else {
              //scrolled up
              this.curDirection = 1;
            }

            if (this.curDirection !== this.prevDirection) {
              this.toggled = this.toggleHeader();
            } else {
              this.lastY = this.curScroll;
            }

            this.prevScroll = this.curScroll;

            if (this.toggled) {
              this.prevDirection = this.curDirection;
            }
          }),
      );
    }
  }

  ngAfterViewInit(): void {
    this.header = this.elRef.nativeElement;

    if (this.header) {
      this.downThreshold = this.header.clientHeight;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setDownThreshold(): void {
    if (this.header) {
      this.downThreshold = this.header.clientHeight;
    }
  }

  showHeader(): void {
    if (this.header) {
      this.renderer.setStyle(this.header, 'transform', `translateY(0)`);
    }
  }

  toggleHeader(): boolean {
    this.toggled = true;

    if (this.curDirection === 2 && this.curScroll - this.lastY > this.downThreshold) {
      this.lastY = this.curScroll;
      this.renderer.setStyle(this.header, 'transform', `translateY(-${this.header.clientHeight}px)`);
    } else if (this.curDirection === 1 && this.lastY - this.curScroll > this.upThreshold) {
      this.lastY = this.curScroll;
      this.showHeader();
    } else {
      this.toggled = false;
    }

    return this.toggled;
  }
}
